import { INTEGRATION_OBJECTS } from './utils/constants'

export const ActivationSteps = [
	{
		id: INTEGRATION_OBJECTS.resume,
		name: 'UPLOAD_RESUME',
		description: '$MESSAGES.ACTIVATION_RESUME',
		incompleted: '$MESSAGES.ACTIVATION_RESUME_INCOMPLETED',
		eventId: 'resume-application_btn',
		eventIdHorizon: 'step-1_btn',
		key: 'ResumeOnboarding',
	},
	{
		id: INTEGRATION_OBJECTS.skillSpecialty,
		name: 'SKILLS_SPECIALTY',
		description: '$MESSAGES.SKILLS_SPECIALTY',
		incompleted: '$MESSAGES.SKILLS_SPECIALTY_INCOMPLETED',
		eventId: 'skillSpecialty-application_btn',
		eventIdHorizon: 'step-2_btn',
		key: 'SpecialtyOnboarding',
	},
	{
		id: INTEGRATION_OBJECTS.referenceOnboarding,
		name: 'PROVIDE_REFERENCES',
		description: '$MESSAGES.ACTIVATION_REFERENCES',
		incompleted: '$MESSAGES.REFERENCE_INCOMPLETED_DATA',
		eventId: 'reference-application_btn',
		eventIdHorizon: 'step-3_btn',
		key: 'WorkerReferenceOnboarding',
	},
	{
		id: INTEGRATION_OBJECTS.preferredLocationOnboarding,
		name: 'PREFERENCES',
		description: '$MESSAGES.ACTIVATION_PREFERENCES',
		incompleted: '$MESSAGES.PREFERENCES_INCOMPLETED',
		eventId: 'preference-application_btn',
		eventIdHorizon: 'step-4_btn',
		key: 'WorkingPreferredLocationOnboarding',
	},
	{
		id: INTEGRATION_OBJECTS.terms,
		name: 'CONFIRMATION',
		description: '$MESSAGES.ACTIVATION_TERMS',
		incompleted: '$MESSAGES.TERMS_INCOMPLETED_DATA',
		eventId: 'terms-eligibility-application_btn',
		eventIdHorizon: 'step-5_btn',
		key: 'UserTermsOfService',
	},
	// { id: 'Done', name: 'SUCCESS!', description: '$MESSAGES.ACTIVATION_COMPLETE', hideStep: true },
]

import { Box, Dialog, Grid, SvgIcon } from '@material-ui/core'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import React from 'react'
import { Button } from '~/components/button'
import { DeleteConfirmationIconSvg } from '~/components/icons'
import { css } from 'styled-components'

const actionBoxStyled = css`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.3px;
	color: ${({ theme }) => theme.palette.content.veryDark};

	.double-confirm {
		min-width: 350px;
	}

	.option-action {
		cursor: pointer;
		min-width: 375px;
		height: 56px;
		display: flex;
		text-align: center;
		align-items: center;
		border-bottom: 1px solid ${({ theme }) => theme.palette.content.light};
	}
	.option-action.delete {
		color: ${({ theme }) => theme.palette.status.error.medium};
		border-bottom: none;
	}

	.title {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		letter-spacing: 0.1px;
		color: ${({ theme }) => theme.palette.primary.main};
		text-align: left;
		margin-top: 10px;
	}

	.desc,
	.desc-delete {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		letter-spacing: 0.2px;
		color: ${({ theme }) => theme.palette.content.veryDark};
	}

	.desc-delete {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		svg {
			font-size: 157px;
		}
	}
`

export const DeleteItemDialog = ({ showDeleteConfirmation, setDeleteConfirmation, handleClickSubmit, handleClickCancel }) => {
	const { t } = useTranslation()

	const handleCloseDialog = () => {
		setDeleteConfirmation(false)
	}

	return (
		<Dialog open={showDeleteConfirmation} onClose={() => handleCloseDialog()} css={actionBoxStyled}>
			<Box className="double-confirm" m={2}>
				<div className="title">{t('CONFIRM')}</div>
				<Box className="desc-delete" mt={2} mb={2}>
					<SvgIcon component={DeleteConfirmationIconSvg} viewBox="0 0 157 120"></SvgIcon>
					<Box mt={2} mb={2}>
						{t('DO_YOU_WANT_TO_DELETE_IT')}
					</Box>
				</Box>
				<div>
					<Grid item container spacing={2}>
						<Grid item xs={6}>
							<Button variant="outlined" color="primary" type="button" onClick={handleClickCancel}>
								{' '}
								{t('CANCEL')}{' '}
							</Button>
						</Grid>

						<Grid item xs={6}>
							<Button type="button" onClick={handleClickSubmit}>
								{' '}
								{t('DELETE')}
							</Button>
						</Grid>
					</Grid>
				</div>
			</Box>
		</Dialog>
	)
}

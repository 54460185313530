export const ONE_DAY = 24 * 60 * 60 * 1000 // ms

export const DATE_FORMAT_VIEW = 'MM/DD/YYYY'
export const DATE_FORMAT_SAVE = 'YYYY-MM-DD'

export const SHORT_DATE_FORMAT_VIEW = 'MM/YY'
export const MONTH_DAY_FORMAT_VIEW = 'MM/DD'

export const DATE_TIME_FORMAT_VIEW = 'MM/DD/YYYY hh:mm A'
export const DATE_TIME_FORMAT_SAVE = 'DD/MM/YYYY HH:mm:ss'

export const DATE_ENCRYPT_PATTERN = /^[x]{2}\/[x]{2}\/[0-9]{4}$/

export const TIME_FORMAT_VIEW = 'HH:mm'
export const TIME_FORMAT_SAVE = 'HH:mm'

export const TIME_FORMAT_VIEW_TIMECARD = 'h:mma'

import { gql } from '@apollo/client'
import { JOB_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const LIKE_DISLIKE_JOB_MUTATION = gql`
	mutation LikeOrDislikeAJob($jobId: String!, $action: LikeActionEnum!) {
		likeOrDislikeAJob(jobId: $jobId, action: $action) {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`

export const EXTEND_JOB_APPLICANT_MUTATION = gql`
	mutation CreateExtendAssignment($workerAssignmentId: String!, $timeOffStartDate: ISO8601Date!, $timeOffEndDate: ISO8601Date!, $note: String) {
		createExtendAssignment(workerAssignmentId: $workerAssignmentId, timeOffStartDate: $timeOffStartDate, timeOffEndDate: $timeOffEndDate, note: $note) {
			id
			note
			timeOffEndDate
			timeOffStartDate
		}
	}
`

export const EXTEND_JOB_APPLICANT_V2_MUTATION = gql`
	mutation CreateExtendAssignmentV2($workerAssignmentId: String!, $note: String, $applicantTimeOffs: [ApplicantTimeOffArgument!]) {
		createExtendAssignmentV2(workerAssignmentId: $workerAssignmentId, note: $note, applicantTimeOffs: $applicantTimeOffs) {
			id
		}
	}
`
export const APPLY_MULTIPLE_JOBS_MUTATION = gql`
	mutation createMultiJobApplicants($jobIds: [String!]!, $source: JobApplicantSourceEnum, $note: String, $applicantTimeOffs: [ApplicantTimeOffArgument!] ) {
		createMultiJobApplicants(jobIds: $jobIds, note: $note, source: $source, applicantTimeOffs: $applicantTimeOffs) {
			id
		}
	}
`

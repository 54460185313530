/*global google*/
import React, { useCallback, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { TextField as MuiTextField } from '@material-ui/core'
import { addressFieldStyle } from './address-field.style'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { parseAddress } from '~/common/helpers'
import { address } from '~/common/validator'
import { useDebounce } from 'react-use'

export const AddressField = ({
	name,
	validate,
	label,
	placeholder,
	mode,
	fullData,
	errorField,
	types,
	valueDisplayFormat,
	errorDisplayFormat,
	componentRestrictions,
	...rest
}) => {
	const inputRef = React.useRef()
	const { setFieldValue } = useFormikContext()
	const { t } = useTranslation()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [focused, focusProps] = useFieldFocused(field)
	const [inputValue, setInputValue] = useState()

	const handleChange = useCallback(
		(event) => {
			setFieldValue(`${name}.formatted_address`, event.target.value)
			setInputValue(event.target.value)
			if (!event.target.value) {
				setFieldValue(name, {})
			}
		},
		[setInputValue, setFieldValue, name]
	)

	const handlePlaceChanged = useCallback(
		(autocomplete) => {
			const address = parseAddress(autocomplete.getPlace())
			setFieldValue(name, { ...field.value, ...address })
			if (typeof valueDisplayFormat === 'function') {
				setInputValue(valueDisplayFormat(address))
			}
		},
		[name, setFieldValue, field.value, valueDisplayFormat]
	)

	useDebounce(
		() => {
			if (focused) {
				return
			}

			if (typeof valueDisplayFormat === 'function') {
				setInputValue(valueDisplayFormat(field.value))
			} else {
				setInputValue(field.value?.formatted_address || '')
			}
		},
		300,
		[field.value, focused]
	)
	React.useEffect(() => {
		if (inputRef.current) {
			const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
				fields: ['geometry', 'address_components', 'formatted_address'],
				componentRestrictions,
				types,
			})

			autocomplete.addListener('place_changed', () => handlePlaceChanged(autocomplete))
		}
		// eslint-disable-next-line
	}, [inputRef.current])

	const error = useFieldError(meta, fullData && errorField, errorDisplayFormat)

	const shrink = focused || inputValue ? true : false

	if (mode === FIELD_MODE.view) {
		return (
			<LabelField
				name={name}
				label={t(label)}
				displayValueFormat={(fieldValue) => (valueDisplayFormat ? valueDisplayFormat(fieldValue) : fieldValue?.formatted_address)}
			/>
		)
	}

	return (
		<MuiTextField
			inputRef={inputRef}
			id={name}
			label={t(label)}
			helperText={error}
			error={!!error}
			css={addressFieldStyle}
			InputLabelProps={{ shrink }}
			{...field}
			value={inputValue}
			onChange={handleChange}
			{...focusProps}
			{...rest}
			placeholder={placeholder && t(placeholder)}
		/>
	)
}

AddressField.defaultProps = {
	label: 'ADDRESS',
	mode: FIELD_MODE.edit,
	fullData: true,
	errorField: 'street',
	validate: address,
	componentRestrictions: { country: ['US', 'CA', 'PR'] },
}

import styled, { css } from 'styled-components'
import { InputLabel, Typography } from '@material-ui/core'

export const inputStyle = css`
	display: none;
`

export const uploadButtonStyle = css`
	height: 80px;
	width: 80px;
	max-width: 80px;
	box-sizing: border-box;
	border: 2px dashed ${({ theme }) => theme.palette.secondary.sub};
	border-radius: 8px;
	background: #f1feff;
	cursor: pointer;
	&:hover {
		border: 2px dashed ${({ theme }) => theme.palette.secondary.sub};
		border-radius: 8px;
	}

	justify-content: start;
	text-transform: unset;

	.content {
		margin-left: 52px;
		.MuiTypography-caption {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
		}
	}
`

export const imageBoxStyle = css`
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	.container {
		display: flex;
		cursor: pointer;
		justify-content: center;
		align-items: center;
		margin-top: 28px;
		margin-left: 12px;
		svg {
			font-size: 25px;
			path {
				stroke: ${({ theme }) => theme.palette.secondary.sub};
			}
		}
	}
`

export const imageBoxUploadStyle = css`
	margin-left: 4px;
	cursor: pointer;
	.container {
		display: flex;
		cursor: pointer;
		justify-content: center;
		align-items: center;
		svg {
			font-size: 25px;
		}
	}
`

export const wrapperStyle = css`
	width: 100%;
`

export const itemBoxStyle = css`
	padding: 4px 0;
	align-items: center;
	width: 80px;
	height: 80px;
	max-width: 80px;
	position: relative;
	background: #ffffff;
	border: 1px solid #c2d6dc;
	box-sizing: border-box;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: #0e3f6c;
	border-radius: 8px;
	margin-top: 8px;
	margin-left: 14px;

	.MuiIconButton-root {
		margin-left: auto;
	}

	p {
		width: 80px;
		overflow: hidden;
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.preview-icon {
		display: flex;
		margin-top: 23px;
		margin-left: 28px;
	}

	.lazyload-wrapper {
		width: 28px;
		height: 28px;
		border-radius: 50%;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
`

export const titleStyle = css`
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 18px;

	letter-spacing: 0.1px;
	color: #0e3f6c;
`

export const messageStyle = css`
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;

	letter-spacing: 0.1px;

	color: #0e3f6c;
`

export const filenameStyle = css`
	word-break: break-word;
	white-space: pre-wrap;
	position: absolute;
	border: -40px;
	bottom: -20px;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	align-items: center;
	letter-spacing: 0.1px;
	color: #333335;
	justify-content: center;
	width: 80px;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
`

export const reviewUploadStyled = css`
	.removeIcon {
		position: absolute;
		top: 0px;
		right: 0px;
		svg {
			width: 15px;
			height: 15px;
		}
	}

	.preview-image {
		margin-left: 10px;
		img {
			border: 1px solid #c2d6dc;
			max-width: 80px;
			max-height: 80px;
			min-height: 80px;
			overflow: hidden;
			padding: 5px;
		}
	}
`

export const uploadInputStyled = css`
	position: relative;
`

export const uploadInputStyledPdf = css`
	position: relative;
	width: 100%;
`

export const labelStyle = css`
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.primary.main};
	text-transform: uppercase;
`

export const hintStyle = css`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.content.dark};
`

export const loadingButtonStyle = css`
	height: 80px;
	width: 80px;
	background: white;
	border: 1px solid #e6e6e6;
	box-sizing: border-box;
	border-radius: 8px;
	justify-content: center;
	display: flex;
	align-items: center;
`

export const pdfCarouselButton = css`
	height: 100%;
	width: 100%;
	background: white;
	justify-content: center;
	display: flex;
	align-items: center;
`

export const fileNameElisips = css`
	font-size: 12px;
	line-height: 18px;
	text-align: center;
`

export const WrapperSliderBox = styled.div`
	display: flex;
	overflow: auto;
	max-width: calc(100vw - 450px);
	@media (max-width: 1065px) {
		max-width: calc(100vw - 350px);
	}
	@media (max-width: 960px) {
		max-width: 90vw;
	}
	@media (max-width: 650px) {
		max-width: 87vw;
	}
	@media (max-width: 600px) {
		max-width: 85vw;
	}
`

export const WrapperInputLabel = styled(InputLabel)`
	padding: 5px 0;
`

export const WrapperSubtitile = styled(Typography)`
	font-size: 12px;
	font-weight: 400;
	color: #808080;
	line-height: 18px;
	padding: 5px 0;
`
export const WrapperButton = styled.div`
	padding: 10px 10px 10px 0;
`

import axios from 'axios'
import { appStore, authStore } from '~/stores'
import { convertKeysToSnakeCase } from '~/common/helpers/mapping.helper'

export const createAxios = (headers) => {
	return axios.create({
		baseURL: process.env.REACT_APP_TRACKING_API_URL,
		headers: {
			'x-api-key': process.env.REACT_APP_TRACKING_API_KEY,
			...headers,
		},
		method: 'PUT',
		timeout: 30000,
	})
}

const records = async (dataValue) => {
	try {
		const userProperties = {
			work_authorized: authStore.activated,
		}

		dataValue.data.userProperties = convertKeysToSnakeCase(userProperties)
		dataValue.timestamp = +Math.floor(Date.now() / 1000)
		dataValue.company_id = appStore.id
		dataValue.user_id = authStore.id
		dataValue.is_anonymous = authStore.isAnonymous
		dataValue.is_mobile = false
		const trackingData = {
			is_anonymous: authStore.isAnonymous,
			Records: [convertKeysToSnakeCase(dataValue)],
		}

		if (authStore.id) {
			return await createAxios().put('/records', trackingData)
		} else {
			return await createAxios().put('/anonymous', trackingData)
		}
	} catch (error) {
		console.log('error', error)
		return null
	}
}

export const httpRequestTracking = {
	record: records,
}

import { apolloClient, CHANGE_PASSWORD_USER_MUTATION, GET_AUTHORIZED_WORKER } from '~/common/apollo'
import { captureException, toFormErrors } from '~/common/helpers'
import { store, computed, action, observable } from '~/common/mobx.decorator'
import { authStore, notifyStore } from '~/stores'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { EVENT_CLICK_ID } from '~/common/tracking/event-click.constant'
import { commonSigninStore } from '../common-signin/common-signin.store'
import { otpDialogStore } from '~/components/otp-dialog/otp-dialog.store'

@store()
class CommonChangePasswordStore {
	@observable confirm = false
	@computed
	get show() {
		return !!authStore.authorization
	}

	@action
	changePassword = async (variables, { setErrors }, updateForRequired = false, setShowChangePasswordDialog) => {
		try {
			logClickEvent(EVENT_CLICK_ID.clickConfirmChangePassBtn)
			try {
				await apolloClient.mutate({ mutation: CHANGE_PASSWORD_USER_MUTATION, variables })
				await notifyStore.success('$MESSAGES.CHANGE_PASSWORD_SUCCESSFUL')
				if (setShowChangePasswordDialog !== false) {
					await setShowChangePasswordDialog(false)
				}

				if (!updateForRequired) {
					this.confirm = true
				} else {
					if (authStore.phoneVerifyAt !== null) {
						const { data } = await apolloClient.query({
							query: GET_AUTHORIZED_WORKER,
							variables: {
								workerId: commonSigninStore.workerID,
							},
						})
						await authStore.changeToken(data?.authToken)
					} else {
						// otpDialogStore.setPhoneNumber(variables?.phone)
						// otpDialogStore.setShowDialogOtp(true)
						otpDialogStore.setShowPhoneVerifyModal(true)
					}
				}
			} catch (error) {
				captureException('Change Password', error)
				setErrors(toFormErrors(error, 'password'))
			}
		} catch (error) {
			captureException('Change Password', error)
			setErrors(toFormErrors(error, 'password'))
		}
	}

	@action
	toggleConfirm = async (confirm) => {
		this.confirm = confirm
	}
}

export const commonChangePasswordStore = new CommonChangePasswordStore()

import React, { useMemo } from 'react'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { useField } from 'formik'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { DATE_ENCRYPT_PATTERN, DATE_FORMAT_SAVE, DATE_FORMAT_VIEW, FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { CalendarSvg } from '~/components/icons'
import moment from 'moment'
import { iconButtonStyle } from './date-field.style'

export const DateField = ({ name, validate, label, placeholder, mode, saveFormat, handleOnChange, ...props }) => {
	const { t } = useTranslation()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [focused, focusProps] = useFieldFocused(field)
	const error = useFieldError(meta)

	const handleChange = React.useCallback(
		(date) => {
			const value = date?.format(saveFormat) || null
			field.onChange({ target: { name, value } })
			handleOnChange && handleOnChange(value)
		},
		[field, name, saveFormat, handleOnChange]
	)

	const encrypted = useMemo(() => DATE_ENCRYPT_PATTERN.test(field.value), [field.value])

	const fieldValue = useMemo(() => (encrypted || !field.value ? null : moment(field.value, saveFormat)), [encrypted, field.value, saveFormat])

	if (mode === FIELD_MODE.view) {
		return <LabelField label={t(label)} displayValueFormat={() => (encrypted ? field.value : fieldValue?.format(DATE_FORMAT_VIEW))} />
	}

	return (
		<div>
			<DatePicker
				{...field}
				label={t(label)}
				value={fieldValue}
				{...props}
				inputFormat={DATE_FORMAT_VIEW}
				toolbarFormat={DATE_FORMAT_VIEW}
				onChange={handleChange}
				openPickerIcon={<CalendarSvg />}
				allowSameDateSelection={true}
				renderInput={(renderProps) => {
					return (
						<TextField
							{...renderProps}
							helperText={error}
							error={!!error}
							placeholder={t(placeholder)}
							fullWidth
							inputProps={{
								...renderProps.inputProps,
								value: encrypted ? field.value : renderProps?.inputProps.value,
								...focusProps,
							}}
							InputLabelProps={{ shrink: focused || encrypted || !!field.value }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton aria-label="show calendar" css={iconButtonStyle} type="button" onClick={renderProps.inputProps.onClick}>
											Icon
											<CalendarSvg />
										</IconButton>
									</InputAdornment>
								),
								...renderProps.InputProps,
							}}
						/>
					)
				}}
			/>
		</div>
	)
}

DateField.defaultProps = {
	label: 'SELECT_DATE',
	placeholder: '$PLACEHOLDERS.SELECT_DATE',
	saveFormat: DATE_FORMAT_SAVE,
	clearable: true,
	mode: FIELD_MODE.edit,
	minDate: new Date(1700, 0, 1), // Start of year 1700
}

import { IconButton, SvgIcon, TextField } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { LabelField } from '@opus/web.core.form.label-field'
import axios from 'axios'
import styled from 'styled-components'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { iconButtonStyle } from '~/components/fields/date-field/date-field.style'
import { debounce } from 'lodash'
import { useDebounce } from 'react-use'
import { appStore } from '~/stores'
import { LocationStateSvg } from '~/components/icons'

export const ListCityStateField = styled.ul`
	top: 37px;
	max-height: 150px;
	overflow: auto;
	position: absolute;
	width: 100%;
	z-index: 10000;
	list-style: none;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
	padding-left: 0px;
	li p {
		padding: 5px 20px;
		margin: 0px;
	}
`
export const CityStateInfo = styled.p`
	cursor: pointer;
	&:hover {
		background: #f5f5f5;
	}
`

export const WrapperCityStateField = styled.div`
	position: relative;
	/* background: red; */
	height: 100%;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	.MuiFormControl-root {
		width: 100%;
	}
`

export const CityStateCustomField = ({
	name,
	validate,
	label,
	placeholder,
	disabled,
	freeSolo,
	mode,
	multiple,
	disableClearable,
	disableUnderline = false,
	showStartAdornment = false,
	showStateCode,
}) => {
	const { t } = useTranslation()
	const { setFieldValue } = useFormikContext()
	const [field, meta] = useField({ name })
	const [inputValue, updateInputValue] = useState(field.value)

	const [focused] = useFieldFocused(field)
	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])

	useEffect(
		() => {
			updateInputValue(field.value || '')
			setFieldValue(name, field.value || '')
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[field.value]
	)

	useDebounce(
		() => {
			if (!!field.value && !focused) {
				updateInputValue(field.value || (multiple ? [] : ''))
			}
		},
		300,
		[field.value]
	)

	const error = useFieldError(meta)

	const handleChange = async (e) => {
		const value = e.target.value
		setOpen(true)
		updateInputValue(value)
		const response = await axios.get(`/cities/search?terms=${value}`)

		const cities = response?.data?.data

		setOptions(cities)
	}

	const onClickRef = useRef(false)

	const selectedOption = useCallback(
		(option) => {
			setFieldValue(name, option.name)
			if (showStateCode) {
				setFieldValue('locationState', option.stateCode)
			} else {
				setFieldValue('locationState', option.stateName)
			}

			setFieldValue('nearBy.lat', parseFloat(option.latitude))
			setFieldValue('nearBy.long', parseFloat(option.longitude))

			updateInputValue(option.name)
			setOpen(false)
		},
		[setFieldValue, name, showStateCode]
	)

	const viewValue = useMemo(
		() => (multiple ? selectedOption?.map((opt) => opt.name)?.join(', ') : selectedOption?.name),

		[multiple, selectedOption]
	)

	if (mode === FIELD_MODE.view) {
		return <LabelField label={t(label)} displayValueFormat={() => viewValue || field.value} />
	}

	const handleRemoveValue = () => {
		updateInputValue('')
		setFieldValue(name, undefined)
		setFieldValue('locationState', '')
		setFieldValue('nearBy.lat', null)
		setFieldValue('nearBy.long', null)
		setFieldValue('nearBy.radius', null)
	}
	const onBlur = debounce(() => {
		if (!onClickRef.current) {
			setOptions([])
			setOpen(false)
		}
		onClickRef.current = false
	}, 150)

	return (
		<WrapperCityStateField>
			<TextField
				placeholder={t(placeholder)}
				onBlur={onBlur}
				disabled={disabled}
				// InputLabelProps={{ shrink: true }}
				{...validate}
				value={inputValue}
				onChange={handleChange}
				label={t(label)}
				error={!!error}
				helperText={error}
				InputProps={{
					disableUnderline: disableUnderline,
					startAdornment: showStartAdornment && <SvgIcon style={{ marginLeft: 10 }} component={LocationStateSvg} />,
					endAdornment: inputValue && (
						<IconButton
							css={[
								iconButtonStyle,
								{
									padding: '0 12px 0 0',
								},
							]}
							aria-label="toggle password visibility"
							onClick={handleRemoveValue}
						>
							<CancelRoundedIcon
								css={[
									{
										fill: appStore.getTheme.palette.primary.main,
										fontSize: '20px',
									},
								]}
							/>
						</IconButton>
					),
				}}
			/>
			{open && (
				<ListCityStateField>
					{options?.map((option, index) => (
						<li key={index}>
							<CityStateInfo
								onClick={() => {
									onClickRef.current = true
									selectedOption(option)
								}}
							>
								<b>{option.name}</b>
								{', ' + option.stateCode + ', ' + option.countryCode}
							</CityStateInfo>
						</li>
					))}
				</ListCityStateField>
			)}
		</WrapperCityStateField>
	)
}

CityStateCustomField.defaultProps = {
	freeSolo: false,
	mode: FIELD_MODE.edit,
	multiple: false,
}

import { css } from 'styled-components'

export const iconButtonStyle = css`
	margin-right: -6px;
	text-indent: -99999px;
`
export const labelStyle = css`
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.primary.main};
	text-transform: capitalize;
`

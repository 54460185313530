import { action, computed, observable, store } from '~/common/mobx.decorator'
import { filter, find, findIndex, flatten, includes, isEmpty, keys, map, orderBy, pickBy, remove, sum } from 'lodash'
import {
	apolloClient,
	CREATE_OR_UPDATE_SUPPORT_DOCUMENTS_MUTATION,
	DELETE_SUPPORT_DOCUMENTS_MUTATION,
	DELETE_WORKER_SPECIALTY,
	GET_SIGNED_URLS,
	GET_WORKER_ONBOARDING_OVERVIEW_QUERY,
	GET_WORKER_RESUMES_QUERY,
	SUPPORT_DOCUMENTS_MUTATION,
	UPDATE_WORKER_ONBOARDING_PART_MUTATION,
	UPDATE_WORKER_RESUMES_MUTATION,
} from '~/common/apollo'
import { authStore, masterStore, notifyStore, routerStore } from '~/stores'
import { PATHS } from '~/common/constants'
import { INTEGRATION_OBJECTS } from './utils/constants'
import { CONVERT_PART_TO_FORM_DATA } from './utils/convert-part-to-form-data'
import { CONVERT_FORM_TO_SAVE_DATA, convertDocumentsToSaveData, convertDocumentsToSaveFirebase } from './utils/convert-form-to-save-data'
import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import { ActivationSteps } from './activation-contants'
import { CALCULATE_PERCENT } from './utils/calculate-percent'
import { generateTempId, isTempId } from '~/common/helpers/uid.helper'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { EVENT_CLICK_ID } from '~/common/tracking/event-click.constant'
import { uploadStatusDialogStore } from '~/components/upload-status-dialog/upload-status-dialog.store'
import { DELETE_RESUME_MUTATION } from '../../common/apollo/mutations/document.mutation'

const EVENTS = {
	[INTEGRATION_OBJECTS.resume]: new events.OnboardingClickNextStepSuccessEvent({ step_id: 0 }),
	[INTEGRATION_OBJECTS.skillSpecialty]: new events.OnboardingClickNextStepSuccessEvent({ step_id: 1 }),
	[INTEGRATION_OBJECTS.referenceOnboarding]: new events.OnboardingClickNextStepSuccessEvent({ step_id: 2 }),
	[INTEGRATION_OBJECTS.preferredLocationOnboarding]: new events.OnboardingClickNextStepSuccessEvent({ step_id: 3 }),
	[INTEGRATION_OBJECTS.terms]: new events.OnboardingClickCompleteApplicationSuccessEvent({ step_id: 4 }),
}

@store()
class CareActivationStore {
	@observable prevStepIndex = -1
	@observable stepIndex = -1
	@observable stepData = {}
	@observable dirtyData = {}
	@observable percentPerStep = {}
	@observable resumes = []
	@observable specialtyList = []
	@observable workerOnboardingOverview
	@observable firebaseData
	@observable hasLastJob = false
	@observable lastJobIsAvailble = false
	@observable lastJob = null
	@observable specialtyTags = []
	@observable supportDocuments = []
	@observable generaticDocuments = []
	@observable originalSupportDocuments = []
	@observable percentageLoading = true
	@observable nullData = false
	@observable stepHasNotSubmitted = {}
	@observable workerSpecialties = []
	@observable preferredLocationError = null
	@observable specialtyKeyword = ''
	@observable specialtyTag = ''
	@observable stepDesc = {}
	@observable totalPercentFirebase = 0
	@observable openDialogSpecialty = false
	@observable showSuccessUpdateRedoSkillChecklist = false
	@observable isDirtySkillCheckList = false
	@observable isSuccessCompleteSkillCheckListDialog = false

	@action
	filterById(array, checkId) {
		return array.filter((item) => checkId.includes(item.id))
	}

	@action
	setShowSuccessUpdateRedoSkillChecklist = (value) => {
		this.showSuccessUpdateRedoSkillChecklist = value
	}

	@action
	selectListWorkerSpecialties = async (items) => {
		let workerSpecialties = this.skillCheklistStepData?.workerSpecialties || []

		const listItem = this.filterById(this.specialtyList, items)
		for (let i = 0; i < listItem.length; i++) {
			const item = listItem[i]
			const object = find(workerSpecialties, { specialty: item.skill_name })
			if (object !== undefined) {
				continue
			}

			const workerSkillChecklistSections = this.generateWorkerSkillChecklistSections(item)

			const workerSpecialty = {
				id: generateTempId(),
				category: item.category,
				isPrimary: workerSpecialties.length === 0,
				completedChecklist: !item.has_checklist,
				specialty: item.skill_name,
				specialtyLongName: item.long_name,
				status: item.status,
				workerSkillChecklistSections: workerSkillChecklistSections,
			}

			workerSpecialties.push(workerSpecialty)
		}

		this.stepData[INTEGRATION_OBJECTS.skillSpecialty] = { ...this.stepData[INTEGRATION_OBJECTS.skillSpecialty], workerSpecialties }
		await this.handleSubmitSkillChecklists(this.stepData[INTEGRATION_OBJECTS.skillSpecialty], false, false, null)
	}

	@action
	setOpenDialogSpecialty = (value) => {
		this.openDialogSpecialty = value
	}

	@computed
	get skillCheklistStepData() {
		return this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
	}
	@computed
	get fetchOriginalSpecialties() {
		return masterStore.originalSpecialties || {}
	}

	@computed
	get fetchSpecialtyTags() {
		const originalSpecialties = masterStore.originalSpecialties
		return Object.keys(originalSpecialties) || []
	}

	@computed
	get incompleteSteps() {
		const incompleteSteps = keys(pickBy(this.percentPerStep, (value) => value < 100))

		return incompleteSteps
	}

	@computed
	get totalPercentCount() {
		let percentPerStep = this.percentPerStep
		if (!includes(this.incompleteSteps, INTEGRATION_OBJECTS.terms)) {
			percentPerStep = { ...percentPerStep, UserTermsOfService: 0 }
		}
		return Math.floor(sum(Object.values(percentPerStep)) / 5)
	}

	@computed
	get totalPercent() {
		return Math.floor(sum(Object.values(this.percentPerStep)) / 5)
	}

	@computed
	get slideDirection() {
		return this.stepIndex > this.prevStepIndex ? 'left' : 'right'
	}

	@computed
	get steps() {
		return ActivationSteps
	}

	@computed
	get activeStep() {
		return this.steps?.[this.stepIndex]
	}

	@computed
	get incompletedStepIndex() {
		return this.steps.findIndex((step) => this.percentPerStep[step.id] !== 100)
	}

	@computed
	get title() {
		return this.activeStep?.name || 'PROFILE_COMPLETION'
	}

	@computed
	get description() {
		return this.stepDesc[this.activeStep?.key]
	}

	@computed
	get disciplineOptions() {
		return masterStore.disciplines
	}

	@computed
	get licenseStatesOptions() {
		return masterStore.licenseStates
	}

	@computed
	get enableReinitialize() {
		return !!this.firebaseData
	}

	@computed
	get unitOptions() {
		const options = find(masterStore.disciplines, { value: 'RN' })
		return options?.dependent_values
	}

	@computed
	get chartingOptions() {
		return masterStore.chartings
	}

	@computed
	get agencyOptions() {
		return masterStore.agencies
	}

	@computed
	get facilityOptions() {
		const experiences = this.stepData?.[INTEGRATION_OBJECTS.skillSpecialty]?.workExperiences

		return map(experiences, ({ facilityName }) => ({ value: facilityName, label: facilityName }))
	}

	@computed
	get salutationOptions() {
		const arr = masterStore.salutations
		return Object.keys(arr).map((key) => ({ value: key, label: arr[key] }))
	}

	@computed
	get relationshipOptions() {
		const arr = masterStore.relationships
		return Object.keys(arr).map((key) => ({ value: key, label: arr[key] }))
	}

	@computed
	get preferredLocations() {
		return this.stepData[INTEGRATION_OBJECTS.preferredLocationOnboarding]
	}

	@action
	checkLastJobAvailble = async () => {
		// const doc = await fireStore.db.collection(COLECTIONS.last_job_id).doc(authStore.id).get()
		// const data = doc.data()
		//
		// if (data?.jobId) {
		// 	this.lastJob = data
		// 	this.hasLastJob = true
		// 	const response = await apolloClient.query({ query: GET_JOB_DETAIL, variables: { id: data?.jobId } })
		// 	if (response.data?.job?.id) {
		// 		this.lastJobIsAvailble = true
		// 		routerStore.goPage(`${PATHS.care.jobs}/${this.lastJob.jobTitle}`)
		// 	}
		// }
	}

	@action
	onClickBack = () => {
		if (this.stepIndex > -1 && this.stepIndex < 5) {
			this.changeActiveStep(this.stepIndex - 1)
		}
	}

	@action
	onClickSkip = () => {
		if (this.stepIndex > -1 && this.stepIndex < 5) {
			this.changeActiveStep(this.stepIndex + 1)
		}
		logClickEvent(EVENT_CLICK_ID.clickSkipApplicationBtn)
	}

	@action
	setIsDirtySkillCheckList = (value) => {
		this.isDirtySkillCheckList = value
	}

	@action
	changeActiveStep = async (stepIndex, force = false) => {
		if (!force && (this.stepIndex > 4 || stepIndex > 5 || stepIndex < -1 || this.stepIndex === stepIndex)) {
			return
		}

		this.prevStepIndex = this.stepIndex
		this.stepIndex = stepIndex

		//Get firebase data for new step

		// if (formData) {
		// 	const docRef = fireStore.db.collection(COLECTIONS.activations).doc(formData?.__workerOnboardingPart?.id)
		// 	const firebaseData = (await docRef.get()).data()
		// 	this.firebaseData = firebaseData
		// }
	}

	@action
	changeActiveStepAndSubmit = async (stepIndex) => {
		const step = this.steps[this.stepIndex]
		const percent = this.percentPerStep[step?.id]
		const formData = this.stepData[step?.id]

		this.changeActiveStep(stepIndex)
		// Save Or Submit current step
		if (formData) {
			if (percent === 100 && step.id !== INTEGRATION_OBJECTS.terms) {
				await this.handleSubmitStep(step.id, formData)
			}
		}
	}

	@action
	handleContinueFirebaseData = () => {
		const formData = this.firebaseData
		if (this.activeStep.id === INTEGRATION_OBJECTS.resume) {
			let resumes = this.firebaseData?.resumes
			this.resumes = resumes
			this.stepData[INTEGRATION_OBJECTS.resume].resumes = resumes
			formData.__hasResumes = this.resumes?.filter((resume) => !resume?._destroy).length > 0
		}

		if (this.activeStep.id === INTEGRATION_OBJECTS.skillSpecialty) {
			setTimeout(() => {
				if (this.firebaseData?.flatSupportDocuments) {
					this.initWorkerDocuments(this.firebaseData?.flatSupportDocuments)
					formData.supportDocuments = this.supportDocuments
				}
			}, 500)
		}

		if (this.activeStep.id === INTEGRATION_OBJECTS.referenceOnboarding) {
			let workerReferences = formData?.workerReferences
			workerReferences = workerReferences.map((item) => ({ ...item, refType: item?.refType === 'former' }))
			this.stepData[this.activeStep.id] = { ...formData, workerReferences }
		} else {
			this.stepData[this.activeStep.id] = formData
		}

		setTimeout(() => {
			this.recalculateStepPercentage(this.activeStep.id, formData)
			this.saveFirebasePercentageData()
		}, 500)

		setTimeout(() => {
			this.firebaseData = null
		}, 500)
	}

	@action
	handleCloseFirebaseData = async () => {
		// const docRef = fireStore.db.collection(COLECTIONS.activations).doc(this.firebaseData?.__workerOnboardingPart?.id)
		// await docRef.delete()
		// await this.refreshStepData(this.workerOnboardingOverview, this.firebaseData?.__workerOnboardingPart?.integrationObject)
		// this.firebaseData = null
		// this.saveFirebasePercentageData()
	}

	@action
	moveNextStep = () => {
		if (this.stepIndex === 4 && this.incompletedStepIndex !== -1) {
			this.changeActiveStep(this.incompletedStepIndex)
		} else {
			if (this.stepIndex === 4) {
				this.checkLastJobAvailble()
				if (!this.lastJobIsAvailble) {
					routerStore.goPage(PATHS.care.home)
				}
			}

			this.changeActiveStep(this.stepIndex + 1)
		}
	}

	@action
	fetchOnboardingOverview = async () => {
		const { data } = await apolloClient.mutate({ mutation: GET_WORKER_ONBOARDING_OVERVIEW_QUERY })

		if (data?.workerOnboardingOverview === null) {
			this.nullData = true
			return
		} else {
			this.nullData = false
		}
		this.workerOnboardingOverview = data.workerOnboardingOverview
		await this.refreshStepData(this.workerOnboardingOverview)
	}

	@action
	fetchPercentageFromFirebase = async () => {
		// const docRef = fireStore.db.collection(COLECTIONS.onboarding_percentage).doc(authStore.id)
		// const firebaseData = (await docRef.get()).data()
		//
		// if (!isEmpty(firebaseData)) {
		// 	this.totalPercentFirebase = firebaseData?.percent
		// } else {
		// 	this.totalPercentFirebase = this.totalPercent || 0
		// }
		this.totalPercentFirebase = this.totalPercent || 0
	}

	@action
	recalculateStepPercentage = async (key, formData) => {
		const percent = await CALCULATE_PERCENT[key](formData)
		this.percentPerStep[key] = percent
	}

	@action
	refreshStepData = async (workerOnboardingOverview, refreshKey) => {
		Object.values(INTEGRATION_OBJECTS).forEach(async (key) => {
			if (refreshKey && refreshKey !== key) {
				return
			}
			const part = workerOnboardingOverview?.onboardingParts?.find((part) => part?.workerOnboardingPart?.integrationObject === key)
			this.stepDesc[key] = part?.description
			const converter = CONVERT_PART_TO_FORM_DATA[key]
			const formData = converter(part?.workerOnboardingPart || {})

			if (key === INTEGRATION_OBJECTS.skillSpecialty) {
				this.workerSpecialties = formData?.workerSpecialties
				setTimeout(() => {
					this.fetchSupportDocuments()
				}, 1000)
				formData.__hasSupportDocuments = this.supportDocuments?.filter((doc) => !doc?._destroy).filter((doc) => !isTempId(doc.id)).length > 0
				formData.supportDocuments = this.supportDocuments
			}
			this.stepData[key] = formData
			this.recalculateStepPercentage(key, formData)
		})
	}

	@action
	fetchWorkerResumes = async () => {
		const { data } = await apolloClient.query({ query: GET_WORKER_RESUMES_QUERY, variables: { id: authStore.id } })
		this.resumes = data?.worker?.resumes

		setTimeout(() => {
			const __hasResumes = this.resumes?.length > 0
			const resumes = this.stepData[INTEGRATION_OBJECTS.resume]
			this.syncStepData(INTEGRATION_OBJECTS.resume, { ...resumes, __hasResumes })
		}, 1000)
	}

	@action
	fetchSupportDocuments = async () => {
		const { data } = await apolloClient.mutate({
			mutation: SUPPORT_DOCUMENTS_MUTATION,
			variables: {
				worker_id: authStore.id,
			},
		})

		if (isEmpty(data?.supportDocuments)) {
			this.generateWorkerDefaultDocuments()
			return
		}
		setTimeout(() => {
			this.initWorkerDocuments(data?.supportDocuments)
		}, 1000)

		setTimeout(() => {
			const __hasSupportDocuments = this.supportDocuments?.length > 0
			const formData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
			this.syncStepData(INTEGRATION_OBJECTS.skillSpecialty, { ...formData, __hasSupportDocuments })
		}, 1000)
	}

	@action
	handleSubmitResumes = async ({ resumes }) => {
		const signedBlobId = filter(map(resumes, (resume) => !resume._destroy && resume.signedBlobId))?.[0]
		if (signedBlobId) {
			const { data } = await apolloClient.mutate({ mutation: UPDATE_WORKER_RESUMES_MUTATION, variables: { id: authStore.id, signedBlobId } })

			this.resumes = [data.updateIntegrationWorker.resume].filter((resume) => !!resume)
			this.stepData[INTEGRATION_OBJECTS.resume] = this.resumes
		} else {
			// HACK: To make form refresh
			this.resumes = [...resumes]
		}
	}

	@action
	handleCompleteActivation = async () => {
		await authStore.verifyToken()
		await routerStore.goPage(PATHS.care.profile)
	}

	@action
	handleViewJob = async () => {
		await authStore.verifyToken()
		// const docRef = fireStore.db.collection(COLECTIONS.last_job_id).doc(authStore.id)
		// await docRef.delete()
	}

	@action
	syncStepData = async (key, formData, dirty = true) => {
		this.dirtyData[key] = dirty
		if (key === INTEGRATION_OBJECTS.skillSpecialty) {
			formData.workerSpecialties = this.skillCheklistStepData?.workerSpecialties
			this.stepData[key] = formData
		} else {
			this.stepData[key] = formData
		}

		this.recalculateStepPercentage(key, formData)

		if (key !== INTEGRATION_OBJECTS.skillSpecialty && dirty && key !== INTEGRATION_OBJECTS.resume) {
			await this.saveFirebaseData(this.workerOnboardingPartId(key), key)
		}
	}

	@action
	handleSubmitStepData = async (key, formData) => {
		try {
			if (key === INTEGRATION_OBJECTS.terms && this.totalPercent !== 100) {
				this.changeActiveStep(this.incompletedStepIndex)
				return
			}

			if (key === INTEGRATION_OBJECTS.terms && this.totalPercent === 100) {
				const pendingSteps = Object.entries(this.stepData).filter(
					([key, value]) => key !== INTEGRATION_OBJECTS.terms && value?.__workerOnboardingPart?.state !== 'completed'
				)

				await Promise.all(pendingSteps.map(([key, value]) => this.handleSubmitStep(key, value)))
			}

			await this.handleSubmitStep(key, formData)
			this.moveNextStep()
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	handleSubmitStep = async (key, formData) => {
		if (key === INTEGRATION_OBJECTS.resume) {
			formData = { ...formData, resumes: this.resumes }
		}

		if (key === INTEGRATION_OBJECTS.preferredLocationOnboarding) {
			formData = { ...formData, preferredLocations: this.preferredLocations }
		}

		if (key === INTEGRATION_OBJECTS.skillSpecialty) {
			this.handleSubmitDocuments(formData)
		}

		const variables = CONVERT_FORM_TO_SAVE_DATA[key](formData)
		const { data } = await apolloClient.mutate({
			mutation: UPDATE_WORKER_ONBOARDING_PART_MUTATION,
			variables,
		})

		eventClient.logEvent(EVENTS[key])
		const stepData = CONVERT_PART_TO_FORM_DATA[key](data.updateWorkerOnboardingPart)

		//Re-assign supportDocuments to stepData
		if (key === INTEGRATION_OBJECTS.skillSpecialty) {
			stepData.__hasSupportDocuments = this.supportDocuments?.filter((doc) => !doc?._destroy).filter((doc) => !isTempId(doc.id)).length > 0
			stepData.supportDocuments = this.supportDocuments
		}

		//Re-assign supportDocuments to stepData
		if (key === INTEGRATION_OBJECTS.resume) {
			this.resumes = stepData?.resumes
		}

		this.stepData[key] = stepData

		const percent = await CALCULATE_PERCENT[key](formData)

		this.percentPerStep[key] = percent

		// const docRef = fireStore.db.collection(COLECTIONS.activations).doc(variables.id)
		// docRef.delete()
	}

	@action
	generateWorkerDefaultDocuments = async () => {
		const docTypes = Object.keys(masterStore.documentTypes)
		const supportDocs = docTypes.map((docType) => ({ documentType: docType, id: generateTempId(), documents: [] }))
		supportDocs.push({
			documentType: 'OTHER_DOCUMENT',
			id: generateTempId(),
			documents: [],
		})

		this.stepData[INTEGRATION_OBJECTS.skillSpecialty] = { ...this.skillCheklistStepData, supportDocuments: supportDocs }
		this.__hasSupportDocuments = false
		this.supportDocuments = supportDocs
	}

	@action
	initWorkerDocuments = async (documents) => {
		if (documents.length <= 0) {
			await this.generateWorkerDefaultDocuments()
			return
		}

		const docTypes = Object.keys(masterStore.documentTypes)
		let supportDocs = []
		for (var i = 0; i < docTypes.length; i++) {
			// eslint-disable-next-line
			let docs = filter(documents, (doc) => doc?.documentType === docTypes[i]).map((item) => ({ ...item.document, supportDocumentId: item.id }))
			if (docs <= 0) {
				supportDocs.push({ documentType: docTypes[i], id: generateTempId(), documents: [] })
			} else {
				supportDocs.push({ documentType: docTypes[i], id: generateTempId(), documents: docs })
			}
		}

		supportDocs.push({
			documentType: 'OTHER_DOCUMENT',
			id: generateTempId(),
			documents: documents
				.filter((item) => includes(['OTHER_DOCUMENT', 'Other'], item.documentType))
				?.map((item) => ({ ...item.document, supportDocumentId: item.id })),
		})

		this.__hasSupportDocuments = true
		this.supportDocuments = supportDocs
		await (this.stepData[INTEGRATION_OBJECTS.skillSpecialty] = { ...this.skillCheklistStepData, supportDocuments: supportDocs })
	}

	@action
	handleSubmitDocuments = async (formData) => {
		try {
			const supportDocuments = formData?.supportDocuments
			const supportDocumentsAttributes = convertDocumentsToSaveData(formData)

			if (supportDocumentsAttributes.length > 0) {
				const { data } = await apolloClient.mutate({
					mutation: CREATE_OR_UPDATE_SUPPORT_DOCUMENTS_MUTATION,
					variables: {
						supportDocumentsAttributes: supportDocumentsAttributes,
					},
				})

				await this.initWorkerDocuments(data.createOrUpdateSupportDocument)
				await uploadStatusDialogStore.setIsSuccessShowUploadStatusDialogStore(true)
				await uploadStatusDialogStore.setShowUploadStatusDialogStore(true)
			} else {
				// HACK: To make form refresh
				this.supportDocuments = supportDocuments
			}
		} catch (error) {
			await uploadStatusDialogStore.setIsSuccessShowUploadStatusDialogStore(false)
			await uploadStatusDialogStore.setShowUploadStatusDialogStore(true)
		}
	}

	@computed
	get photoImages() {
		return this.resumes?.filter((image) => !image._destroy)
	}

	@action
	workerOnboardingPartId = (key) => {
		const stepData = this.stepData[key]
		return stepData?.__workerOnboardingPart?.id
	}

	@action
	onSubmitDeleteResume = async (id) => {
		if (!isTempId(id)) {
			const { data } = await apolloClient.mutate({
				mutation: DELETE_RESUME_MUTATION,
				variables: {
					ids: [id],
				},
			})

			if (data?.destroyWorkerResumes?.success) {
				notifyStore.success('$MESSAGES.SUCCESSFUL')
			}

			this.resumes = this.resumes?.map((image) => {
				if (image.id === id) return { ...image, _destroy: true }
				return image
			})
		} else {
			this.resumes = this.resumes?.filter((image) => image.id !== id)
		}

		this.stepData[INTEGRATION_OBJECTS.resume].resumes = this.resumes
		const percent = await CALCULATE_PERCENT[INTEGRATION_OBJECTS.resume](this.stepData[INTEGRATION_OBJECTS.resume])
		this.percentPerStep[INTEGRATION_OBJECTS.resume] = percent

		if (this.resumes.length > 0) {
			await this.saveFirebaseData(this.workerOnboardingPartId(INTEGRATION_OBJECTS.resume), INTEGRATION_OBJECTS.resume)
		} else {
			this.deleteFirebaseData(this.workerOnboardingPartId(INTEGRATION_OBJECTS.resume))
		}
		this.saveFirebasePercentageData()
	}

	@action
	handleErrorSupportDocument = async (docImage, docIndex) => {
		try {
			const response = await apolloClient.query({
				query: GET_SIGNED_URLS,
				variables: {
					blobIds: [docImage?.blobId],
				},
			})
			const id = response?.data?.signedUrls?.[0]?.id
			const fileUrl = response?.data?.signedUrls?.[0]?.fileUrl
			let supportDocuments = this.skillCheklistStepData?.supportDocuments

			if (id === undefined) {
				if (docImage?.blobId !== undefined) {
					console.log(`Document is expired, but not fetch new form api: blodId: ${docImage?.blobId}`)
				}
			} else {
				const documents = supportDocuments[docIndex]?.documents
					.filter((item) => !item?.hasOwnProperty('_destroy'))
					.map((item) => (item?.blobId === docImage?.blobId || item?.id === docImage?.id ? { ...item, fileUrl } : item))

				supportDocuments[docIndex].documents = documents
			}

			this.stepData[INTEGRATION_OBJECTS.skillSpecialty] = { ...this.skillCheklistStepData, supportDocuments }
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	handleResumeError = async (blobId, image) => {
		const response = await apolloClient.query({
			query: GET_SIGNED_URLS,
			variables: {
				blobIds: [blobId],
			},
		})
		const id = response?.data?.signedUrls?.[0]?.id
		if (id === undefined) {
			console.log(`Resume is expired, but not fetch new form api: blodId: ${blobId}`)
			return
		}
		const fileUrl = response?.data?.signedUrls?.[0]?.fileUrl
		this.resumes = this.resumes
			.filter((item) => !item.hasOwnProperty('_destroy'))
			.map((item) => (item.blobId === blobId ? { ...item, fileUrl: fileUrl } : item))

		this.stepData[this.activeStep] = { ...this.stepData[this.activeStep], resumes: this.resumes }
	}

	@computed
	get hasShowTooltip() {
		return this.workedTimesheet !== null && this.isApprovedOrRejected
	}

	@action
	onChangePhotoUpload = async ({ values, isLoading }) => {
		this.isLoadingUpload = isLoading
		logClickEvent(EVENT_CLICK_ID.clickUploadResumeApplicationBtn)
		const resumes = this.stepData[INTEGRATION_OBJECTS.resume].resumes
		const stepData = { ...this.stepData[INTEGRATION_OBJECTS.resume], resumes: [...resumes, values] }
		this.stepData[INTEGRATION_OBJECTS.resume] = stepData
		this.resumes = [...this.resumes, values]
		await this.saveFirebaseData(stepData?.__workerOnboardingPart?.id, INTEGRATION_OBJECTS.resume)
	}

	@action
	saveFirebaseData = async (id, key) => {
		// await delay(500)
		// const doc = fireStore.db.collection(COLECTIONS.activations).doc(id)
		// if (key === INTEGRATION_OBJECTS.referenceOnboarding) {
		// 	const data = this.stepData[key]
		// 	let workerReferences = this.stepData[key]?.workerReferences
		// 	workerReferences = workerReferences.map((item) => ({ ...item, refType: item?.refType ? 'former' : 'current' }))
		// 	await doc.set({ ...data, workerReferences })
		// } else {
		// 	await doc.set(this.stepData[key])
		// }
		//
		// this.recalculateStepPercentage(key, this.stepData[key])
		// this.saveFirebasePercentageData()
	}

	@action
	saveFirebasePercentageData = async () => {
		// await delay(500)
		// const doc = fireStore.db.collection(COLECTIONS.onboarding_percentage).doc(authStore.id)
		// await doc.set({ percent: this.totalPercent, percentPerStep: this.percentPerStep })
	}

	@action
	deleteFirebaseData = async (id) => {
		// await delay(500)
		// const doc = fireStore.db.collection(COLECTIONS.activations).doc(id)
		// await doc.delete()
	}

	@action
	selectWorkerSpecialties = async (item) => {
		let workerSpecialties = this.skillCheklistStepData?.workerSpecialties || []
		const object = find(workerSpecialties, { specialty: item.skill_name })
		if (object !== undefined) {
			return
		}
		const workerSkillChecklistSections = this.generateWorkerSkillChecklistSections(item)
		const workerSpecialty = {
			id: generateTempId(),
			category: item.category,
			isPrimary: workerSpecialties.length === 0 ? true : false,
			completedChecklist: item.has_checklist ? false : true,
			specialty: item.skill_name,
			specialtyLongName: item.long_name,
			status: item.status,
			workerSkillChecklistSections: workerSkillChecklistSections,
		}

		await workerSpecialties.push(workerSpecialty)
		this.stepData[INTEGRATION_OBJECTS.skillSpecialty] = { ...this.stepData[INTEGRATION_OBJECTS.skillSpecialty], workerSpecialties }
		await this.handleSubmitSkillChecklists(this.stepData[INTEGRATION_OBJECTS.skillSpecialty], false, false, null)
	}

	@action
	geneateAnswerSkillChecklist = (questions) => {
		let newQuestions = []
		newQuestions = questions?.map((item) => {
			return { workerId: authStore.id, question: item, proficiency: 0, frequency: 0, id: generateTempId() }
		})
		return newQuestions
	}

	@action
	deleteSpecialtyFromFirebase = async (item) => {
		let workerSpecialties = this.stepData[INTEGRATION_OBJECTS.skillSpecialty].workerSpecialties
		remove(workerSpecialties, { specialty: item.specialty })

		this.fetchSpecialtyOptions(this.specialtyKeyword, this.specialtyTag)
		let refreshData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
		refreshData = { ...refreshData, workerSpecialties }
		this.stepData[INTEGRATION_OBJECTS.skillSpecialty] = refreshData
		this.workerSpecialties = workerSpecialties
		this.saveFirebaseData(this.workerOnboardingPartId(INTEGRATION_OBJECTS.skillSpecialty), INTEGRATION_OBJECTS.skillSpecialty)
	}

	@action
	setOpenInstruction = async (value) => {
		this.openInstruction = value
	}

	@action
	generateWorkerSkillChecklistSections = (item) => {
		if (!item?.has_checklist) {
			return []
		}
		let sections = []
		sections = item?.checklist?.map((value) => {
			return {
				id: generateTempId(),
				name: value.section,
				workerId: authStore.id,
				workerSkillChecklists: this.geneateAnswerSkillChecklist(value?.questions),
			}
		})
		return sections
	}

	@action
	setSpecialtyAsPrimary = async (item) => {
		let workerSpecialties = this.skillCheklistStepData?.workerSpecialties
		workerSpecialties = workerSpecialties.map((item) => ({ ...item, isPrimary: false }))

		item.isPrimary = true

		var index = findIndex(workerSpecialties, { specialty: item.specialty })
		workerSpecialties.splice(index, 1, item)

		let refreshData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
		refreshData = { ...refreshData, workerSpecialties: workerSpecialties }
		this.stepData[INTEGRATION_OBJECTS.skillSpecialty] = refreshData
		this.saveFirebaseData(this.workerOnboardingPartId(INTEGRATION_OBJECTS.skillSpecialty), INTEGRATION_OBJECTS.skillSpecialty)
		await this.handleSubmitSkillChecklists(this.stepData[INTEGRATION_OBJECTS.skillSpecialty], false, false, item?.id)
	}

	@action
	updateSpecialtyValue = async (values, saveFirebase = false) => {
		let workerSpecialties = this.skillCheklistStepData?.workerSpecialties
		if (values.isPrimary) {
			workerSpecialties = workerSpecialties.map((item) => ({ ...item, isPrimary: false }))
		}

		var index = findIndex(workerSpecialties, { specialty: values.specialty })

		values = { ...values, completedChecklist: this.isSpecialtyCompleted(values) }
		workerSpecialties.splice(index, 1, values)
		this.workerSpecialties = workerSpecialties

		let refreshData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
		refreshData = { ...refreshData, workerSpecialties: this.workerSpecialties }
		this.stepData[INTEGRATION_OBJECTS.skillSpecialty] = refreshData
		await this.syncStepData(INTEGRATION_OBJECTS.skillSpecialty, refreshData)
		if (saveFirebase) {
			await this.saveFirebaseData(this.workerOnboardingPartId(INTEGRATION_OBJECTS.skillSpecialty), INTEGRATION_OBJECTS.skillSpecialty)
		}
	}

	@action
	getAllQuestions = (specialty) => {
		let allQuestions = []
		allQuestions = specialty?.workerSkillChecklistSections?.map((section) => {
			return [...allQuestions, ...section?.workerSkillChecklists]
		})
		allQuestions = flatten(allQuestions)
		return allQuestions
	}

	@action
	isSpecialtyCompleted = (specialty) => {
		const allQuestions = this.getAllQuestions(specialty)
		if (allQuestions.length === 0) {
			return true
		}
		const incompleted = find(allQuestions, (item) => item.proficiency === 0 || item.frequency === 0)

		if (incompleted !== undefined) {
			return false
		}
		return true
	}

	@action
	handleDeleteSkillChecklist = async (specialtyId) => {
		await apolloClient.mutate({
			mutation: DELETE_WORKER_SPECIALTY,
			variables: {
				id: specialtyId,
			},
		})

		this.fetchSpecialtyOptions(this.specialtyKeyword, this.specialtyTag)
		this.syncStepData(INTEGRATION_OBJECTS.skillSpecialty, this.skillCheklistStepData)
		//
		// const docRef = fireStore.db.collection(COLECTIONS.activations).doc(this.workerOnboardingPartId(INTEGRATION_OBJECTS.skillSpecialty))
		// await docRef.delete()
	}

	@action
	saveFirebaseForSupportDocument = async (item, docIndex) => {
		let formData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
		const flatSupportDocuments = convertDocumentsToSaveFirebase(formData.supportDocuments)
		const existed = find(flatSupportDocuments, (doc) => doc.id === item.id)
		if (existed === undefined) {
			const documentType = formData?.supportDocuments[docIndex]?.documentType
			flatSupportDocuments.push({ documentType: documentType, document: item, id: item.id })
		}
		//
		// let firebaseData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
		// firebaseData.flatSupportDocuments = flatSupportDocuments
		// const doc = fireStore.db.collection(COLECTIONS.activations).doc(this.workerOnboardingPartId(INTEGRATION_OBJECTS.skillSpecialty))
		// await doc.set(firebaseData)
	}

	@action
	saveFirebaseForSupportGenDocument = async (item) => {
		let formData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
		const flatSupportDocuments = convertDocumentsToSaveFirebase(formData.supportDocuments)
		const existed = find(flatSupportDocuments, (doc) => doc.id === item.id)
		if (existed === undefined) {
			const documentType = formData?.supportDocuments?.otherDocument.documentType
			flatSupportDocuments.push({ documentType: documentType, document: item, id: item.id })
		}

		// let firebaseData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
		// firebaseData.flatSupportDocuments = flatSupportDocuments
		// const doc = fireStore.db.collection(COLECTIONS.activations).doc(this.workerOnboardingPartId(INTEGRATION_OBJECTS.skillSpecialty))
		// await doc.set(firebaseData)
	}

	@action
	saveFirebaseForGenericSupportDocument = async (item) => {
		let formData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
		const flatSupportDocuments = convertDocumentsToSaveFirebase(formData.supportDocuments)
		const existed = find(flatSupportDocuments, (doc) => doc.id === item.id)
		if (existed === undefined) {
			const documentType = 'OTHER_DOCUMENT'
			flatSupportDocuments.push({ documentType: documentType, document: item, id: item.id })
		}

		// let firebaseData = this.stepData[INTEGRATION_OBJECTS.skillSpecialty]
		// firebaseData.flatSupportDocuments = flatSupportDocuments
		// const doc = fireStore.db.collection(COLECTIONS.activations).doc(this.workerOnboardingPartId(INTEGRATION_OBJECTS.skillSpecialty))
		// await doc.set(firebaseData)
	}

	@action
	onSubmitDeleteDocument = async (item, docIndex) => {
		if (item?.id && !isTempId(item.id)) {
			const { data } = await apolloClient.mutate({
				mutation: DELETE_SUPPORT_DOCUMENTS_MUTATION,
				variables: {
					ids: [item?.supportDocumentId],
				},
			})

			if (data?.destroySupportDocument?.success) {
				notifyStore.success('$MESSAGES.SUCCESSFUL')
			}
		}
		let supportDocuments = this.stepData[INTEGRATION_OBJECTS.skillSpecialty].supportDocuments
		let supportDocument = supportDocuments[docIndex]
		let documents = []
		if (!isTempId(item.id)) {
			documents = supportDocument?.documents?.map((image) => {
				if (image.id === item.id) return { ...image, _destroy: true }
				return image
			})
		} else {
			documents = supportDocument?.documents?.filter((image) => image.id !== item.id)
		}

		supportDocument.documents = documents
		supportDocuments[docIndex] = supportDocument
		this.supportDocuments = supportDocuments

		this.stepData[INTEGRATION_OBJECTS.skillSpecialty] = { ...this.stepData[INTEGRATION_OBJECTS.skillSpecialty], supportDocuments }
	}

	@action
	fetchSpecialtyOptions = async (keyword = '', tag = '') => {
		const originalSpecialties = masterStore.originalSpecialties
		let specialties = []
		specialties = masterStore.specialties
		let specialtiesByTags = specialties
		if (tag !== '') {
			specialtiesByTags = originalSpecialties[tag]
			specialties = Object.keys(specialtiesByTags).map((key) => ({ ...specialtiesByTags[key], category: tag }))
		}

		const workerSpecialties = this.stepData[INTEGRATION_OBJECTS.skillSpecialty].workerSpecialties
		if (workerSpecialties.length > 0) {
			specialties = specialties.filter((item) => filter(workerSpecialties, (ws) => item.skill_name === ws.specialty).length === 0)
		}
		specialties = orderBy(specialties, 'long_name', 'asc')

		if (keyword === '') {
			this.specialtyList = specialties
			return
		}

		var options = filter(specialties, (item) => {
			return item.long_name?.toLowerCase().indexOf(keyword?.toLowerCase()) > -1
		})
		this.specialtyList = options || []
	}

	@action
	setPreferredLocationError = async (value) => {
		this.preferredLocationError = value
	}

	@action
	isCompleteAllSpecialties = (specialties) => {
		if (specialties.length === 0) {
			return false
		}
		for (var i = 0; i < specialties.length; i++) {
			if (!specialties[i].completedChecklist && !this.isSpecialtyCompleted(specialties[i])) {
				return false
			}
		}
		return true
	}

	@action
	hasUploadSupportDocument = (supportDocuments) => {
		for (var i = 0; i < supportDocuments.length; i++) {
			if (supportDocuments[i]?.documents?.filter((doc) => !doc._destroy).length > 0) {
				return true
			}
		}
		return false
	}

	@action
	setSpecialtyKeyword = async (keyword) => {
		this.specialtyKeyword = keyword
	}

	@action
	setSpecialtyTag = async (tag) => {
		this.specialtyTag = tag
	}

	@action
	combineWorkerSkillChecklists = (serverData, firebaseData) => {
		const array = serverData.map((skillServer) => {
			let skill = firebaseData.find((skillFirebase) => skillServer?.question === skillFirebase?.question)
			if (skill !== undefined) {
				return { ...skill, id: skillServer?.id }
			} else {
				return skillServer
			}
		})

		return array
	}

	@action
	combineWorkerSpecialtiesSections = (serverData, firebaseData) => {
		if (serverData.length === 0) {
			return []
		}

		const array = serverData.map((sectionServer) => {
			let section = firebaseData.find((sectionFirebase) => sectionFirebase?.name === sectionServer?.name)

			if (section !== undefined) {
				const workerSkillChecklists = this.combineWorkerSkillChecklists(sectionServer?.workerSkillChecklists, section?.workerSkillChecklists)
				return { ...section, id: sectionServer?.id, workerSkillChecklists }
			} else {
				return sectionServer
			}
		})

		return array
	}

	@action
	combineWorkerSpecialties = (serverData, firebaseData) => {
		let workerSpecialties = serverData?.workerSpecialties //from BE
		let firebaseDataWorkerSpecialties = firebaseData?.workerSpecialties // from Firebase

		workerSpecialties = workerSpecialties.map((item) => {
			let specialty = firebaseDataWorkerSpecialties.find((spec) => spec?.specialty === item?.specialty)
			if (specialty !== undefined) {
				const workerSkillChecklistSections = this.combineWorkerSpecialtiesSections(item.workerSkillChecklistSections, specialty.workerSkillChecklistSections)
				return { ...specialty, id: item?.id, isPrimary: item?.isPrimary, status: item?.status, workerSkillChecklistSections }
			} else {
				return item
			}
		})

		return workerSpecialties
	}
	@action
	setIsSuccessCompleteSkillCheckListDialog = (value) => {
		this.isSuccessCompleteSkillCheckListDialog = value
	}

	@action
	handleSubmitSkillChecklists = async (formData, empty = false, clearFirebase = true, speciatyId) => {
		try {
			const key = INTEGRATION_OBJECTS.skillSpecialty
			const firebaseFormData = formData
			const variables = CONVERT_FORM_TO_SAVE_DATA[key](formData, empty)
			const { data } = await apolloClient.mutate({
				mutation: UPDATE_WORKER_ONBOARDING_PART_MUTATION,
				variables,
			})

			const stepData = CONVERT_PART_TO_FORM_DATA[key](data.updateWorkerOnboardingPart)
			stepData.__hasSupportDocuments = this.supportDocuments?.filter((doc) => !doc?._destroy).filter((doc) => !isTempId(doc.id)).length > 0
			stepData.supportDocuments = formData?.supportDocuments

			if (!clearFirebase) {
				stepData.workerSpecialties = this.combineWorkerSpecialties(stepData, firebaseFormData)
				this.stepData[key] = stepData
			}

			this.stepData[key] = stepData
			const percent = await CALCULATE_PERCENT[key](formData)
			this.percentPerStep[key] = percent
			this.saveFirebasePercentageData()

			this.syncStepData(key, this.stepData[key], true)

			if (this.isDirtySkillCheckList) {
				if (data?.updateWorkerOnboardingPart?.integration?.workerSpecialties.find((item) => item.id === speciatyId).status === 'completed') {
					this.setIsSuccessCompleteSkillCheckListDialog(true)
					this.setShowSuccessUpdateRedoSkillChecklist(true)
				} else {
					this.setIsSuccessCompleteSkillCheckListDialog(false)
					this.setShowSuccessUpdateRedoSkillChecklist(true)
				}
			}

			// if (clearFirebase) {
			// 	const docRef = fireStore.db.collection(COLECTIONS.activations).doc(variables.id)
			// 	await docRef.delete()
			// } else {
			// 	this.saveFirebaseData(this.workerOnboardingPartId(key), key)
			// }
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	setPreferredLocationValues = async (values) => {
		const key = INTEGRATION_OBJECTS.preferredLocationOnboarding
		const preData = this.preferredLocations
		this.stepData[key] = { ...preData, workingPreferredLocations: values }
		this.recalculateStepPercentage(key, this.stepData[key])
		await this.saveFirebaseData(this.workerOnboardingPartId(key), key)
	}

	@action
	setAvailableStartDateValues = async (date) => {
		const key = INTEGRATION_OBJECTS.preferredLocationOnboarding
		const preData = this.preferredLocations
		this.stepData[key] = { ...preData, worker: { id: authStore.id, availableStartDate: date } }
		this.recalculateStepPercentage(key, this.stepData[key])
	}
}

export const careActivationStore = new CareActivationStore()

import { flatten } from 'flat'
import { find, isEmpty, flatten as _flatten, filter } from 'lodash'
import moment from 'moment'
import { DATE_FORMAT_SAVE } from '~/common/constants'
import { INTEGRATION_OBJECTS } from './constants'
import { VALIDATE_FORM_DATA } from './validate-form-data'

const REFERRENCE_REQUIRED_FIELDS_NUMBER = 9
export const defaultFilterCondition = ([key, value]) => (!isEmpty(value) && !key.startsWith('__') ? true : false)

export const calculateTotalKeys = (formData, filterCondition = defaultFilterCondition) => {
	return Object.entries(flatten(formData || {})).filter(filterCondition).length || 0
}

export const calculatePercent = (validator, totalHidden = 0) => async (formData) => {
	const errors = await validator(formData)
	const totalFields = calculateTotalKeys(formData) - totalHidden
	const totalErrors = calculateTotalKeys(errors)
	const sumFieldAndError = totalFields + totalErrors
	return sumFieldAndError === 0 ? 100 : Math.floor((totalFields * 100) / sumFieldAndError)
}

export const calculateResumePercent = async (formData) => {
	const totalHidden = (formData?.resumes?.length || 0) * 2
	return calculatePercent(VALIDATE_FORM_DATA[INTEGRATION_OBJECTS.resume], totalHidden)(formData)
}

export const calculateWorkerSupportDocumentPercent = (supportDocuments) => {
	let percent = 0
	if (supportDocuments.length <= 0) {
		return percent
	}
	const length = supportDocuments.filter(({ documents }) => documents.length > 0).length
	return length > 0 ? 100 : 0
}

export const getSpecialtyAllQuestions = (specialty) => {
	let allQuestions = []
	allQuestions = specialty?.workerSkillChecklistSections?.map((section) => {
		return [...allQuestions, ...section?.workerSkillChecklists]
	})
	allQuestions = _flatten(allQuestions)
	return allQuestions
}

export const isSpecialtyCompleted = (specialty) => {
	const allQuestions = getSpecialtyAllQuestions(specialty)
	const incompleted = find(allQuestions, (item) => item.proficiency === 0 || item.frequency === 0)

	if (incompleted !== undefined) {
		return false
	}
	return true
}

export const percentCompleteSpecialtyQuestion = (specialty) => {
	const allQuestions = getSpecialtyAllQuestions(specialty)
	const incompleted = filter(allQuestions, (item) => item?.proficiency === 0 || item?.frequency === 0).length
	const total = allQuestions.length
	return Math.round(((total - incompleted) / total) * 100)
}

export const calculatePercentSpecialty = (specialty) => {
	if (specialty?.completedChecklist || specialty?.workerSkillChecklistSections?.length <= 0) {
		return 100
	}
	if (isSpecialtyCompleted(specialty)) {
		return 100
	}
	return 0
}

export const calculateWorkerSpecialtiesPercent = (workerSpecialties) => {
	let percent = 0
	const length = workerSpecialties.length
	if (length === 0) {
		return percent
	}
	const incompleteSpecalties = filter(workerSpecialties, (item) => calculatePercentSpecialty(item) === 0)
	const totalIncomplete = incompleteSpecalties.length

	if (totalIncomplete === 0) {
		return 100 //100%
	}

	if (length > 0) {
		return 50 //50%
	}

	return 0
}

export const calculateSkillSpecialtyPercent = async (formData) => {
	const percentSpecialty = calculateWorkerSpecialtiesPercent(formData?.workerSpecialties)
	return percentSpecialty
}

export const calculatePreferredLocationPercent = async (formData) => {
	let total = 0

	if (!isEmpty(formData?.worker?.availableStartDate)) {
		const min = new Date()
		const date = moment(formData?.worker?.availableStartDate, DATE_FORMAT_SAVE)
		if (date.isValid() && !date.isBefore(moment(min), 'date')) {
			total = total + 50
		}
	}

	const preferedLocations = formData?.workingPreferredLocations
	const location = preferedLocations.find((item) => item?.preferredWorkingState && !item?.preferredWorkingCity)
	if (!isEmpty(location)) {
		total = total + 50
	}
	return total
}

export const calculateReferencePercent = async (formData) => {
	const workerReferenceData = await VALIDATE_FORM_DATA[INTEGRATION_OBJECTS.referenceOnboarding](formData)
	if (isEmpty(workerReferenceData)) {
		return 100
	}
	const workerReferenceErrors = workerReferenceData?.workerReferences[0]
	const numberError = Object.keys(workerReferenceErrors).length || 0
	const percentage = ((REFERRENCE_REQUIRED_FIELDS_NUMBER - numberError) / REFERRENCE_REQUIRED_FIELDS_NUMBER) * 100
	return percentage
}

export const calculateTermPercent = async (formData) => {
	return calculatePercent(VALIDATE_FORM_DATA[INTEGRATION_OBJECTS.terms])(formData)
}

export const CALCULATE_PERCENT = {
	[INTEGRATION_OBJECTS.resume]: calculateResumePercent,
	[INTEGRATION_OBJECTS.skillSpecialty]: calculateSkillSpecialtyPercent,
	[INTEGRATION_OBJECTS.referenceOnboarding]: calculateReferencePercent,
	[INTEGRATION_OBJECTS.preferredLocationOnboarding]: calculatePreferredLocationPercent,
	[INTEGRATION_OBJECTS.terms]: calculateTermPercent,
}

import { gql } from '@apollo/client'
import { JOB_FRAGMENT, WORKER_FRAGMENT, WORKER_JTP_FRAGMENT } from '../apollo.fragment'

const workerFragment = process.env.REACT_APP_COMPANY_HOST === 'JNP' ? WORKER_FRAGMENT : WORKER_JTP_FRAGMENT
export const GET_CURRENT_WORKER_ASSIGNMENT_QUERY = gql`
	query {
		currentWorkerAssignment {
			id
		}
	}
`

export const GET_WORKER_CURRENT_JOB_QUERY = gql`
	query CurrentWorkerAssignment {
		currentWorkerAssignment {
			job {
				applicantCount
				createdAt
				description
				discipline
				duration
				exclusiveJob
				externalCreatedAt
				externalJobId
				externalFriendlyId
				externalUpdatedAt
				facilityType
				favoriteCount
				frequency
				hoursPerWeek
				id
				isDisliked
				isLiked
				isSkillChecklist
				jobType
				matchingPercentage
				minWeeklyPayAmount
				shifts
				skillLongName
				slotsNumber
				specialty
				startDate
				status
				title
				updatedAt
				viewCount
				weeklyPayAmount
			}
		}
	}
`

export const GET_WORKER_PAST_JOB_QUERY = gql`
	query getWorkerPastJobQuery($offset: Int, $limit: Int) {
		workerAssignments(offset: $offset, limit: $limit) {
			availableToExtend
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${workerFragment}
`

export const GET_JOB_DETAIL_QUERY = gql`
	query getJobDetailQuery($id: String!) {
		workerAssignment(id: $id) {
			availableToExtend
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${workerFragment}
`

export const GET_JOB_APPLICANT_QUERY = gql`
	query getJobApplicantQuery($id: String!) {
		jobApplicant(id: $id) {
			id
		}
	}
`

export const GET_ASSIGNMENT_JOB__QUERY = gql`
	query getJobDetailQuery($id: String!) {
		workerAssignment(id: $id) {
			id
			availableToExtend
			job {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_JOB_DETAIL = gql`
	query getJobDetail($id: String!) {
		job(id: $id) {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`

import { yupToFormErrors } from 'formik'
import * as yup from 'yup'
import { DATE_ENCRYPT_PATTERN, DATE_FORMAT_SAVE } from '~/common/constants'
import { INTEGRATION_OBJECTS } from './constants'
import validator from 'validator'
import moment from 'moment'
import { get, isEmpty, omit } from 'lodash'
import { trimData } from '~/common/helpers'
import { date, maxDate, minDate, minValue, phone } from '~/common/validator'
import { authStore } from '~/stores'

const DATE_REGEX = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
const DATE_ERROR = 'Invalid date'
const DATE_ERROR2 = 'Expired Date should be in the future.'

yup.addMethod(yup.array, 'unique', function (message, path, ignoreIndex = -1) {
	return this.test('unique', message, function (list) {
		const mapper = (x) => get(x, path)
		const total = {}

		list.forEach((item, index) => {
			const value = mapper(item)
			total[value] = (total[value] || 0) + 1
			if (ignoreIndex > 0 && index >= ignoreIndex) {
				// Don't check duplicate
			} else if (value && total[value] > 1) {
				throw this.createError({ path: `${this.path}.[${index}].${path}`, message })
			}
		})
		return true
	})
})

yup.addMethod(yup.string, 'minDate', function (message = DATE_ERROR, min = new Date()) {
	return this.test('minDate', message, function (value) {
		const { path, createError } = this

		const error = minDate(min)(value)

		return error ? createError({ path, message }) : true
	})
})

yup.addMethod(yup.string, 'validDate', function (message = DATE_ERROR) {
	return this.test('validDate', message, function (value) {
		const { path, createError } = this
		const error = value && date(value)

		return error ? createError({ path, message }) : true
	})
})

yup.addMethod(yup.string, 'maxDate', function (message = DATE_ERROR, max = new Date()) {
	return this.test('maxDate', message, function (value) {
		const { path, createError } = this
		const error = maxDate(max)(value)
		return error ? createError({ path, message }) : true
	})
})

export const SpecialtySchema = yup.object().shape({
	category: yup.string().trim().required(),
	specialty: yup.string().trim().required(),
	isPrimary: yup.boolean().required(),
	completedChecklist: yup.boolean().required('All questions must be accepted.').oneOf([true], 'All questions must be accepted.'),
})

export const SupportDocumentSchema = yup.object().shape({
	documents: yup.array().min(1),
})

export const ResumeSchema = yup.object().shape({
	licenseDiscipline: yup.string().trim().required(),
	licenseState: yup.string().trim().required(),
	licenseNumber: yup.string().trim().required().max(100, 'Maximum is 100 characters'),
	expirationDate: yup.string().trim().required().matches(DATE_REGEX, DATE_ERROR).minDate(DATE_ERROR2),
})

export const CertificationSchema = yup.object().shape({
	title: yup.string().trim().required(),
	licenseNumber: yup.string().trim().max(100, 'Maximum is 100 characters'),
	expirationDate: yup.string().trim().required().matches(DATE_REGEX, DATE_ERROR).minDate(DATE_ERROR2),
})

export const ReferenceSchema = yup.object().shape({
	facilityName: yup.string().trim().required().max(60, 'Maximum is 60 characters'),
	contactLastName: yup.string().trim().required(),
	contactFirstName: yup.string().trim().required(),
	contactEmail: yup.string().nullable().email().required(),
	contactPhone: yup
		.string()
		.trim()
		.required()
		.test('phone', 'Invalid phone number', (value) => value && validator.isMobilePhone(value)),
	jobTitle: yup.string().trim().required(),
	workedFrom: yup.string().trim().required(),
	workedTo: yup.string().trim().required(),
	refType: yup.string().trim().required(),
})

export const EducationSchema = yup.object().shape({
	schoolName: yup.string().trim().required().max(100, 'Maximum is 100 characters'),
	degreeName: yup.string().trim().required().max(100, 'Maximum is 100 characters'),
	degreeDate: yup.string().trim().required().matches(DATE_REGEX, DATE_ERROR).validDate().maxDate(),
})

export const ExperienceSchema = yup.object().shape({
	facilityName: yup.string().trim().required().max(60, 'Maximum is 60 characters'),
	unitSpecialty: yup.string().trim().required(),
	bedsInUnit: yup.string().trim().nullable().max(60, 'Maximum is 60 characters'),
	startDate: yup.string().trim().required().matches(DATE_REGEX, DATE_ERROR).validDate().maxDate(),
	endDate: yup.mixed().when(['startDate', 'currentlyEmployed'], (startDate, currentlyEmployed) => {
		if (!currentlyEmployed) {
			return yup
				.string()
				.required()
				.matches(DATE_REGEX, DATE_ERROR)
				.validDate()
				.test('date_range', 'Invalid date range', (value) => {
					return moment(startDate, DATE_FORMAT_SAVE).isBefore(moment(value, DATE_FORMAT_SAVE))
				})
		}
	}),

	agency: yup.string().when(['travelAssignment'], {
		is: (travelAssignment) => !!travelAssignment,
		then: yup.string().trim().required(),
	}),
})

export const QuizAnswerSchema = yup.object().shape({
	quizAnswerId: yup.string().trim().required(),
	reason: yup
		.string()
		.nullable()
		.when(['required'], {
			is: (required) => !!required,
			then: yup.string().trim().required().max(255, 'Maximum is 255 characters'),
		}),
})

export const WorkAuthorizationSchema = yup.object().shape({
	socialSecurityNumber: yup
		.string()
		.nullable()
		.test('ssn', 'Invalid social security number', (value) => {
			const expression = /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/
			const encryptPattern = /^[x]{3}-[x]{2}-[0-9]{4}$/

			return !value || encryptPattern.test(value) || expression.test(value)
		}),
	dateOfBirth: yup
		.string()
		.nullable()
		.test('birthDay', 'Date of birth must be 18 years or older', (value) => {
			if (!value || DATE_ENCRYPT_PATTERN.test(value)) {
				return true
			}

			const date = moment(value, DATE_FORMAT_SAVE)
			return date.isValid() && date.isSameOrBefore(moment().subtract(18, 'years'), 'day')
		})
		.validDate(),
})

export const AddressSchema = yup.object().shape({
	street: yup.string().trim().required(),
	city: yup.string().trim().required(),
	state: yup.string().trim().required(),
	zipcode: yup.string().trim().required(),
	country: yup.string().trim().required(),
})

export const EmergencyContactSchema = yup.object().shape({
	contactPhone: yup
		.string()
		.nullable()
		.test('phonePattern', 'Invalid Phone', (value) => {
			if (!value || value === '+' || value === '+1') {
				return true
			}

			return phone(value) ? false : true
		}),
})

export const PreferredLocationSchema = yup.object().shape({
	preferredWorkingState: yup.string().nullable(),
})

export const ResumeOverviewSchema = yup.object().shape({
	resumes: yup.array().min(1),
})

export const JobAlertViewSchema = yup.object().shape({
	name: yup.string().trim().required(),
})

export const JobAlertEditSchema = yup.object().shape({
	name: yup.string().trim().required(),
	location: yup.string().trim().required(),
	// skillName: yup.string().trim().required(),
	alertChannel: yup.string().trim().nullable().required('Alert channel is required'),
	alertFrequency: yup.string().trim().required(),
})

export const JobAlertEditAnoSchema = yup.object().shape({
	name: yup.string().trim().required(),
	location: yup.string().trim().required(),
	// skillName: yup.string().trim().required(),
	alertFrequency: yup.string().trim().required(),
})

export const JobAlertEditPauseSchema = yup.object().shape({
	name: yup.string().trim().required(),
	location: yup.string().trim().required(),
	// skillName: yup.string().trim().required(),
	alertChannel: yup.string().trim().nullable().required('Alert channel is required'),
	alertFrequency: yup.string().trim().required(),
	pauseAlertPeriod: yup.string().trim().required(),
})

export const JobAlertEditAnoPauseSchema = yup.object().shape({
	name: yup.string().trim().required(),
	location: yup.string().trim().required(),
	// skillName: yup.string().trim().required(),
	alertFrequency: yup.string().trim().required(),
	pauseAlertPeriod: yup.string().trim().required(),
})

export const ExperienceOverviewSchema = yup.object().shape({
	yearsOfExperience: yup
		.string()
		.required()
		.test('minValue', 'Invalid Number', (value) => !minValue(0)(value)),

	workExperiences: yup.mixed().when(['__hasResumes'], (__hasResumes) =>
		yup.array().of(
			yup.lazy((value) => {
				const isEmptyExperience = isEmpty(trimData(omit(value, ['id', 'createdAt']), (value) => !!value && !isEmpty(value)))
				return !__hasResumes || !isEmptyExperience ? ExperienceSchema : yup.mixed().nullable()
			})
		)
	),
})

export const EducationOverviewSchema = yup.object().shape({
	workerEducations: yup.mixed().when(['__hasResumes'], (__hasResumes) =>
		yup.array().of(
			yup.lazy((value) => {
				const isEmptyEducation = isEmpty(trimData(omit(value, ['id', 'createdAt']), (value) => !!value && !isEmpty(value)))
				return !__hasResumes || !isEmptyEducation ? EducationSchema : yup.mixed().nullable()
			})
		)
	),
})

export const PreferredSchema = yup.object().shape({
	preferredWorkingState: yup.string().required(),
})

export const WorkerStartDateSchema = yup.object().shape({
	availableStartDate: yup.string().trim().required(),
})

export const PreferenceLocationSchema = yup.object().shape({
	worker: WorkerStartDateSchema.required(),
	workingPreferredLocations: yup
		.array()
		.min(1)
		.of(yup.lazy((_, options) => (options.path === 'workingPreferredLocations[0]' ? PreferredSchema : yup.object().nullable()))),
})

export const ReferenceOverviewSchema = yup.object().shape({
	workerReferences: yup.array().of(
		yup.lazy((value, options) => {
			const isEmptyReference = isEmpty(trimData(omit(value, ['id', 'createdAt']), (value) => !!value && !isEmpty(value)))
			const firstItem = options.path === 'workerReferences[0]'
			return firstItem || !isEmptyReference ? ReferenceSchema : yup.mixed().nullable()
		})
	),
})

export const VerificationOverviewSchema = yup.object().shape({
	termsOfServiceId: yup.boolean().required('The terms and eligibility must be accepted.').oneOf([true], 'The terms and eligibility must be accepted.'),
	workerQuizAnswers: yup.array(QuizAnswerSchema).min(1),
})

export const ProfileOverviewSchema = yup.object().shape({
	workingAuthorization: WorkAuthorizationSchema.nullable(),
	workerAddress: AddressSchema.required(),
	workingPreferredLocations: yup
		.array()
		.of(yup.lazy((_, options) => (options.path === 'workingPreferredLocations[0]' ? PreferredLocationSchema : yup.object().nullable())))
		.unique('Duplicate preferred destination', 'preferredWorkingState', 3),
	emergencyContact: EmergencyContactSchema.nullable(),
})

export const SkillSpecialtySchema = yup.object().shape({
	workerSpecialties: yup.array(SpecialtySchema).min(1),
	// __hasSupportDocuments: yup.boolean().required('Document must be uploaded.').oneOf([true], 'Document must be uploaded.')
	// supportDocuments: yup
	// 	.array()
	// 	.min(1)
	// 	.of(yup.lazy((_, options) => (options.path === 'supportDocuments[0]' ? SupportDocumentSchema : yup.object().nullable())))
})

export const validateResume = async (formData) => {
	const yupErrors = await ResumeOverviewSchema.validate(formData, { abortEarly: false }).catch((error) => error)
	const errors = yupToFormErrors(yupErrors)
	return errors
}

export const validateJobAlert = async (formData) => {
	if (formData.enableAlert === true) {
		if (formData.pauseAlert === true) {
			const isValueAno = authStore.id ? JobAlertEditPauseSchema : JobAlertEditAnoPauseSchema
			const yupErrors = await isValueAno.validate(formData, { abortEarly: false }).catch((error) => error)
			const errors = yupToFormErrors(yupErrors)
			return errors
		} else {
			const isValueAno = authStore.id ? JobAlertEditSchema : JobAlertEditAnoSchema
			const yupErrors = await isValueAno.validate(formData, { abortEarly: false }).catch((error) => error)

			const errors = yupToFormErrors(yupErrors)
			return errors
		}
	} else {
		const yupErrors = await JobAlertViewSchema.validate(formData, { abortEarly: false }).catch((error) => error)
		const errors = yupToFormErrors(yupErrors)
		return errors
	}
}

export const validateSkills = async (formData) => {
	const yupErrors = await SkillSpecialtySchema.validate(formData, { abortEarly: false }).catch((error) => error)
	const errors = yupToFormErrors(yupErrors)
	return errors
}

export const validatePreferredLocation = async (formData) => {
	const yupErrors = await PreferenceLocationSchema.validate(formData, { abortEarly: false }).catch((error) => error)

	const errors = yupToFormErrors(yupErrors)
	return errors
}

export const validateReferences = async (formData) => {
	const yupErrors = await ReferenceOverviewSchema.validate(formData, { abortEarly: false }).catch((error) => error)

	const errors = yupToFormErrors(yupErrors)
	return errors
}

export const validateTerms = async (formData) => {
	const yupErrors = await VerificationOverviewSchema.validate(formData, { abortEarly: false }).catch((error) => error)

	const errors = yupToFormErrors(yupErrors)
	return errors
}

export const validateProfile = async (formData) => {
	const yupErrors = await ProfileOverviewSchema.validate(formData, { abortEarly: false }).catch((error) => error)

	const errors = yupToFormErrors(yupErrors)
	return errors
}

export const VALIDATE_FORM_DATA = {
	[INTEGRATION_OBJECTS.resume]: validateResume,
	[INTEGRATION_OBJECTS.skillSpecialty]: validateSkills,
	[INTEGRATION_OBJECTS.referenceOnboarding]: validateReferences,
	[INTEGRATION_OBJECTS.preferredLocationOnboarding]: validatePreferredLocation,
	[INTEGRATION_OBJECTS.terms]: validateTerms,
}

import { generateUUID } from '~/common/helpers/uid.helper'

export const getSessionId = () => sessionStorage.getItem('session_id')

export const createSessionId = () => {
	const sessionId = generateUUID()
	sessionStorage.setItem('session_id', sessionId)
	return sessionId
}

export const initializeSessionId = () => {
	if (!getSessionId()) {
		createSessionId()
	}
	return getSessionId()
}

export const removeSessionId = () => {
	sessionStorage.removeItem('session_id')
}

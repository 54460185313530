import React from 'react'
import { NotifyDialog } from '../notify'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { observer } from '~/common/mobx.decorator'
import { otpDialogStore } from '~/components/otp-dialog/otp-dialog.store'
import { convertToUSPhoneFormat } from '~/common/helpers/mapping.helper'
import { careStore } from '~/companies/care/care.store'

export const VerificationSixTimesDialog = observer(({ handleOnClickCloseModals, data }) => {
	const { t } = useTranslation()

	return (
		<NotifyDialog
			open={otpDialogStore.showVerificationSixTimes}
			okText="OK, I GOT IT"
			onOk={() => handleOnClickCloseModals()}
			title={careStore.isJTP ? t('$MESSAGES.REACH_LIMIT_PHONE_NUMBER_CHANGES') : t('$MESSAGES.PHONE_VERIFICATION_SIX_TIMES_DIALOG')}
		>
			{!careStore.isJTP && (
				<>
					Phone verification feature is temporarily locked. <br /> <br />
					Please contact us for support at{' '}
					<a style={{ color: '#0083C9', textDecoration: 'none' }} href={`mailto:${data?.appInit?.company?.mainContactEmail}`}>
						{data?.appInit?.company?.mainContactEmail}
					</a>{' '}
					or call us at{' '}
					<a style={{ color: '#0083C9', textDecoration: 'none' }} href={`tel:${data?.appInit?.company?.phone}`}>
						{convertToUSPhoneFormat(data?.appInit?.company?.phone)}
					</a>{' '}
					for assistance.
				</>
			)}
			{careStore.isJTP && (
				<>
					{t('$MESSAGES.REACH_LIMIT_PHONE_NUMBER_CHANGES_DESC')}{' '}
					<a style={{ color: '#0083C9', textDecoration: 'none' }} href={`mailto:${data?.appInit?.company?.mainContactEmail}`}>
						{data?.appInit?.company?.mainContactEmail}
					</a>
					{'.'}
				</>
			)}
		</NotifyDialog>
	)
})

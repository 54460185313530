import { gql } from '@apollo/client'
import { FILE_FRAGMENT, WORKER_ONBOARDING_PART_FRAGMENT } from '../apollo.fragment'

export const UPDATE_WORKER_ONBOARDING_PART_MUTATION = gql`
	mutation updateWorkerOnboardingPart($id: String!, $integrationAttributes: JSON!) {
		updateWorkerOnboardingPart(id: $id, integrationAttributes: $integrationAttributes) {
			...workerOnboardingPartFragment
		}
	}

	${WORKER_ONBOARDING_PART_FRAGMENT}
`

export const UPDATE_WORKER_RESUMES_MUTATION = gql`
	mutation updateWorkerResumes($id: String!, $signedBlobId: String!) {
		updateIntegrationWorker(id: $id, signedBlobId: $signedBlobId) {
			resume {
				...fileFragment
			}
		}
	}

	${FILE_FRAGMENT}
`

import { gql } from '@apollo/client'
import { FILE_FRAGMENT, ONBOARDING_PART_FRAGMENT, WORKER_ONBOARDING_PART_FRAGMENT } from '../apollo.fragment'

export const GET_WORKER_ONBOARDING_OVERVIEW_QUERY = gql`
	query getWorkerOnboardingQuery {
		workerOnboardingOverview {
			id
			message
			percentageCompleted
			state
			companyOnboarding {
				companyId
				description
				id
				name
				onboardingPartsNumber
			}
			onboardingParts {
				workerOnboardingPart {
					...workerOnboardingPartFragment
				}
				...onboardingPartFragment
			}
		}
	}

	${ONBOARDING_PART_FRAGMENT}
	${WORKER_ONBOARDING_PART_FRAGMENT}
`

export const GET_WORKER_ONBOARDING_PART_QUERY = gql`
	query getWorkerOnboardingPart($id: String!) {
		onboardingPart(id: $id) {
			workerOnboardingPart {
				...workerOnboardingPartFragment
			}
		}
	}

	${WORKER_ONBOARDING_PART_FRAGMENT}
`

export const GET_WORKER_RESUMES_QUERY = gql`
	query workerResumesQuery($id: String!) {
		worker(id: $id) {
			resumes {
				...fileFragment
			}
		}
	}
	${FILE_FRAGMENT}
`

import { useRequest } from 'ahooks'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Helmet } from 'react-helmet'
import { JNP_META_DATA, JNP_META_DEFAULT, JTP_META_DATA, JTP_META_DEFAULT } from '~/common/constants/common.constant'
import { delay } from '~/common/helpers'
import { careStore } from '~/companies/care/care.store'
import { routerStore } from '~/stores'

export const Meta = observer(() => {
	const { data, loading } = useRequest(async () => {
		await delay(300)
		const branchData = await window.host.getBranchData()

		const data = branchData.data_parsed

		return data
	})

	if (loading || !data) {
		return null
	}

	const currentPath = routerStore.basePath
	let currentData = careStore.isJTP ? JTP_META_DATA[currentPath] : JNP_META_DATA[currentPath]
	if (isEmpty(currentData)) {
		currentData = careStore.isJTP ? JTP_META_DEFAULT : JNP_META_DEFAULT
	}

	const tags = [
		data.$og_title && <meta key="1" property="og:title" content={data.$og_title} />,
		data.$twitter_title && <meta key="2" name="twitter:title" content={data.$twitter_title} />,

		data.$og_description && <meta key="3" property="og:description" content={'Responsive, friendly recruiters that listen'} />,

		data.$twitter_description && <meta key="4" name="twitter:description" content={data.$twitter_description} />,

		<link key="5" rel="canonical" href={window.location.href} />,

		data.$og_image_url && <meta key="6" property="og:image" content={window.location.origin + '/assets/common/app-logo.png'} />,
		data.$twitter_image_url && <meta key="7" property="twitter:image:src" content={data.$twitter_image_url} />,
		<title key="8">{data.$og_title || `${process.env.REACT_APP_COMPANY_NAME}`}</title>,
		<meta key="9" name="title" content={data.$og_title || currentData?.title || `${process.env.REACT_APP_COMPANY_NAME}`} />,
		<meta
			key="10"
			name="description"
			content={
				data.$og_description ||
				currentData?.description ||
				'Travel nursing is financially rewarding, but aspects of it can be a hassle and we’re here to take care of all the details. We’ll seek to understand your career goals and preferences. We’ll work to find the assignments that align and provide you with options. We’ll keep you informed, and we’ll have your back.'
			}
		/>,
	].filter(Boolean)

	if (tags.length === 0) {
		return null
	}

	return <Helmet>{tags}</Helmet>
})

import { action, computed, observable, store } from '~/common/mobx.decorator'
import { apolloClient, GET_LAST_JOBS_DISCOVER_COUNT, GET_LAST_JOBS_QUERY } from '~/common/apollo'
import { authStore } from '~/stores'

@store()
class LastViewedStore {
	@observable lastViewedJobs = []
	@observable totalCount = 0

	@computed
	get lastViewedJobsItems() {
		return this.lastViewedJobs
	}

	@action
	resetCountLastView = () => {
		this.totalCount = 0
	}
	@action
	fetchTotalLastViewedJobs = async () => {
		if (!authStore.id) {
			const lastViewedJobs = localStorage.getItem('lastViewJobs')
			this.totalCount = JSON.parse(lastViewedJobs)?.length || 0
		} else {
			const response = await apolloClient.query({
				query: GET_LAST_JOBS_DISCOVER_COUNT,
			})
			this.totalCount = response?.data?.discoversV2?.lastViewedJobs || 0
		}
	}

	@action
	handleSyncLastViewedJobs = async () => {
		if (authStore.id) {
			window.localStorage.removeItem('lastViewJobs')
		}
	}
	@action
	fetchLastViewedJobs = async () => {
		if (!authStore.id) {
			const lastViewedJobsStore = localStorage.getItem('lastViewJobs')
			const lastViewedJobsMapping = JSON.parse(lastViewedJobsStore)?.map((item) => item?.job)
			this.lastViewedJobs = lastViewedJobsMapping ? lastViewedJobsMapping : []
			this.totalCount = lastViewedJobsMapping?.length || 0
		} else {
			const response = await apolloClient.query({
				query: GET_LAST_JOBS_QUERY,
			})

			this.lastViewedJobs = response?.data?.lastViewedJobs
			this.totalCount = response?.data?.lastViewedJobs?.length || 0
		}
	}

	@action
	setStorageLastViewJob = (job) => {
		const lastViewJobs = JSON.parse(localStorage.getItem('lastViewJobs')) || []
		const newLastViewJob = lastViewJobs?.filter((item) => item?.job?.id !== job?.id).slice(0, 29)
		if (job) {
			newLastViewJob.unshift({ job, lastViewedAt: new Date() })
		}
		localStorage.setItem('lastViewJobs', JSON.stringify(newLastViewJob))
	}
	@action
	mappingSyncLastViewJob = () => {
		const lastViewJobs = JSON.parse(localStorage.getItem('lastViewJobs')) || []
		return lastViewJobs
			?.map((item) => {
				return {
					jobId: item?.job?.id,
					lastViewedAt: item?.lastViewedAt,
				}
			})
			?.filter((item) => item?.hasOwnProperty('jobId'))
	}
}

export const lastViewedStore = new LastViewedStore()

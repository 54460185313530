import { css } from 'styled-components'

export const dateRangeFieldStyle = css`
	width: 100%;

	.MuiBox-root {
		margin-top: 16px;
	}

	.MuiInputBase-root {
		width: 50%;
	}

	.date-range-wrapper {
		display: flex;

		.MuiIconButton-root {
			margin-right: -6px;
		}
		&.current-date {
			.end-date {
				input {
					text-align: end;
				}
			}
		}
	}
	.end-date{
		margin-left: 16px;
	}
`

export const iconButtonStyle = css`
	margin-right: -6px;
`

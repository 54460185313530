import { action, computed, event, observable, store } from '~/common/mobx.decorator'
import { appStore, authStore, notifyStore, routerStore } from '~/stores'
import {
	ActivationNavSvg,
	ChangePassSvg,
	ContactSvg,
	FindSvg,
	HomeSvg,
	MyJobsSvg,
	ProfileNavSvg,
	ResourceSvg,
	RunSidebarSvg,
	SettingsSvg,
	TimecardNavSvg,
	UserPlusSvg,
} from '~/components/icons'
import { EVENTS, PATHS } from '~/common/constants'
import { apolloClient, GET_COMPANY_INFO_QUERY, GET_COMPANY_JNP_INFO_QUERY, GET_WORKER_DETAIL_QUERY } from '~/common/apollo'
import { eventBus } from 'mobx-event-bus2'
import { COMPANY } from '~/common/constants/company.constant'
import { EVENT_CLICK_ID } from '~/common/tracking/event-click.constant'
import { careProfileStore } from '~/features/care-profile/care-profile.store'
import { isEmpty } from 'lodash'
import moment from 'moment'

const FEATURE_TYPE = {
	activated: 'activated',
	nonActivated: 'nonActivated',
	public: 'public',
	common: 'common',
}

@store()
class CareStore {
	@observable showProfileSidebar
	@observable customHeader = {}
	@observable worker = {}
	@observable companyConfig = {}
	@observable workerConfigInfo = {}

	@computed
	get currentYear() {
		return moment().year()
	}

	@computed
	get privacyPolicyUrl() {
		return appStore.config?.companyConfigs?.privacyPolicyUrl
	}

	@computed
	get termsOfUseUrl() {
		return appStore.config?.companyConfigs?.termUrl
	}

	@computed
	get facebookUrl() {
		return this.compamyInfo.facebookUrl
	}

	@computed
	get instagramUrl() {
		return this.compamyInfo.instagramUrl
	}

	@computed
	get twitterUrl() {
		return this.compamyInfo.twitterUrl
	}
	@computed
	get linkedinUrl() {
		return this.compamyInfo.linkedInUrl
	}

	@computed
	get noSms() {
		return this.worker.noSms
	}
	@computed
	get enableSmsSender() {
		return this.worker?.enableSmsSender
	}

	@computed
	get messagingServiceSender() {
		return this.companyConfig?.messagingServiceSender
	}

	@computed
	get privacyPolicyContent() {
		return this.companyConfig?.privacyPolicyContent
	}

	@computed
	get isJTP() {
		return process.env.REACT_APP_COMPANY_HOST === COMPANY.JTP
	}

	@computed
	get features() {
		const publicMenu = this.getFeature(FEATURE_TYPE.public)
		const activatedMenu = this.getFeature(FEATURE_TYPE.activated)
		const nonActivatedMenu = this.getFeature(FEATURE_TYPE.nonActivated)

		return authStore.isAnonymous ? publicMenu : authStore.activated && !authStore.isAnonymous ? activatedMenu : nonActivatedMenu
	}

	@computed
	get allFeatures() {
		const common = this.getFeature(FEATURE_TYPE.common)
		const allFeature = [...this.features, ...common]

		return allFeature?.find((feature) => feature.path === routerStore.basePath)
	}

	@computed
	get activeFeature() {
		return this.features?.find((feature) => feature.path === routerStore.basePath)
	}

	@computed
	get code() {
		return this.activeFeature?.code
	}

	@computed
	get name() {
		return this.activeFeature?.name || ''
	}

	@computed
	get useLayout() {
		return this.activeFeature?.useLayout
	}
	@computed
	get getReferalRewardContent() {
		return this.companyConfig.referalRewardContent
	}

	@computed
	get sidebarMenu() {
		return this.features?.filter((feature) => feature.showSideBar)
	}

	@computed
	get dropDownMenu() {
		return this.features?.filter((feature) => feature.showDropdown)
	}

	@computed
	get appbarMenu() {
		return this.features?.filter((feature) => feature.showAppBar)
	}

	@action
	fetchWorkerDetail = async () => {
		if (isEmpty(authStore.id)) {
			return
		}
		const { data } = await apolloClient.query({
			query: GET_WORKER_DETAIL_QUERY,
			variables: { id: authStore.id },
		})
		this.worker = data.worker
		if (!data.worker?.phoneVerifiedAt) {
			await careProfileStore.setWorker(data.worker)
		}
	}

	@computed
	get compamyInfo() {
		return this.company
	}

	@computed
	get companyName() {
		return this.compamyInfo?.name
	}

	@action
	fetchCompanyInfo = async () => {
		const { data } = await apolloClient.query({ query: careStore.isJTP ? GET_COMPANY_INFO_QUERY : GET_COMPANY_JNP_INFO_QUERY })
		this.companyConfig = {
			linkedInUrl: data?.appInit?.companyConfig?.linkedInUrl,
			twitterUrl: data?.appInit?.companyConfig?.twitterUrl,
			instagramUrl: data?.appInit?.companyConfig?.instagramUrl,
			facebookUrl: data?.appInit?.companyConfig?.facebookUrl,
			defaultLocationImageWebUrl: data?.appInit?.companyConfig?.defaultLocationImageWebUrl,
			defaultLocationImageThumbnailUrl: data?.appInit?.companyConfig?.defaultLocationImageThumbnailUrl,
			privacyPolicyContent: data?.appInit?.companyConfig?.privacyPolicyContent,
			referalRewardContent: data?.appInit?.companyConfig?.referalRewardContent,
			messagingServiceSender: data?.appInit?.companyConfig?.messagingServiceSender,
			externalResourceUrl: data?.appInit?.companyConfig?.externalResourceUrl,
			externalImmediatePayUrl: data?.appInit?.companyConfig?.externalImmediatePayUrl,
		}
		this.company = {
			email: data?.appInit?.company?.mainContactEmail,
			phone: data?.appInit?.company?.phone,
			name: data?.appInit?.company?.name,
		}
		this.workerConfigInfo = { hasActiveAssignment: data?.appInit?.worker?.hasActiveAssignment, referralCode: data?.appInit?.worker?.referralCode }
	}

	getFeature = (type) => {
		const activatedMenu = [
			{
				code: 'home',
				name: 'HOME',
				path: PATHS.care.home,
				icon: HomeSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickHomeNavBtn,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.home : PATHS.banners.first,
			},
			{
				code: 'profile',
				name: 'PROFILE',
				path: PATHS.care.profile,
				icon: ProfileNavSvg,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				showDropdown: true,
				isDisabled: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.profile : PATHS.banners.third,
			},
			{
				code: 'expressPass',
				name: 'EXPRESS_PASS',
				pageTitle: 'EXPRESS_PASS_PAGE',
				path: PATHS.care.express_pass,
				icon: RunSidebarSvg,
				auth: true,
				showAppBar: false,
				showSideBar: this.isJTP,
				isDisabled: false,
				useLayout: true,
				showDropdown: false,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.express : PATHS.banners.first,
			},
			{
				code: 'job-results',
				name: 'SEARCH_JOBS',
				path: PATHS.care.search,
				icon: FindSvg,
				auth: true,
				showAppBar: true,
				eventId: EVENT_CLICK_ID.clickSearchJobsNavBtn,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.search : PATHS.banners.third,
			},
			{
				code: 'filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.filter,
				icon: FindSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'edit-filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.edit_filter,
				icon: FindSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'job-results',
				name: 'SEARCH_RESULTS',
				path: PATHS.care.search,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'my-jobs',
				name: 'MYJOBS',
				path: PATHS.care.my_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickMyJobsNavBtn,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.myJob : PATHS.banners.third,
			},
			{
				code: 'timecards',
				name: 'TIMECARDS',
				path: PATHS.care.timecards,
				icon: TimecardNavSvg,
				auth: false,
				showAppBar: true,
				showSideBar: true,
				hideBanner: !this.isJTP ? true : false,
				isDisabled: false,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickTimecardsNavBtn,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.timecard : PATHS.banners.four,
			},

			{
				code: 'referrer',
				name: 'REFERRER',
				icon: UserPlusSvg,
				path: PATHS.care.referrer,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},

			{
				code: 'referral-traveller',
				name: this.isJTP ? 'REFER_FRIENDS_GET_REWARDED' : 'REFER_TRAVELER',
				icon: UserPlusSvg,
				path: PATHS.care.refer_traveler,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.home : PATHS.banners.first,
			},
			{
				code: 'contact-us',
				name: 'CONTACT_US',
				icon: ContactSvg,
				path: PATHS.care.contact_us,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.settings : PATHS.banners.four,
			},
			{
				code: 'change-password',
				name: 'CHANGE_PASSWORD',
				icon: ChangePassSvg,
				path: PATHS.common.change_password,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.settings : PATHS.banners.third,
			},
			{
				code: 'update-phone',
				name: 'UPDATE_PHONE_NUMBER',
				icon: ChangePassSvg,
				path: PATHS.common.update_phone,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'settings',
				name: 'SETTINGS',
				path: PATHS.care.settings,
				eventId: EVENT_CLICK_ID.clickSettingsNavBtn,
				icon: SettingsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: true,
				isDisabled: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.settings : PATHS.banners.four,
			},
			{
				code: 'popular-cities',
				name: 'POPULAR_CITIES',
				path: PATHS.care.popular_cities,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'explore-matches',
				name: 'EXPLORE_MATCHES',
				path: PATHS.care.suggested_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'urgent-needs',
				name: 'URGENT_NEEDS',
				path: PATHS.care.urgent_needs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'trending-jobs',
				name: 'TRENDING_JOBS',
				path: PATHS.care.trending_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'job-alerts',
				name: 'NOTIFICATIONS',
				path: PATHS.care.job_alerts,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.second,
			},

			{
				code: 'last-viewed',
				name: 'LAST_VIEWED',
				path: PATHS.care.last_viewed,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'discover',
				name: 'DISCOVER',
				path: PATHS.care.discover,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'signup',
				name: 'SIGNUP',
				path: PATHS.common.signup,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'signin',
				name: 'SIGNIN',
				path: PATHS.common.signin,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'email-unsubscribe',
				name: 'EMAIL_UNSUBSCRIBE',
				path: PATHS.care.email_unsubscribe,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'resources',
				name: 'RESOURCES',
				path: PATHS.care.resources,
				icon: ResourceSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: this.isJTP ? false : true,
				isDisabled: false,
				useLayout: true,
			},
		].filter(Boolean)

		const nonActivatedMenu = [
			{
				code: 'signup',
				name: 'SIGNUP',
				path: PATHS.common.signup,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'job-alerts',
				name: 'NOTIFICATIONS',
				path: PATHS.care.job_alerts,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.second,
			},

			{
				code: 'update-phone',
				name: 'UPDATE_PHONE_NUMBER',
				icon: ChangePassSvg,
				path: PATHS.common.update_phone,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'signin',
				name: 'SIGNIN',
				path: PATHS.common.signin,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'home',
				name: 'HOME',
				path: PATHS.care.home,
				icon: HomeSvg,
				auth: false,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickHomeNavBtn,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.home : PATHS.banners.first,
			},
			{
				code: 'activation',
				name: 'PROFILE',
				path: PATHS.care.activation,
				icon: ActivationNavSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
				showDropdown: true,
				eventId: EVENT_CLICK_ID.clickCompleteYourProfileNavBtn,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.profile : PATHS.banners.second,
			},
			{
				code: 'expressPass',
				name: 'EXPRESS_PASS',
				pageTitle: 'EXPRESS_PASS_PAGE',
				path: PATHS.care.express_pass,
				icon: RunSidebarSvg,
				auth: true,
				showAppBar: false,
				showSideBar: this.isJTP,
				isDisabled: true,
				useLayout: true,
				showDropdown: this.isJTP,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.express : PATHS.banners.first,
			},
			{
				code: 'job-results',
				name: 'SEARCH_JOBS',
				path: PATHS.care.search,
				icon: FindSvg,
				auth: false,
				showAppBar: true,
				showSideBar: true,
				eventId: EVENT_CLICK_ID.clickSearchJobsNavBtn,
				isDisabled: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.search : PATHS.banners.second,
			},
			{
				code: 'filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.filter,
				icon: FindSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'job-results',
				name: 'SEARCH_RESULTS',
				path: PATHS.care.search,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'my-jobs',
				name: 'MYJOBS',
				path: PATHS.care.my_jobs,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: true,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickMyJobsNavBtn,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.myJob : PATHS.banners.third,
			},
			{
				code: 'timecards',
				name: 'TIMECARDS',
				path: PATHS.care.timecards,
				icon: TimecardNavSvg,
				auth: false,
				showAppBar: true,
				showSideBar: true,
				hideBanner: !this.isJTP ? true : false,
				isDisabled: false,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickTimecardsNavBtn,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.timecard : PATHS.banners.four,
			},
			{
				code: 'edit-filter-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.edit_filter,
				icon: FindSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},

			{
				code: 'referrer',
				name: 'REFERRER',
				icon: UserPlusSvg,
				path: PATHS.care.referrer,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},

			{
				code: 'referral-traveller',
				name: this.isJTP ? 'REFER_FRIENDS_GET_REWARDED' : 'REFER_TRAVELER',
				icon: UserPlusSvg,
				path: PATHS.care.refer_traveler,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.home : PATHS.banners.first,
			},
			{
				code: 'contact-us',
				name: 'CONTACT_US',
				icon: ContactSvg,
				path: PATHS.care.contact_us,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.settings : PATHS.banners.four,
			},
			{
				code: 'popular-cities',
				name: 'POPULAR_CITIES',
				path: PATHS.care.popular_cities,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'explore-matches',
				name: 'EXPLORE_MATCHES',
				path: PATHS.care.suggested_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'urgent-needs',
				name: 'URGENT_NEEDS',
				path: PATHS.care.urgent_needs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'trending-jobs',
				name: 'TRENDING_JOBS',
				path: PATHS.care.trending_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'last-viewed',
				name: 'LAST_VIEWED',
				path: PATHS.care.last_viewed,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.first,
			},

			{
				code: 'change-password',
				name: 'CHANGE_PASSWORD',
				icon: ChangePassSvg,
				path: PATHS.common.change_password,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				showDropdown: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.settings : PATHS.banners.third,
			},
			{
				code: 'settings',
				name: 'SETTINGS',
				path: PATHS.care.settings,
				eventId: EVENT_CLICK_ID.clickProfileNavBtn,
				icon: SettingsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				showDropdown: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.settings : PATHS.banners.four,
			},
			{
				code: 'jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'discover',
				name: 'DISCOVER',
				path: PATHS.care.discover,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'email-unsubscribe',
				name: 'EMAIL_UNSUBSCRIBE',
				path: PATHS.care.email_unsubscribe,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
				bannerUrl: PATHS.banners.second,
			},
			{
				code: 'resources',
				name: 'RESOURCES',
				path: PATHS.care.resources,
				icon: ResourceSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				showDropdown: this.isJTP ? false : true,
				isDisabled: false,
				useLayout: true,
			},
		]

		const publicMenu = [
			{
				code: 'jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.four,
			},
			{
				code: 'referrer',
				name: 'REFERRER',
				icon: UserPlusSvg,
				path: PATHS.care.referrer,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'update-phone',
				name: 'UPDATE_PHONE_NUMBER',
				icon: ChangePassSvg,
				path: PATHS.common.update_phone,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'signup',
				name: 'SIGNUP',
				path: PATHS.common.signup,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},

			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'home',
				name: 'HOME',
				path: PATHS.care.home,
				icon: HomeSvg,
				auth: false,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				eventId: EVENT_CLICK_ID.clickHomeNavBtn,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.home : PATHS.banners.first,
			},
			{
				code: 'job-results',
				path: PATHS.care.search,
				icon: FindSvg,
				auth: false,
				showAppBar: true,
				eventId: EVENT_CLICK_ID.clickSearchJobsNavBtn,
				showSideBar: true,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.search : PATHS.banners.first,
			},
			{
				code: 'contact-us',
				name: 'CONTACT_US',
				icon: ContactSvg,
				path: PATHS.care.contact_us,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: true,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.settings : PATHS.banners.four,
			},
			{
				code: 'signup',
				name: 'SIGNUP',
				path: PATHS.common.signup,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'last-viewed',
				name: 'LAST_VIEWED',
				path: PATHS.care.last_viewed,
				icon: MyJobsSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
				bannerUrl: PATHS.banners.first,
			},
			{
				code: 'email-unsubscribe',
				name: 'EMAIL_UNSUBSCRIBE',
				path: PATHS.care.email_unsubscribe,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
				bannerUrl: PATHS.banners.second,
			},
		].filter(Boolean)

		const common = [
			{
				code: 'signup',
				name: 'SIGNUP',
				path: PATHS.common.signup,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'authorization',
				name: 'AUTHORIZATION',
				path: PATHS.common.authorization,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'signin',
				name: 'SIGNIN',
				path: PATHS.common.signin,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'reset',
				name: 'RESET',
				path: PATHS.common.reset,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'change-password',
				name: 'CHANGE_PASSWORD',
				path: PATHS.common.change_password,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
				bannerUrl: this.isJTP ? PATHS.bannersJTP.settings : PATHS.banners.third,
			},
			{
				code: 'errors',
				name: 'ERRORS',
				path: PATHS.common.errors,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
		].filter(Boolean)

		let result = null
		switch (type) {
			case FEATURE_TYPE.activated:
				result = activatedMenu
				break
			case FEATURE_TYPE.nonActivated:
				result = nonActivatedMenu
				break
			case FEATURE_TYPE.public:
				result = publicMenu
				break
			case FEATURE_TYPE.common:
				result = common
				break
			default:
				result = []
				break
		}
		return result
	}

	@action
	toogleProfileSidebar = async () => {
		this.showProfileSidebar = !this.showProfileSidebar
	}

	@action
	logout = async () => {
		this.showProfileSidebar = false
		notifyStore.unInitOneSignal()

		eventBus.post(EVENTS.authStore.logout)
		routerStore.goPage(PATHS.common.authorization)
	}

	@action
	handleClickChangePassword = async () => {
		this.showProfileSidebar = false
		await routerStore.goPage(PATHS.common.change_password)
	}

	@event(EVENTS.careStore.updateHeader)
	async onUpdateHeader({ payload }) {
		this.customHeader = payload
	}
}

export const careStore = new CareStore()

import { unflatten } from 'flat'
import { find, get, isEmpty, keyBy, mapValues } from 'lodash'

export const toFormErrors = (error, baseField = '__ERROR') => {
	const errors = unflatten(
		mapValues(
			keyBy(error?.graphQLErrors, (value) => get(value, 'extensions.attribute', baseField)),
			'message'
		)
	)

	if (isEmpty(errors)) {
		return { [baseField]: error?.message }
	}

	return errors
}

export const isExistingEmailError = (error = {}) => {
	const emailExist = find(error?.graphQLErrors, ({ extensions }) => extensions?.attribute === 'email' && extensions?.error_type === 'taken')
	if (emailExist !== undefined) {
		return true
	}
	return false
}

export const isPhoneInvalidError = (error = {}) => {
	const phoneInvalid = find(error?.graphQLErrors, ({ extensions }) => extensions?.attribute === 'phone' && extensions?.error_type === 'invalid_phone')
	if (phoneInvalid !== undefined) {
		return true
	}
	return false
}

export const isPhoneChangeLimited = (error = {}) => {
	const phoneInvalid = find(error?.graphQLErrors, ({ extensions }) => {
		return extensions?.type?.phone?.[0]?.error === 'phone_change_limited'
	})
	if (phoneInvalid !== undefined) {
		return true
	}
	return false
}

import { create } from 'mobx-persist'
import localforage from 'localforage'
import remotedev from 'mobx-remotedev'
import { eventBus, subscribe } from 'mobx-event-bus2'
import { loading as baseLoading, loadingStore } from 'mobx-loading'
import { action as baseAction } from 'mobx'
import { lowerFirst } from 'lodash'

export * from 'mobx'
export * from 'mobx-react-lite'
export * from 'mobx-loading'
export * from 'mobx-event-bus2'
export * from 'mobx-persist'

export const storage = localforage

export const hydrate = create({ storage, jsonify: false })

export const store = (options) => (target) => {
	let decorator = function (...args) {
		const instance = new target(...args)
		const name = instance.constructor.name

		if (options?.persist) {
			hydrate(name, instance)
		}

		remotedev(instance, { name })
		eventBus.register(instance)

		return instance
	}

	decorator.prototype = target.prototype
	return decorator
}

function fireEvent(func, eventName) {
	return function () {
		const payload = arguments[0]
		eventBus.post(eventName, payload)
		const promise = func.apply(this, arguments)

		if (!promise || typeof promise.then !== 'function') {
			return promise
		}

		return promise
			.then((data) => {
				eventBus.post(`${eventName}.success`, { event: `${eventName}.success`, data, payload })
				return data
			})
			.catch((error) => {
				console.error(error)
				eventBus.post(`${eventName}.failure`, { event: `${eventName}.failure`, error, payload })
			})
	}
}

export const baseEvent = (target, eventName, descriptor) => {
	const des = {
		enumerable: false,
		configurable: true,
		writable: true,
	}

	if (descriptor.value) {
		des.value = fireEvent(descriptor.value, eventName)
	} else {
		des.initializer = function () {
			return fireEvent(descriptor.initializer.call(this), eventName)
		}
	}

	return des
}

export const action = (target, key, descriptor) => {
	const eventName = `${lowerFirst(target.constructor.name)}/${key}`
	const func1 = baseEvent(target, eventName, descriptor)
	const func2 = baseLoading(eventName)(target, eventName, func1)

	return baseAction(target, eventName, func2)
}

export const event = (target, options) => {
	return function (...args) {
		let eventName = typeof target === 'string' ? target : target.name

		if (options?.success) {
			eventName = `${eventName}.success`
		} else if (options?.failure) {
			eventName = `${eventName}.failure`
		}

		subscribe(eventName)(...args)
		return baseAction(...args)
	}
}

export const loadingState = (target) => {
	const eventName = typeof target === 'string' ? target : target.name
	return loadingStore.actions[eventName]
}

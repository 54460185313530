import { gql } from '@apollo/client'
import { apolloClient, GET_WORKER_SPECIALTIES_QUERY } from '~/common/apollo'
import { WORKER_CERTIFICATION_FRAGMENT, WORKER_EDUCATION_FRAGMENT, WORKER_REFERENCE_FRAGMENT, WORK_EXPERIENCE_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
	query getWorkerCertifications($workerId: String!) {
		workerCertifications(workerId: $workerId) {
			...workerCertificationFragment
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const CREATE_WORKER_CERTIFICATION_MUTATION = gql`
	mutation createWorkerCertificationMutation(
		$title: String
		$licenseDiscipline: String
		$licenseState: String
		$licenseNumber: String
		$compactLicense: Boolean
		$expirationDate: ISO8601Date
		$certificationType: String!
		$signedBlobIds: [String!]
	) {
		createWorkerCertification(
			title: $title
			certificationType: $certificationType
			licenseDiscipline: $licenseDiscipline
			licenseState: $licenseState
			licenseNumber: $licenseNumber
			compactLicense: $compactLicense
			expirationDate: $expirationDate
			signedBlobIds: $signedBlobIds
		) {
			...workerCertificationFragment
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const CREATE_WORKER_REFERENCE_MUTATION = gql`
	mutation createWorkerReferenceMutation(
		$contactEmail: String!
		$facilityName: String!
		$contactLastName: String!
		$contactFirstName: String!
		$contactPhone: String!
		$jobTitle: String!
		$refType: WorkerReferenceTypeEnum!
		$workedFrom: ISO8601Date!
		$workedTo: ISO8601Date!
		$salutation: WorkerReferenceSalutationEnum
		$relationship: String
		$city: String
		$state: String
	) {
		createWorkerReference(
			contactEmail: $contactEmail
			facilityName: $facilityName
			contactFirstName: $contactFirstName
			contactLastName: $contactLastName
			contactPhone: $contactPhone
			jobTitle: $jobTitle
			refType: $refType
			workedFrom: $workedFrom
			workedTo: $workedTo
			salutation: $salutation
			relationship: $relationship
			city: $city
			state: $state
		) {
			...workerReferenceFragment
		}
	}

	${WORKER_REFERENCE_FRAGMENT}
`

export const UPDATE_WORKER_REFERENCE_MUTATION = gql`
	mutation updateWorkerReferenceMutation(
		$id: String!
		$contactEmail: String!
		$facilityName: String!
		$contactFirstName: String!
		$contactLastName: String!
		$contactPhone: String!
		$jobTitle: String!
		$refType: WorkerReferenceTypeEnum!
		$workedFrom: ISO8601Date!
		$workedTo: ISO8601Date!
		$salutation: WorkerReferenceSalutationEnum
		$relationship: String
		$city: String
		$state: String
	) {
		updateWorkerReference(
			id: $id
			contactEmail: $contactEmail
			facilityName: $facilityName
			contactFirstName: $contactFirstName
			contactLastName: $contactLastName
			contactPhone: $contactPhone
			jobTitle: $jobTitle
			refType: $refType
			workedFrom: $workedFrom
			workedTo: $workedTo
			salutation: $salutation
			relationship: $relationship
			city: $city
			state: $state
		) {
			...workerReferenceFragment
		}
	}

	${WORKER_REFERENCE_FRAGMENT}
`

export const GET_WORKER_REFERENCES_QUERY = gql`
	query getWorkerReferencesQuery($workerId: String!) {
		workerReferences(workerId: $workerId) {
			...workerReferenceFragment
		}
	}

	${WORKER_REFERENCE_FRAGMENT}
`

export const GET_WORK_EXPERIENCES_QUERY = gql`
	query getWorkExperiencesQuery($workerId: String!) {
		worker(id: $workerId) {
			workExperiences {
				...workExperienceFragment
			}
		}
	}

	${WORK_EXPERIENCE_FRAGMENT}
`

export const GET_WORKER_EDUCATIONS_QUERY = gql`
	query getWorkerEducations {
		workerEducations {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const CREATE_WORKER_EDUCATION_MUTATION = gql`
	mutation createWorkerEducationMutation(
		$schoolName: String!
		$major: String!
		$state: String!
		$city: String
		$degreeName: String!
		$degreeDate: ISO8601Date
	) {
		createWorkerEducation(schoolName: $schoolName, major: $major, state: $state, city: $city, degreeName: $degreeName, degreeDate: $degreeDate) {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const CREATE_EDUCATION_FOR_WORKER_MUTATION = gql`
	mutation createWorkerEducationMutation($schoolName: String!, $state: String!, $city: String, $degreeName: String!, $degreeDate: ISO8601Date) {
		createWorkerEducation(schoolName: $schoolName, state: $state, city: $city, degreeName: $degreeName, degreeDate: $degreeDate) {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const UPDATE_EDUCATION_FOR_WORKER_MUTATION = gql`
	mutation updateWorkerEducationMutation($id: String!, $schoolName: String!, $state: String!, $city: String, $degreeName: String!, $degreeDate: ISO8601Date) {
		updateWorkerEducation(id: $id, schoolName: $schoolName, state: $state, city: $city, degreeName: $degreeName, degreeDate: $degreeDate) {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`
export const UPDATE_WORKER_PREFERRED_LOCATION_MUTATION = gql`
	mutation($workingPreferredLocationsAttributes: [WorkingPreferredLocationArgument!]!) {
		updateWorkingPreferredLocations(workingPreferredLocationsAttributes: $workingPreferredLocationsAttributes) {
			id
			preferredWorkingCity
			preferredWorkingState
		}
	}
`

export const CREATE_WORK_EXPERIENCE_MUTATION = gql`
	mutation updateWorkExperienceMutation($id: String!, $experiences: [WorkExperienceArgument!]) {
		updateIntegrationWorker(id: $id, workExperiencesAttributes: $experiences) {
			workExperiences {
				...workExperienceFragment
			}
		}
	}
	${WORK_EXPERIENCE_FRAGMENT}
`

export const CREATE_WORK_EXPERIENCE_MUTATION_FOR_WORKER = gql`
	mutation createWorkExperienceMutation(
		$facilityName: String!
		$position: String!
		$unitSpecialty: String
		$floatSpecialty: String
		$facilityType: String
		$patientType: String
		$city: String
		$state: String
		$country: String
		$travelAssignment: Boolean
		$agency: String!
		$reasonForLeaving: String!
		$dutiesPerformed: String
		$startDate: ISO8601Date!
		$endDate: ISO8601Date
		$currentlyEmployed: Boolean
		$assignmentType: String
		$supervisor: String
		$mayContactSupervisor: Boolean
		$nurseRatio: String
		$patientRatio: String
		$bedsInUnit: Int
		$birthsPerMonth: String
		$numberSuites: Float
		$dailyCensus: Float
		$chartingSoftwareUsed: String
	) {
		createWorkExperience(
			facilityName: $facilityName
			position: $position
			unitSpecialty: $unitSpecialty
			floatSpecialty: $floatSpecialty
			facilityType: $facilityType
			patientType: $patientType
			city: $city
			state: $state
			country: $country
			travelAssignment: $travelAssignment
			agency: $agency
			reasonForLeaving: $reasonForLeaving
			dutiesPerformed: $dutiesPerformed
			startDate: $startDate
			endDate: $endDate
			currentlyEmployed: $currentlyEmployed
			assignmentType: $assignmentType
			supervisor: $supervisor
			mayContactSupervisor: $mayContactSupervisor
			nurseRatio: $nurseRatio
			patientRatio: $patientRatio
			bedsInUnit: $bedsInUnit
			birthsPerMonth: $birthsPerMonth
			numberSuites: $numberSuites
			dailyCensus: $dailyCensus
			chartingSoftwareUsed: $chartingSoftwareUsed
		) {
			...workExperienceFragment
		}
	}
	${WORK_EXPERIENCE_FRAGMENT}
`

export const UPDATE_WORK_EXPERIENCE_MUTATION_FOR_WORKER = gql`
	mutation updateWorkExperienceMutation(
		$id: String!
		$facilityName: String!
		$position: String!
		$unitSpecialty: String
		$floatSpecialty: String
		$facilityType: String
		$patientType: String
		$city: String
		$state: String
		$country: String
		$travelAssignment: Boolean
		$agency: String
		$reasonForLeaving: String!
		$dutiesPerformed: String
		$startDate: ISO8601Date!
		$endDate: ISO8601Date
		$currentlyEmployed: Boolean
		$assignmentType: String
		$supervisor: String
		$mayContactSupervisor: Boolean
		$nurseRatio: String
		$patientRatio: String
		$bedsInUnit: Int
		$birthsPerMonth: String
		$numberSuites: Float
		$dailyCensus: Float
		$chartingSoftwareUsed: String
	) {
		updateWorkExperience(
			id: $id
			facilityName: $facilityName
			position: $position
			unitSpecialty: $unitSpecialty
			floatSpecialty: $floatSpecialty
			facilityType: $facilityType
			patientType: $patientType
			city: $city
			state: $state
			country: $country
			travelAssignment: $travelAssignment
			agency: $agency
			reasonForLeaving: $reasonForLeaving
			dutiesPerformed: $dutiesPerformed
			startDate: $startDate
			endDate: $endDate
			currentlyEmployed: $currentlyEmployed
			assignmentType: $assignmentType
			supervisor: $supervisor
			mayContactSupervisor: $mayContactSupervisor
			nurseRatio: $nurseRatio
			patientRatio: $patientRatio
			bedsInUnit: $bedsInUnit
			birthsPerMonth: $birthsPerMonth
			numberSuites: $numberSuites
			dailyCensus: $dailyCensus
			chartingSoftwareUsed: $chartingSoftwareUsed
		) {
			...workExperienceFragment
		}
	}
	${WORK_EXPERIENCE_FRAGMENT}
`
export const createWorkExperience = async (variables) => {
	const response = await apolloClient.mutate({ mutation: CREATE_WORK_EXPERIENCE_MUTATION_FOR_WORKER, variables })

	return response?.data?.createWorkExperience
}

export const updateWorkExperience = async (variables) => {
	const response = await apolloClient.mutate({ mutation: UPDATE_WORK_EXPERIENCE_MUTATION_FOR_WORKER, variables })

	return response?.data?.updateWorkExperience
}

export const createWorkerEducation = async (variables) => {
	const response = await apolloClient.mutate({ mutation: CREATE_EDUCATION_FOR_WORKER_MUTATION, variables })

	return response?.data?.createWorkerEducation
}

export const updateWorkerEducation = async (variables) => {
	const response = await apolloClient.mutate({ mutation: UPDATE_EDUCATION_FOR_WORKER_MUTATION, variables })

	return response?.data?.createWorkerEducation
}

export const getWorkerEducations = async () => {
	const response = await apolloClient.query({ query: GET_WORKER_EDUCATIONS_QUERY })

	return response?.data?.workerEducations
}

export const getWorkExperiences = async (workerId) => {
	const response = await apolloClient.query({ query: GET_WORK_EXPERIENCES_QUERY, variables: { workerId } })

	return response.data?.worker?.workExperiences
}

export const getWorkerReferences = async (workerId) => {
	const response = await apolloClient.query({ query: GET_WORKER_REFERENCES_QUERY, variables: { workerId } })

	return response.data?.workerReferences
}

export const createWorkerReference = async (variables) => {
	const response = await apolloClient.mutate({ mutation: CREATE_WORKER_REFERENCE_MUTATION, variables })

	return response.data?.createWorkerReference
}

export const updateWorkerReference = async (variables) => {
	const response = await apolloClient.mutate({ mutation: UPDATE_WORKER_REFERENCE_MUTATION, variables })

	return response.data?.updateWorkerReference
}

export const getWorkerCertifications = async (workerId) => {
	const response = await apolloClient.query({ query: GET_WORKER_CERTIFICATIONS_QUERY, variables: { workerId } })

	return response?.data?.workerCertifications
}

export const createWorkerCertification = async (variables) => {
	const response = await apolloClient.mutate({ mutation: CREATE_WORKER_CERTIFICATION_MUTATION, variables })

	return response?.data?.createWorkerCertification
}

export const getWorkerSpecialties = async (workerId) => {
	const response = await apolloClient.query({ query: GET_WORKER_SPECIALTIES_QUERY, variables: { workerId } })

	return response?.data?.workerSpecialties
}

export const updateWorkerPreferredLocation = async (variables) => {
	const response = await apolloClient.mutate({ mutation: UPDATE_WORKER_PREFERRED_LOCATION_MUTATION, variables })
	return response?.data?.updateWorkingPreferredLocations
}

import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { careSearchRedirectStore } from './care-search-redirect.store'
import { CircularProgress } from '@material-ui/core'

const SearchRedirect = observer(() => {
	const { link } = useParams() // Capture the 'link' from the URL
	useEffect(() => {
		careSearchRedirectStore.parseLink(link)
		window.location.href = careSearchRedirectStore.getActualLink
	}, [link])

	return <CircularProgress />
})

export default SearchRedirect

import { css, createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html {
    -webkit-transform: unset !important;
  }
  body {
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.background.default} !important;
    overflow-x: hidden;
  }

  :focus {
    outline-color: transparent !important;
  }

  .mobile-open .custom-mobile-menu{
    z-index: 2;
  }
 
  .react-daterange-picker__inputGroup__input {
    -moz-appearance: textfield !important; /* For Firefox */
    appearance: textfield !imporant; /* For other browsers */
  }

  .MuiButtonBase-root {
    user-select: none !important; /* For other browsers */
    -webkit-user-select: none !important; /* For Safari 3+, Safari on iOS 3+ */
}

  .body-wrapper{
    overflow: initial !important;
  }

  @media (max-width: 956px) {
    .rwc-embed {
      bottom: 76px !important;
    }
  }
  .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:focus {
outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  .grecaptcha-badge {
    display: none;
  }

  .pac-container {
    z-index: 3000;
  }
`

export const linearProgressStyle = css`
	position: fixed;
	top: 0;
	width: 100vw;
	height: 3px;
	z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`

import { mergeDeep } from '@apollo/client/utilities'
import { differenceBy, filter, find, findIndex, flatten, includes, isEmpty, isNil, map, omit, pick, remove } from 'lodash'
import {
	apolloClient,
	CREATE_OR_UPDATE_SUPPORT_DOCUMENTS_MUTATION,
	CREATE_OR_UPDATE_WORKER_SPECIALTY,
	DELETE_RESUME_MUTATION,
	DELETE_SUPPORT_DOCUMENTS_MUTATION,
	DELETE_WORKER_SPECIALTY,
	GET_SIGNED_URLS,
	GET_WORKER_DETAIL_QUERY,
	SEND_OTP_CODE_V2_MUTATION,
	SUPPORT_DOCUMENTS_MUTATION,
	UPDATE_WORKER_INFO_MUTATION,
	UPDATE_WORKER_RESUME_MUTATION,
	VERIFY_OTP_CODE_MUTATION,
} from '~/common/apollo'
import { FIELD_MODE } from '~/common/constants'
import { captureException, diffUpdate, isPhoneChangeLimited, RECAPTCHA_CLIENT_KEY, toFormErrors } from '~/common/helpers'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, masterStore, notifyStore } from '~/stores'
import { careStore } from '~/companies/care/care.store'
import { getWorkerSpecialties, UPDATE_WORKER_PREFERRED_LOCATION_MUTATION } from './care-profile.service'
import { generateTempId, isTempId, removeTempIds } from '~/common/helpers/uid.helper'
import {
	convertDocumentsToSaveData,
	convertSkillChecklistToSaveData,
	getWorkerSkillChecklistSections,
} from '../care-activation/utils/convert-form-to-save-data'
import { workerSpecialtiesMapping } from '../care-activation/utils/convert-part-to-form-data'
import { otpDialogStore } from '~/components/otp-dialog/otp-dialog.store'
import { careActivationStore } from '~/features/care-activation/care-activation.store'
import { uploadStatusDialogStore } from '~/components/upload-status-dialog/upload-status-dialog.store'

const VALID_ADDRESS_FIELDS = ['id', 'street', 'city', 'zipcode', 'country', 'state', 'aptNumber']

@store()
class CareProfileStore {
	@observable prevActiveTabIndex = -1
	@observable worker = {}
	@observable fieldMode = FIELD_MODE.view
	@observable activeTabIndex = 0
	@observable verifyPhoneState = false
	@observable addPhoneState = false
	@observable allowShowPopup = false
	@observable supportDocuments = []
	@observable generaticDocuments = []
	@observable __hasSupportDocuments = false
	@observable prevSpecialtiesDocumentData = {}
	@observable workerSpecialties = []
	@observable specialtyList = []
	@observable specialtyKeyword = ''
	@observable specialtyTag = ''
	@observable workingPreferredLocations = []
	@observable cities = []
	@observable preferredLocationUpdating = false

	@observable openDialogSpecialty = false

	@action
	filterById(array, checkId) {
		return array.filter((item) => checkId.includes(item.id))
	}

	@action
	selectListWorkerSpecialties = async (items) => {
		const listItem = this.filterById(this.specialtyList, items)
		let workerSpecialties = this.specialtiesDocumentData?.workerSpecialties || []
		for (let i = 0; i < listItem.length; i++) {
			const item = listItem[i]
			const object = find(workerSpecialties, { specialty: item.skill_name })
			if (object !== undefined) {
				return
			}
			const workerSkillChecklistSections = this.generateWorkerSkillChecklistSections(item)
			const workerSpecialty = {
				id: generateTempId(),
				category: item.category,
				isPrimary: false,
				completedChecklist: !item.has_checklist,
				specialty: item.skill_name,
				specialtyLongName: item.long_name,
				specialtyType: item.specialtyType,
				checklistFile: item.checklistFile,
				status: item.status,
				workerSkillChecklistSections: workerSkillChecklistSections,
			}
			await this.handleSubmitSkillChecklist(workerSpecialty, true)
		}
	}

	@action
	setOpenDialogSpecialty = (value) => {
		this.openDialogSpecialty = value
	}

	@computed
	get fetchOriginalSpecialties() {
		return masterStore.originalSpecialties || {}
	}

	@computed
	get fetchSpecialtyTags() {
		const originalSpecialties = masterStore.originalSpecialties
		return Object.keys(originalSpecialties) || []
	}

	@computed
	get stateOptions() {
		return masterStore.licenseStates
	}

	@computed
	get profileSpecialties() {
		return this.workerSpecialties?.map((specialty) => specialty.specialtyLongName)
	}

	@action
	setAllowShowPopup = async (state) => {
		this.allowShowPopup = state
	}

	@action
	setVerifyPhoneState = async (state) => {
		this.verifyPhoneState = state
	}

	@action
	setAddPhoneState = async (state) => {
		this.addPhoneState = state
	}

	@action
	syncSubmitData = async (formData, dirty = true) => {
		this.workerSpecialties = formData.workerSpecialties
		this.supportDocuments = formData.supportDocuments
	}

	@computed
	get workerPhone() {
		return this.worker?.phone
	}

	@computed
	get phoneNotExisted() {
		return isEmpty(this.worker?.phone) || this.worker?.phone === '+1'
	}

	@computed
	get slideDirection() {
		return this.prevActiveTabIndex > this.activeTabIndex ? 'left' : 'right'
	}

	@computed
	get specialtiesDocumentData() {
		return {
			supportDocuments: this.supportDocuments,
			workerSpecialties: this.workerSpecialties,
			checklistFile: this.workerSpecialties.map((item) => item?.checklistFile),
		}
	}

	@computed
	get preferenceLocationsValues() {
		const states = this.preferredStates?.map((item) => item.preferredWorkingState)
		const cities = this.preferredCities?.map((item) => ({ name: item.preferredWorkingCity, stateCode: item.preferredWorkingState }))
		return { states, cities }
	}

	@computed
	get preferredStates() {
		return this.workingPreferredLocations.filter((item) => isEmpty(item.preferredWorkingCity))
	}

	@computed
	get preferredCities() {
		return this.workingPreferredLocations.filter((item) => !isEmpty(item.preferredWorkingCity))
	}

	@computed
	get infoInitialValues() {
		const { supportDocuments, avatarUrl, resumes, emergencyContact, workerAddress } = this.worker || {}
		return {
			...pick(this.worker, [
				'id',
				'email',
				'phone',
				'phoneVerifiedAt',
				'firstName',
				'lastName',
				'shiftRequested',
				'timeOffRequested',
				'shiftRequested',
				'availableStartDate',
				'workingAuthorization.id',
				'workingAuthorization.socialSecurityNumber',
				'workingAuthorization.dateOfBirth',
				'avatarUrl',
			]),
			resumes: resumes,
			avatar: avatarUrl && { url: avatarUrl },
			emergencyContact: emergencyContact || {},
			workerAddress: (workerAddress && pick(workerAddress, VALID_ADDRESS_FIELDS)) || { zipCode: null },
			supportDocuments: supportDocuments,
			workerSpecialties: this.workerSpecialties,
		}
	}

	@computed
	get resumeInitialValues() {
		const { resumes } = this.worker || []

		return {
			resumes: resumes,
		}
	}

	@action
	fetchWorkerDetail = async () => {
		try {
			if (isEmpty(authStore.id)) {
				return
			}
			const { data } = await apolloClient.query({ query: GET_WORKER_DETAIL_QUERY, variables: { id: authStore.id } })
			this.worker = data.worker
			if (isEmpty(this.worker?.phoneVerifiedAt)) {
				this.verifyPhoneState = true
				this.setAllowShowPopup(true)
			}
			this.addPhoneState = isEmpty(this.worker?.phone) || this.worker?.phone === '+1'
			this.workingPreferredLocations = this.worker?.workingPreferredLocations

			if (isEmpty(data?.worker?.supportDocuments)) {
				await this.generateWorkerDefaultDocuments()
				return
			}
			setTimeout(() => {
				this.initWorkerDocuments(data?.worker?.supportDocuments)
			}, 1000)
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	changeActiveTabIndex = (tabIndex) => {
		if (this.activeTabIndex === tabIndex || tabIndex < 0 || tabIndex > 4) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTabIndex = tabIndex
	}

	@action
	handleSubmitInfo = async (values, { setErrors }, changeFieldMode) => {
		try {
			const updateData = diffUpdate(values, this.infoInitialValues)

			const workerAddress = updateData.workerAddress && pick(mergeDeep({}, values.workerAddress, updateData.workerAddress), VALID_ADDRESS_FIELDS)
			const variables = omit(
				{
					...updateData,
					workerAddress,
					avatarSignedBlobId: values?.image?.filter((item) => !item._destroy)[0]?.signedBlobId,
				},
				['image']
			)

			const { data } = await apolloClient.mutate({
				mutation: UPDATE_WORKER_INFO_MUTATION,
				variables: careStore.isJTP ? omit(variables, 'avatarSignedBlobId') : variables,
			})

			this.worker = data.updateWorker

			authStore.setAvatar(data.updateWorker.avatarUrl)

			if (isNil(this.worker?.phoneVerifiedAt)) {
				this.setVerifyPhoneState(true)
				this.setAllowShowPopup(true)
			}

			if (typeof changeFieldMode === 'function') {
				changeFieldMode(FIELD_MODE.view)
			}

			// await fireStore.clear(COLECTIONS.profile_infos)
			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			if (isPhoneChangeLimited(error)) {
				otpDialogStore.setShowPhoneChangeTwoTimesModal(true)
			} else {
				notifyStore.error(error.message)
				captureException('Care Profile', error)
				setErrors(toFormErrors(error, 'workerAddress.zipcode'))
			}
		}
	}

	@action
	handleSubmitResumes = async (values) => {
		try {
			const resumes_signed_blob_ids = filter(map(values.resumes, (resume) => !resume._destroy && resume.signedBlobId))
			const { data } = await apolloClient.mutate({
				mutation: UPDATE_WORKER_RESUME_MUTATION,
				variables: { resumes_signed_blob_ids },
			})

			this.worker = data.createOrUpdateWorkerResumes
			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			captureException('Care Profile - Resume Tab - Upload Resume', error)
		}
	}

	@action
	handleSendOTP = async (variables) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, { action: 'sendOtpCodeV2' })
					const variableFinal = { ...variables, recaptchaToken: token }
					const response = await apolloClient.mutate({ mutation: SEND_OTP_CODE_V2_MUTATION, variables: variableFinal })
					const { data } = response
					const remainingExpirationTime = data?.sendOtpCodeV2?.worker?.remainingExpirationTime
					await notifyStore.success('$MESSAGES.SEND_OTP')
					await resolve(remainingExpirationTime)
				} catch (error) {
					notifyStore.error(error?.message)
				}
			})
		})
	}

	@action
	handleSubmitOTP = async (variables) => {
		try {
			await apolloClient.mutate({ mutation: VERIFY_OTP_CODE_MUTATION, variables })
			notifyStore.success('$MESSAGES.VERIFY_PHONE')

			this.setVerifyPhoneState(false)
			this.setAllowShowPopup(false)

			await this.fetchWorkerDetail()
			await careStore.fetchWorkerDetail()
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	handleUpdatePhone = async (variables) => {
		try {
			const { data } = await apolloClient.mutate({
				mutation: UPDATE_WORKER_INFO_MUTATION,
				variables: { ...variables, id: this.worker.id },
			})
			this.worker = data.updateIntegrationWorker

			notifyStore.success('$MESSAGES.UPDATE_PHONE')
		} catch (error) {
			notifyStore.error(error?.message)
		}
		this.setAddPhoneState(false)
	}

	@action
	fetchWorkerSpecialties = async () => {
		const data = await getWorkerSpecialties(authStore.id)
		this.workerSpecialties = workerSpecialtiesMapping(data)
		this.worker = { ...this.worker, workerSpecialties: workerSpecialtiesMapping(data) }
	}

	@action
	generateWorkerDefaultDocuments = async () => {
		setTimeout(() => {
			const docTypes = Object.keys(masterStore.documentTypes)
			const supportDocs = docTypes.map((docType) => ({ documentType: docType, id: generateTempId(), documents: [] }))
			supportDocs.push({
				documentType: 'OTHER_DOCUMENT',
				documents: [],
			})
			this.__hasSupportDocuments = false
			this.supportDocuments = supportDocs
		}, 300)
	}

	@action
	fetchSupportDocuments = async () => {
		const { data } = await apolloClient.mutate({
			mutation: SUPPORT_DOCUMENTS_MUTATION,
			variables: {
				worker_id: authStore.id,
			},
		})

		if (isEmpty(data?.supportDocuments)) {
			await this.generateWorkerDefaultDocuments()
			return
		}
		await this.initWorkerDocuments(data?.supportDocuments)
	}

	@action
	initWorkerDocuments = async (documents) => {
		const docTypes = Object.keys(masterStore.documentTypes)
		let supportDocs = []
		for (var i = 0; i < docTypes.length; i++) {
			// eslint-disable-next-line
			let docs = filter(documents, (doc) => doc?.documentType === docTypes[i]).map((item) => ({ ...item.document, supportDocumentId: item.id }))
			if (docs <= 0) {
				supportDocs.push({
					documentType: docTypes[i],
					documents: [],
				})
			} else {
				supportDocs.push({ documentType: docTypes[i], documents: docs })
			}
		}
		supportDocs.push({
			documentType: 'OTHER_DOCUMENT',
			documents: documents
				.filter((item) => includes(['OTHER_DOCUMENT', 'Other'], item.documentType))
				?.map((item) => ({ ...item.document, supportDocumentId: item.id })),
		})
		this.__hasSupportDocuments = true
		this.supportDocuments = supportDocs
	}

	@action
	handleSubmitDocuments = async (formData) => {
		try {
			const supportDocuments = formData?.supportDocuments
			const supportDocumentsAttributes = convertDocumentsToSaveData(formData)

			if (supportDocumentsAttributes.length > 0) {
				const { data } = await apolloClient.mutate({
					mutation: CREATE_OR_UPDATE_SUPPORT_DOCUMENTS_MUTATION,
					variables: {
						supportDocumentsAttributes: supportDocumentsAttributes,
					},
				})

				await this.initWorkerDocuments(data.createOrUpdateSupportDocument)
				await uploadStatusDialogStore.setIsSuccessShowUploadStatusDialogStore(true)
				await uploadStatusDialogStore.setShowUploadStatusDialogStore(true)
			} else {
				// HACK: To make form refresh
				this.supportDocuments = supportDocuments
			}
		} catch (error) {
			await uploadStatusDialogStore.setIsSuccessShowUploadStatusDialogStore(false)
			await uploadStatusDialogStore.setShowUploadStatusDialogStore(true)
		}
	}

	@action
	handleSubmitSkillChecklistData = async (data) => {
		this.supportDocuments = data.supportDocuments
		this.workerSpecialties = data.workerSpecialties

		await this.handleSubmitSpecialties(data)
		await this.handleSubmitDocuments(data)

		notifyStore.success('$MESSAGES.SUCCESSFUL')
	}

	@action
	handleSubmitSpecialties = async (formData, specialtyId) => {
		const { workerSpecialties } = formData
		let workerSpecialtiesAttributes = workerSpecialties
			.filter((item) => {
				return (!specialtyId && item.status !== 'expired') || item.id === specialtyId
			})
			.map((workerSpecialty) => ({
				id: workerSpecialty.id,
				category: workerSpecialty.category,
				isPrimary: workerSpecialty.isPrimary,
				specialty: workerSpecialty.specialty,
				workerSkillChecklistSectionsAttributes: getWorkerSkillChecklistSections(workerSpecialty),
				specialtyType: !isNil(formData?.specialtyType) ? formData?.specialtyType : formData.checklistFileSignedBlobId ? 'file_upload' : 'checklist',
				checklistFileSignedBlobId: formData?.checklistFileSignedBlobId,
			}))
		// this.specialtiesDocumentData?.workerSpecialties
		workerSpecialtiesAttributes = await removeTempIds(workerSpecialtiesAttributes)

		const variables = {
			workerSpecialtiesAttributes: workerSpecialtiesAttributes,
		}

		if (careActivationStore.isDirtySkillCheckList) {
			const { data } = await apolloClient.mutate({
				mutation: CREATE_OR_UPDATE_WORKER_SPECIALTY,
				variables,
			})
			const dataMapping = workerSpecialtiesMapping(data?.createOrUpdateWorkerSpecialty)
			this.workerSpecialties = dataMapping
			// this.workerSpecialties = this.specialtiesDocumentData?.workerSpecialties
			return dataMapping
		}
	}

	@action
	refreshSpecialtyDocumentData = async (data) => {
		// this.workerSpecialties =  this.specialtiesDocumentData?.workerSpecialties
	}

	@action
	onSubmitDeleteDocument = async (item, docIndex) => {
		if (item?.id && !isTempId(item.id)) {
			const { data } = await apolloClient.mutate({
				mutation: DELETE_SUPPORT_DOCUMENTS_MUTATION,
				variables: {
					ids: [item?.supportDocumentId],
				},
			})

			if (data?.destroySupportDocument?.success) {
				notifyStore.success('$MESSAGES.SUCCESSFUL')
			}
		}

		let supportDocuments = this.specialtiesDocumentData?.supportDocuments
		let supportDocument = supportDocuments[docIndex]
		let documents = []
		if (!isTempId(item.id)) {
			documents = supportDocument?.documents?.map((image) => {
				if (image.id === item.id) return { ...image, _destroy: true }
				return image
			})
		} else {
			documents = supportDocument?.documents?.filter((image) => image.id !== item.id)
		}

		supportDocument.documents = documents
		supportDocuments[docIndex] = supportDocument
		this.supportDocuments = supportDocuments
	}

	@action
	getAllQuestions = (specialty) => {
		let allQuestions = []
		allQuestions = specialty?.workerSkillChecklistSections?.map((section) => {
			return [...allQuestions, ...section?.workerSkillChecklists]
		})
		allQuestions = flatten(allQuestions)
		return allQuestions
	}

	@action
	isSpecialtyCompleted = (specialty) => {
		const allQuestions = this.getAllQuestions(specialty)
		if (allQuestions.length === 0) {
			return true
		}
		const incompleted = find(allQuestions, (item) => item.proficiency === 0 || item.frequency === 0)

		if (incompleted !== undefined) {
			return false
		}
		return true
	}

	@action
	handleDeleteSkillChecklist = async (specialtyId) => {
		await apolloClient.mutate({
			mutation: DELETE_WORKER_SPECIALTY,
			variables: {
				id: specialtyId,
			},
		})

		this.fetchSpecialtyOptions(this.specialtyKeyword, this.specialtyTag)
	}

	@action
	updateSpecialtyValue = async (values) => {
		let workerSpecialties = this.specialtiesDocumentData?.workerSpecialties
		var index = findIndex(workerSpecialties, { specialty: values.specialty })
		values = { ...values, completedChecklist: this.isSpecialtyCompleted(values) }
		workerSpecialties.splice(index, 1, values)
		this.workerSpecialties = workerSpecialties
		// await careActivationStore.setShowSuccessUpdateRedoSkillChecklist(true)
	}

	@action
	setSpecialtyAsPrimary = async (item) => {
		let workerSpecialties = this.specialtiesDocumentData?.workerSpecialties
		workerSpecialties = workerSpecialties.map((it) => ({ ...it, isPrimary: false }))

		item.isPrimary = true
		var index = findIndex(workerSpecialties, { specialty: item.specialty })
		workerSpecialties.splice(index, 1, item)
		this.workerSpecialties = workerSpecialties
	}

	@action
	selectWorkerSpecialties = async (item) => {
		let workerSpecialties = this.specialtiesDocumentData?.workerSpecialties || []
		const object = find(workerSpecialties, { specialty: item.skill_name })
		if (object !== undefined) {
			return
		}
		const workerSkillChecklistSections = this.generateWorkerSkillChecklistSections(item)
		const workerSpecialty = {
			id: generateTempId(),
			category: item.category,
			isPrimary: false,
			completedChecklist: item.has_checklist ? false : true,
			specialty: item.skill_name,
			specialtyLongName: item.long_name,
			status: item.status,
			workerSkillChecklistSections: workerSkillChecklistSections,
			checklistFile: item.checklistFile,
			specialtyType: item.specialtyType,
		}
		await this.handleSubmitSkillChecklist(workerSpecialty, true)
		// workerSpecialties.push(workerSpecialty)
		// this.workerSpecialties = workerSpecialties
		// this.specialtiesDocumentData = { ...this.specialtiesDocumentData, workerSpecialties }
	}

	@action
	generateWorkerSkillChecklistSections = (item) => {
		if (!item?.has_checklist) {
			return []
		}
		let sections = []
		sections = item?.checklist?.map((value) => {
			return {
				id: generateTempId(),
				name: value.section,
				workerId: authStore.id,
				workerSkillChecklists: this.geneateWorkerSkillChecklists(value?.questions),
			}
		})
		return sections
	}

	@action
	geneateWorkerSkillChecklists = (questions) => {
		let newQuestions = []
		newQuestions = questions?.map((item) => {
			return { workerId: authStore.id, question: item, proficiency: 0, frequency: 0, id: generateTempId() }
		})
		return newQuestions
	}

	@action
	deleteSpecialtyFromFirebase = async (item) => {
		let workerSpecialties = this.specialtiesDocumentData?.workerSpecialties
		remove(workerSpecialties, { specialty: item.specialty })
		this.workerSpecialties = workerSpecialties
		this.fetchSpecialtyOptions(this.specialtyKeyword, this.specialtyTag)
	}

	@action
	fetchSpecialtyOptions = async (keyword = '', tag = '') => {
		const originalSpecialties = masterStore.originalSpecialties
		let specialties = []
		specialties = masterStore.specialties
		let specialtiesByTags = specialties
		if (tag !== '') {
			specialtiesByTags = originalSpecialties[tag]
			specialties = Object.keys(specialtiesByTags).map((key) => ({ ...specialtiesByTags[key], category: tag }))
		}

		const workerSpecialties = this.specialtiesDocumentData?.workerSpecialties
		if (workerSpecialties.length > 0) {
			specialties = specialties.filter((item) => filter(workerSpecialties, (ws) => item.skill_name === ws.specialty).length === 0)
		}

		if (keyword === '') {
			this.specialtyList = specialties
			return
		}

		let options = filter(specialties, (item) => {
			return item.long_name?.toLowerCase().indexOf(keyword?.toLowerCase()) > -1
		})

		this.specialtyList = options || []
	}

	@action
	handleErrorSupportDocument = async (docImage, docIndex, setFieldValue) => {
		try {
			const response = await apolloClient.query({
				query: GET_SIGNED_URLS,
				variables: {
					blobIds: [docImage?.blobId],
				},
			})
			const id = response?.data?.signedUrls?.[0]?.id
			const fileUrl = response?.data?.signedUrls?.[0]?.fileUrl
			let supportDocuments = this.specialtiesDocumentData?.supportDocuments

			if (id === undefined) {
				let documents = supportDocuments[docIndex]?.documents
				remove(documents, (item) => item?.blobId === docImage?.blobId && isTempId(item.id))
				supportDocuments[docIndex].documents = documents
				if (docImage?.blobId !== undefined) {
					console.log(`Document is expired, but not fetch new form api blodId: ${docImage?.blobId}`)
					// notifyStore.error('Document is expired and has been removed')
				}
			} else {
				supportDocuments[docIndex].documents = supportDocuments[docIndex]?.documents
					?.filter((item) => !item?.hasOwnProperty('_destroy'))
					.map((item) => (item?.blobId === docImage?.blobId ? { ...item, fileUrl: fileUrl } : item))
			}

			setFieldValue && setFieldValue('supportDocuments', supportDocuments)

			this.supportDocuments = supportDocuments
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	handleErrorResumeDisplaying = async (resume, setFieldValue) => {
		try {
			const response = await apolloClient.query({
				query: GET_SIGNED_URLS,
				variables: {
					blobIds: [resume?.blobId],
				},
			})
			const id = response?.data?.signedUrls?.[0]?.id
			const fileUrl = response?.data?.signedUrls?.[0]?.fileUrl
			let resumes = this.resumeInitialValues?.resumes

			if (id === undefined) {
				if (resume?.blobId !== undefined) {
					console.log(`Resume is expired, but not fetch new form api: blodId: ${resume?.blobId}`)
					// notifyStore.error('Resume is expired')
				}
			} else {
				resumes = this.resumeInitialValues?.resumes.map((item) => (item?.blobId === resume?.blobId ? { ...item, fileUrl: fileUrl } : item))
				this.worker.resumes = resumes
				setFieldValue && setFieldValue('resumes', resumes)
			}
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	handleDeleteResume = async (resumeId, setFieldValue) => {
		if (!isTempId(resumeId)) {
			const { data } = await apolloClient.mutate({
				mutation: DELETE_RESUME_MUTATION,
				variables: {
					ids: [resumeId],
				},
			})

			if (data?.destroyWorkerResumes?.success) {
				notifyStore.success('$MESSAGES.SUCCESSFUL')
			}

			this.worker.resumes = this.worker?.resumes?.map((image) => (image.id === resumeId ? { ...image, _destroy: true } : image))
			setFieldValue && setFieldValue('resumes', this.worker.resumes)
		} else {
			this.worker.resumes = this.worker.resumes?.filter((image) => image.id !== resumeId)
		}
	}

	@action
	isCompleteAllSpecialties = (specialties) => {
		if (specialties.length === 0) {
			return false
		}

		for (var i = 0; i < specialties.length; i++) {
			if (!specialties[i].completedChecklist && !this.isSpecialtyCompleted(specialties[i])) {
				return false
			}
		}
		return true
	}

	@action
	hasUploadSupportDocument = (supportDocuments) => {
		for (var i = 0; i < supportDocuments.length; i++) {
			if (supportDocuments[i]?.documents?.filter((doc) => !doc._destroy).length > 0) {
				return true
			}
		}
		return false
	}
	@action
	setSpecialtyKeyword = async (keyword) => {
		this.specialtyKeyword = keyword
	}

	@action
	setSpecialtyTag = async (tag) => {
		this.specialtyTag = tag
	}

	@action
	handleSubmitSkillChecklist = async (values, empty = false, specialtyId) => {
		try {
			const workerSpecialtiesAttributes = convertSkillChecklistToSaveData(values, empty)
			const { data } = await apolloClient.mutate({
				mutation: CREATE_OR_UPDATE_WORKER_SPECIALTY,
				variables: {
					workerSpecialtiesAttributes: workerSpecialtiesAttributes,
				},
			})
			this.workerSpecialties = workerSpecialtiesMapping(data.createOrUpdateWorkerSpecialty)
			if (careActivationStore.isDirtySkillCheckList) {
				if (data.createOrUpdateWorkerSpecialty?.find((item) => item.id === specialtyId).status === 'completed') {
					careActivationStore.setIsSuccessCompleteSkillCheckListDialog(true)
					careActivationStore.setShowSuccessUpdateRedoSkillChecklist(true)
				} else {
					careActivationStore.setIsSuccessCompleteSkillCheckListDialog(false)
					careActivationStore.setShowSuccessUpdateRedoSkillChecklist(true)
				}
			}
		} catch (error) {
			await notifyStore.error(error?.message)
		}
	}

	@action
	convertCitiesToSaveData = (locationCities) => {
		let cities = []
		if (!isEmpty(locationCities)) {
			cities = Object.values(locationCities).map((item) => {
				let existItem = find(
					this.preferredCities,
					(preLocation) => preLocation.preferredWorkingState === item.stateCode && preLocation.preferredWorkingCity === item.name
				)
				return isEmpty(existItem)
					? { preferredWorkingCity: item?.name, preferredWorkingState: item?.stateCode }
					: { preferredWorkingCity: item?.name, preferredWorkingState: item?.stateCode, id: existItem?.id }
			})
		}

		const deleteItems = filter(this.preferredCities, (preCity) =>
			isEmpty(
				find(
					cities,
					(newCity) => newCity.preferredWorkingCity === preCity.preferredWorkingCity && newCity.preferredWorkingState === preCity.preferredWorkingState
				)
			)
		).map((item) => ({ ...item, _destroy: true }))
		if (deleteItems?.length > 0) {
			cities = [...cities, ...deleteItems]
		}

		return cities
	}

	@action
	convertStatesToSaveData = (statesData) => {
		let states = []
		if (!isEmpty(statesData)) {
			states = statesData?.map((state) => {
				let existItem = find(this.preferredStates, (preLocation) => preLocation.preferredWorkingState === state)
				return isEmpty(existItem) ? { preferredWorkingState: state } : { id: existItem?.id, preferredWorkingState: state }
			})
		}
		const deleteItems = differenceBy(this.preferredStates, states, 'preferredWorkingState').map((item) => ({ ...item, _destroy: true }))
		if (deleteItems?.length > 0) {
			states = [...states, ...deleteItems]
		}

		return states
	}

	@action
	handleSubmitPreferedLocation = async (formData) => {
		try {
			const states = this.convertStatesToSaveData(formData?.states)
			const cities = this.convertCitiesToSaveData(formData?.locationCities)
			const workingPreferredLocationsAttributes = [...cities, ...states]

			await apolloClient.mutate({
				mutation: UPDATE_WORKER_PREFERRED_LOCATION_MUTATION,
				variables: { workingPreferredLocationsAttributes },
			})

			await this.fetchWorkerDetail()
			this.setPreferredLocationUpdating(false)
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	setPreferredLocationUpdating = async (value) => {
		this.preferredLocationUpdating = value
	}
	@action
	setWorker = async (value) => {
		this.worker = value
	}
}

export const careProfileStore = new CareProfileStore()

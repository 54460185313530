import { FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import { useField } from 'formik'
import React, { useCallback } from 'react'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { Checkbox } from '../../checkbox'
import { logClickEvent } from '~/common/tracking/event-client.tracking'

export const CheckField = ({ name, validate, label, mode, ...props }) => {
	const { t } = useTranslation()
	const eventId = props?.eventId
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })

	const error = useFieldError(meta)

	const handleChange = useCallback(
		(event, checked) => {
			event.target.value = checked

			if (mode === FIELD_MODE.edit) {
				field.onChange(event)
			}

			logClickEvent(eventId, {
				value: event.target.value,
			})
		},
		[mode, field, eventId]
	)

	return (
		<FormControl error={!!error}>
			<FormControlLabel control={<Checkbox {...field} checked={field.value || false} onChange={handleChange} name={name} />} label={t(label)} />
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	)
}

CheckField.defaultProps = {
	mode: FIELD_MODE.edit,
}

import { Box, CircularProgress, Collapse, FormHelperText, IconButton, SvgIcon } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import React, { useCallback, useMemo, useState } from 'react'
import { EVENTS, FIELD_MODE } from '~/common/constants'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { Button } from '~/components/button'
import { Typography } from '~/components/typography'
import {
	inputStyle,
	uploadButtonStyle,
	imageBoxStyle,
	itemBoxStyle,
	wrapperStyle,
	hintStyle,
	filenameStyle,
	uploadInputStyled,
	reviewUploadStyled,
	labelStyle,
} from './file-upload-field.style'
import { captureException, removeFile as defaultRemoveFile, uploadFile as defaultUploadFile } from '~/common/helpers'
import { filter, startsWith } from 'lodash'
import { AddImageSvg, DocSvg, PdfSvg, RemoveSvg } from '~/components/icons'
import { useDebounce } from 'react-use'
import { eventBus } from 'mobx-event-bus2'
import UploadListImage from '~/components/fields/file-upload-field/upload-list-image'

import { ImagePreviewCarousel } from '~/components/image-preview-carousel'
import { DeleteItemDialog } from '../../delete-item-dialog/delete-dialog'
import { DocxViewer } from '~/components/doc-viewer/doc-viewer.component'

const ICONS = {
	'application/doc': DocSvg,
	'application/msword': DocSvg,
	'application/ms-doc': DocSvg,
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DocSvg,
	'application/pdf': PdfSvg,
	'application/xml': DocSvg,
}

const IMAGE_TYPE = 'image/'

export const FileUploadField = ({
	name,
	label,
	validate,
	mode,
	multiple,
	camera,
	uploadFile,
	removeFile,
	accept,
	image,
	hint,
	hideDelete = false,
	handleDeleteDocument,
	handleErrorImage,
	hasRemove = false,
}) => {
	const { t } = useTranslation()
	const { setFieldValue } = useFormikContext()
	const [showPreview, setShowPreview] = useState()
	const [processingItems, setProcesingItems] = useState([])
	const [showDelete, setShowDelete] = useState(false)
	const [deleteItem, setDeleteItem] = useState(null)
	const [deleteItemIndex, setDeleteItemIndex] = useState(null)
	const [showDocPreview, setShowDocPreview] = useState(false)
	const [docUrl, setDocUrl] = useState(null)
	const [contentType, setContentType] = useState(null)

	const validateFunc = useCallback(
		(value) => {
			if (processingItems?.length > 0) {
				return 'UPLOADING'
			}

			if (typeof validate === 'function') {
				return validate(value)
			}
		},
		[validate, processingItems]
	)

	const [field, meta] = useField({ name, validate: validateFunc })

	const id = useMemo(() => `${name}_file_upload`, [name])

	const error = useFieldError(meta)
	const [activeImage, setActiveImage] = useState({})
	useDebounce(
		async () => {
			if (processingItems.length === 0) {
				return
			}

			const [file, ...rest] = processingItems

			try {
				const res = await uploadFile(file)
				const { signedBlobId, preSignedUrl, blobId } = res

				const values = [...(field.value || []), { filename: file.name, contentType: file.type, signedBlobId, fileUrl: preSignedUrl, blobId: blobId }]
				field.onChange({ target: { name, value: values } })
			} catch (error) {
				captureException('File upload failed.', error)
				eventBus.post(EVENTS.notifyStore.fireError, { message: `File upload failed. "${file.name}"` })
			} finally {
				setProcesingItems(rest)
			}
		},
		300,
		[processingItems]
	)

	const handleChange = useCallback(async (event) => {
		const files = Array.from(event?.target?.files)
		event.target.value = ''

		const invalidFileSize = files?.some((file) => file.size >= 10 * 1000 * 1000)
		const invalidFileType = files?.some((file) => !(Object.keys(ICONS).includes(file?.type) || startsWith(file.type, IMAGE_TYPE)))

		if (invalidFileSize) {
			return eventBus.post(EVENTS.notifyStore.fireError, { message: '$ERRORS.MAXIMUM_FILE_SIZE' })
		}

		if (invalidFileType) {
			return eventBus.post(EVENTS.notifyStore.fireError, { message: '$ERRORS.UNSUPPORT_CONTENT_TYPE' })
		}

		setProcesingItems(files)
	}, [])

	const handleDelete = useCallback(async () => {
		const item = deleteItem
		const index = deleteItemIndex
		await handleDeleteDocument(item)
		const file = field?.value?.[index]
		setFieldValue(`${name}.${index}._destroy`, true)
		field.onChange({ target: { name, value: field?.value?.filter((v) => file.id !== v.id) } })
		setDeleteItem(null)
		setDeleteItemIndex(null)
		setShowDelete(false)
	}, [setFieldValue, deleteItem, deleteItemIndex, name, field, handleDeleteDocument])

	const handleCancelDelete = () => {
		setDeleteItem(null)
		setDeleteItemIndex(null)
		setShowDelete(false)
	}

	const visible = useMemo(() => field?.value?.filter((item) => !item._destroy)?.length > 0 || processingItems.length > 0, [field.value, processingItems])

	const disabled = useMemo(() => processingItems?.length > 0, [processingItems])

	const actualViewValue = filter(field?.value, (v) => !v._destroy)

	const hideUpload = useMemo(() => !multiple && (processingItems?.length > 0 || actualViewValue.length > 0), [processingItems, multiple, actualViewValue])

	const handlePreviewImage = (item) => {
		setActiveImage({
			url: item?.imageUrls?.s_500x500 || item?.src,
			title: item.filename,
		})
		setShowPreview(true)
	}

	const handleOnClosePreview = async () => {
		setShowDocPreview(false)
		setDocUrl(null)
		setContentType(null)
	}

	const handlePreviewPdf = async (item) => {
		setDocUrl(item?.fileUrl)
		setContentType(item.contentType)
		setShowDocPreview(true)
	}

	const handleSelectImgList = (item) => {
		setActiveImage({
			url: item?.src,
			title: item?.alt,
		})
	}
	const listImageSlider = field.value
		?.filter((item) => !item.hasOwnProperty('_destroy'))
		.map((item) => {
			return {
				alt: item?.filename,
				src: item?.imageUrls?.s_500x500 || item?.src || item?.fileUrl,
				id: item?.id,
				contentType: item?.contentType,
			}
		})
	return (
		<>
			{label && <Typography css={labelStyle}>{t(label)}</Typography>}
			{hint && <Typography css={hintStyle}>{t(hint)}</Typography>}
			<Box mb={3} style={{ display: 'flex' }}>
				{mode === FIELD_MODE.edit && !hideUpload && (
					<Box css={uploadInputStyled} mt={1} position="relative" height={80}>
						<input
							disabled={disabled}
							accept={accept}
							max={3}
							multiple={multiple}
							camera={camera}
							id={id}
							type="file"
							css={inputStyle}
							onChange={handleChange}
						/>

						<label htmlFor={id} css={wrapperStyle}>
							<Button border={2} variant="outlined" color="primary" component="span" css={uploadButtonStyle}>
								<Box className="content"></Box>
							</Button>

							<Box pl={2} pr={2} css={imageBoxStyle}>
								<div className="container icon-add-file">
									<SvgIcon component={AddImageSvg} />
								</div>
							</Box>
						</label>
					</Box>
				)}
				<Box css={reviewUploadStyled}>
					{(!actualViewValue || actualViewValue.length === 0) && label && mode === FIELD_MODE.view && (
						<Box mt={1} mb={1}>
							{t('$PLACEHOLDERS.NONE')}
						</Box>
					)}
					<Collapse className="preview-image" in={visible}>
						<div style={{ display: 'flex' }}>
							{field.value?.map(
								(item, index) =>
									!item?._destroy &&
									(item?.contentType === 'application/pdf' ||
									item?.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
									item?.contentType === 'application/msword' ? (
										<Box css={itemBoxStyle} key={index} onClick={() => handlePreviewPdf(item)}>
											<Box className="preview-icon">
												<SvgIcon component={ICONS[item?.contentType]} viewBox="0 0 24 30" style={{ fill: 'none' }} />
											</Box>
											<Typography css={filenameStyle}>{item.filename}</Typography>
											{!hasRemove && (
												<IconButton
													className="removeIcon"
													size="small"
													onClick={(e) => {
														setShowDelete(true)
														setDeleteItem(item)
														setDeleteItemIndex(index)
														e.stopPropagation()
													}}
												>
													<RemoveSvg />
												</IconButton>
											)}
										</Box>
									) : (
										<UploadListImage
											key={index}
											onClick={() => handlePreviewImage(item)}
											item={item}
											mode={mode}
											className="image"
											onClick1={(e) => {
												setShowDelete(true)
												setDeleteItem(item)
												setDeleteItemIndex(index)
												e.stopPropagation()
											}}
											hideDelete={!hasRemove}
										/>
									))
							)}

							{processingItems?.map((item, index) => (
								<div key={index} className="preview-image-icon">
									<Box css={itemBoxStyle} key={`${item.name}_${index}`}>
										<Box className="preview-icon">
											<CircularProgress size={18} />
										</Box>
										<Typography css={filenameStyle} className="file-name">
											{item.name}
										</Typography>
									</Box>
								</div>
							))}
							<ImagePreviewCarousel
								className="image-preview-carousel"
								handlePreviewPdf={handlePreviewPdf}
								handleSelectImgList={handleSelectImgList}
								listImageSlider={listImageSlider}
								open={showPreview}
								activeImage={activeImage}
								onClose={() => setShowPreview(false)}
							/>
							{docUrl && <DocxViewer showDialog={showDocPreview} setShowDialog={handleOnClosePreview} docUrl={docUrl} fileType={contentType} />}
						</div>
					</Collapse>
				</Box>
			</Box>

			{error && (
				<FormHelperText error={true} style={{ position: 'relative', top: 0 }}>
					{error}
				</FormHelperText>
			)}

			<DeleteItemDialog
				showDeleteConfirmation={showDelete}
				setDeleteConfirmation={setShowDelete}
				handleClickSubmit={handleDelete}
				handleClickCancel={handleCancelDelete}
			/>
		</>
	)
}

FileUploadField.defaultProps = {
	mode: FIELD_MODE.edit,
	camera: true,
	removeFile: defaultRemoveFile,
	uploadFile: defaultUploadFile,
	accept: '.png,.jpg,.jpeg,.pdf,.doc,.docx',
	multiple: true,
}

const CONVERSION_ID= process.env.REACT_APP_CONVERSION_ID
const EXCLUSION_ID= process.env.REACT_APP_EXCLUSION_ID

export const CONVERSION_SCRIPT = `!function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];z.parentNode.insertBefore(t,z)}(window,document,'script','https://tags.srv.stackadapt.com/events.js');saq('conv','${CONVERSION_ID}');`
export const EXCLUSION_SCRIPT = `!function(s,a,e,v,n,t,z){if(s.saq)return;n=s.saq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';n.queue=[];t=a.createElement(e);t.async=!0;t.src=v;z=a.getElementsByTagName(e)[0];z.parentNode.insertBefore(t,z)}(window,document,'script','https://tags.srv.stackadapt.com/events.js');saq('rt','${EXCLUSION_ID}');`

export const handleLoadTrackingPixel = () => {
	const loadScriptByURL = (id, innerScript, callback) => {
		const isScriptExist = document.getElementById(id)

		if (!isScriptExist) {
			var script = document.createElement('script')
			script.type = 'text/javascript'
			script.id = id
      script.innerHTML = innerScript
			script.onload = function () {
				if (callback) callback()
			}
			document.body.appendChild(script)
		}

		if (isScriptExist && callback) callback()
	}

	loadScriptByURL('conversion-pixel', CONVERSION_SCRIPT, () => {
		console.log('Conversion Pixel Script loaded!')
	})

  loadScriptByURL('exclusion-pixel', EXCLUSION_SCRIPT, () => {
		console.log('Exclusion Pixel Script loaded!')
	})
}

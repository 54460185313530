import gql from 'graphql-tag'

export const GET_COMPANY_INFO_QUERY = gql`
	query getCompanyInfo {
		appInit {
			company {
				mainContactEmail
				phone
				id
				name
			}
			worker {
				hasActiveAssignment
				referralCode
			}
			companyConfig {
				facebookUrl
				twitterUrl
				instagramUrl
				linkedInUrl
				defaultLocationImageWebUrl
				defaultLocationImageThumbnailUrl
				privacyPolicyContent
				referalRewardContent
				messagingServiceSender
			}
		}
	}
`

export const GET_COMPANY_JNP_INFO_QUERY = gql`
	query getCompanyInfo {
		appInit {
			company {
				mainContactEmail
				phone
				id
				name
			}
			worker {
				hasActiveAssignment
				referralCode
			}
			companyConfig {
				facebookUrl
				twitterUrl
				instagramUrl
				linkedInUrl
				defaultLocationImageWebUrl
				defaultLocationImageThumbnailUrl
				privacyPolicyContent
				referalRewardContent
				messagingServiceSender
				externalResourceUrl
				externalImmediatePayUrl
			}
		}
	}
`
export const GET_SPECIALTY_MASTER_QUERY = gql`
	query SpecialtiesMaster {
		specialtiesMaster {
			id
			slug
		}
	}
`

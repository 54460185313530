import { isEmpty } from 'lodash'
import { action, observable, store, computed } from '~/common/mobx.decorator'

const JOB_TYPES = ['travel', 'perdiem', 'permanent', 'localcontract']

// Helper function to parse job types from the link
const parseJobTypeToArray = (link) => {
	return link
		.replace(/\|/g, ' ') // Replace `|` with spaces
		.replace(/\+/g, '') // Remove the `+` symbols
		.toLowerCase() // Make it lowercase
		.split(' ') // Split into an array
		.map((item) => item.trim()) // Trim whitespace from each element
}

@store()
class CareSearchRedirectStore {
	@observable redirectLink = null
	@observable jobTypes = null
	@observable startDate = null
	@observable minWage = null
	@observable maxWage = null
	@observable shifts = null
	@observable frequency = null
	@observable nearBy = null
	@observable locationCity = null
	@observable locationState = null
	@observable specialty = null
	@observable minMatchingPercentage = null
	@observable profession = null

	@computed
	get getActualLink() {
		const queryParams = new URLSearchParams()

		if (this.profession) queryParams.append('profession', this.profession?.replace(/;/g, '/')) // Replace `;` with `/`
		if (this.minMatchingPercentage) queryParams.append('minMatchingPercentage', this.minMatchingPercentage)
		if (this.minWage) queryParams.append('minWage', this.minWage)
		if (this.maxWage) queryParams.append('maxWage', this.maxWage)
		if (this.startDate) queryParams.append('startDate', this.startDate?.replace(/\|/g, ',')) // Replace `|` with `,`

		if (this.specialty) {
			const specialtyFormatted = this.specialty
				.replace(/;/g, '/') // Replace `;` with `/`
				.replace(/\|/g, ',') // Replace `|` with ','
				.replace(/\+/g, ' ') // Replace existing `+` with space for uniformity

			// Append the formatted specialty directly to the queryParams
			queryParams.append('specialty', specialtyFormatted)
		}

		if (this.jobTypes) queryParams.append('jobTypes', this.jobTypes?.replace(/\|/g, ',').replace(/\+/g, ' ')) // Replace `|` with `,`
		if (this.shifts) queryParams.append('shifts', this.shifts?.replace(/\|/g, ',').replace(/\+/g, ' ')) // Replace `|` with `,`
		if (this.frequency) queryParams.append('frequency', this.frequency?.replace(/\+/g, ' '))
		if (this.locationCity) queryParams.append('locationCity', this.locationCity?.replace(/\|/g, ',').replace(/\+/g, ' ')) // Replace `|` with `,`
		if (this.locationState) queryParams.append('locationState', this.locationState?.replace(/\|/g, ',').replace(/\+/g, ' ')) // Replace `|` with `,`
		if (this.nearBy) queryParams.append('nearBy', this.nearBy)

		const path = queryParams.toString()

		if (isEmpty(path)) {
			return `${process.env.REACT_APP_URL_DOMAIN}/search`
		}
		return `${process.env.REACT_APP_URL_DOMAIN}/search?${path}`
	}

	@computed
	get getMarketLink() {
		let link = ''
		if (this.jobTypes) {
			link = `${this.jobTypes}`
		}
		if (this.locationCity) {
			link = `${link}&locationCity=${this.locationCity}`
		}
		if (this.locationState) {
			link = `${link}&locationState=${this.locationState}`
		}
		if (this.profession) {
			link = `${link}&profession=${this.profession}`
		}
		if (this.minMatchingPercentage) {
			link = `${link}&minMatchingPercentage=${this.minMatchingPercentage}`
		}
		if (this.minWage) {
			link = `${link}&minWage=${this.minWage}`
		}
		if (this.maxWage) {
			link = `${link}&maxWage=${this.maxWage}`
		}
		if (this.specialty) {
			link = `${link}&specialty=${this.specialty}`
		}
		if (this.startDate) {
			link = `${link}&startDate=${this.startDate}`
		}
		if (this.frequency) {
			link = `${link}&frequency=${this.frequency}`
		}
		if (this.shifts) {
			link = `${link}&shifts=${this.shifts}`
		}
		if (this.nearBy) {
			link = `${link}&nearBy=${this.nearBy}`
		}

		if (isEmpty(link)) {
			return `${process.env.REACT_APP_URL_DOMAIN}/search`
		}

		if (isEmpty(this.jobTypes)) {
			link = link.slice(1)
		}

		return `${process.env.REACT_APP_URL_DOMAIN}/search/${link}`
	}

	@action
	setJobTypes = (jobTypes) => {
		this.jobTypes = jobTypes
	}
	@action
	setRedirectLink = (link) => {
		this.redirectLink = link
	}

	@action
	setStartDate = (date) => {
		this.startDate = date
	}

	@action
	setMinWage = (wage) => {
		this.minWage = wage
	}

	@action
	setMaxWage = (wage) => {
		this.maxWage = wage
	}

	@action
	setShifts = (shifts) => {
		this.shifts = shifts
	}

	@action
	setFrequency = (frequency) => {
		this.frequency = frequency
	}

	@action
	setNearBy = (nearBy) => {
		this.nearBy = nearBy
	}

	@action
	setLocationCity = (city) => {
		this.locationCity = city
	}

	@action
	setLocationState = (state) => {
		this.locationState = state
	}

	@action
	setMinMatchingPercentage = (percentage) => {
		this.minMatchingPercentage = percentage
	}

	@action
	setProfession = (profession) => {
		this.profession = profession
	}

	@action
	setSpecialty = (specialty) => {
		this.specialty = specialty
	}

	@action
	parseLink = (link) => {
		// Check if the first part of the link is a valid job type
		const paramsArray = link?.split('&')
		const params = {}

		const jobTypeString = paramsArray?.[0]
		const jobTypesArray = parseJobTypeToArray(jobTypeString)

		const hasJobTypes = jobTypesArray?.length > 0 && JOB_TYPES.some((jobType) => jobTypesArray.includes(jobType))
		if (hasJobTypes) {
			this.setJobTypes(jobTypeString)
			// Skip the first item (jobTypeString) when parsing other parameters
			paramsArray.slice(1).forEach((param) => {
				const [key, value] = param.split('=')
				if (key && value) {
					params[key] = decodeURIComponent(value)
				}
			})
		} else {
			this.setJobTypes(null)
			paramsArray.forEach((param) => {
				const [key, value] = param.split('=')
				params[key] = value || ''
			})
		}

		if (isEmpty(params)) {
			return
		}

		console.log(params)

		// Handle each parameter
		this.setMinWage(params.minWage)
		this.setMaxWage(params.maxWage)
		this.setLocationCity(params.locationCity)
		this.setLocationState(params.locationState)
		this.setMinMatchingPercentage(params.minMatchingPercentage)
		this.setProfession(params.profession)
		this.setSpecialty(params.specialty)
		this.setProfession(params.profession)
		this.setFrequency(params.frequency)
		this.setShifts(params.shifts)
		this.setNearBy(params.nearBy)
		this.setStartDate(params.startDate)
	}

	@action
	setFilterValues = (filterValues) => {
		const jobTypes = filterValues?.jobTypes?.map((item) => item.replace(/ /g, '+')).join('|') // Replace spaces with '+' and join with '|'
		this.setJobTypes(jobTypes)
		this.setMinWage(filterValues?.minWage)
		this.setMaxWage(filterValues?.maxWage)
		const locationCitiesString = filterValues?.locationCities?.map((item) => item.replace(/, /g, '|')?.replace(/ /g, '+'))?.join('|')
		const locationStatesString = filterValues?.locationStates?.join('|')
		this.setLocationCity(locationCitiesString)
		this.setLocationState(locationStatesString)
		this.setMinMatchingPercentage(filterValues?.minMatchingPercentage)
		const skillNameString = filterValues?.skillName?.map((item) => item.replace(/\//g, ';')?.replace(/ /g, '+'))?.join('|')
		this.setSpecialty(skillNameString)
		this.setProfession(filterValues?.profession?.replace(/\//g, ';'))
		this.setFrequency(filterValues.frequency)
		this.setShifts(filterValues?.shifts?.join('|'))
		this.setNearBy(filterValues?.nearBy?.radius)
		const startDateString = filterValues?.startDate?.from ? `${filterValues?.startDate?.from}|${filterValues?.startDate?.to}` : null
		this.setStartDate(startDateString)
	}
}

export const careSearchRedirectStore = new CareSearchRedirectStore()

import React from 'react'
import { careStore } from '~/companies/care/care.store'
import { Helmet } from 'react-helmet'
import { appStore } from '~/stores'
import './richWebChat.css' // Import the internal CSS file
//how to handle richWebChat.css file is effect inside the chat-engine.component.js file in react js

export const ChatEngine = ({ ...props }) => {
	const { isJTP } = careStore
	const { chatIntegrationSetting } = appStore

	if (isJTP || !chatIntegrationSetting?.active) return <></>

	return (
		<div {...props}>
			<div id="rwc"></div>
			<Helmet>
				<script src="https://chat.staffingengine.onereach.ai/lib/richWebChat.umd.min.js"></script>
				<script type="text/javascript">
					{`
            setTimeout(() => {
              var RWC = richWebChat.default;
              var app = new RWC(${chatIntegrationSetting?.configuration?.web});
            }, 1000);
          `}
				</script>
			</Helmet>
		</div>
	)
}

import { css } from 'styled-components'

export const SIDEBAR_WIDTH = '280px'

export const sidebarStyle = css`
	width: ${SIDEBAR_WIDTH};

	.MuiDrawer-paper {
		z-index: 1;
		padding-top: 15px;
		width: ${SIDEBAR_WIDTH};
		background-color: ${({ theme }) => theme.palette.background.default};

		.MuiList-root {
			.MuiListItem-root {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				border-radius: 8px;
				letter-spacing: 0.2px;
				color: ${({ theme }) => theme.palette.content.dark};
				opacity: 1;

				.MuiListItemIcon-root {
					color: ${({ theme }) => theme.palette.content.dark};

					svg {
						fill: ${({ theme }) => theme.palette.text.disabled};
					}
				}

				&.Mui-selected {
					color: ${({ theme }) => theme.palette.content.veryLight};
					background: ${({ theme }) => theme.palette.secondary.sub};
					border-radius: 8px;

					svg {
						fill: ${({ theme }) => theme.palette.content.veryLight};
					}

					.sidebar-resources {
						svg {
							path {
								stroke: white;
							}
						}
					}
				}
			}
		}
	}
`
export const mainContentStyle = css`
	width: 100%;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	min-height: calc(100vh - 135px);

	${({ theme }) => theme.breakpoints.up('md')} {
		padding-left: ${SIDEBAR_WIDTH};
	}

	${({ theme }) => theme.breakpoints.down('sm')} {
		margin-bottom: 60px;
		padding-bottom: 75px;
		min-height: 400px;
	}
	.react-daterange-picker {
		width: 100%;
		font-family: ${({ theme }) => theme.typography.fontFamily};
		border-bottom: none;
	}
	.react-calendar {
		font-family: ${({ theme }) => theme.typography.fontFamily};
	}

	.react-daterange-picker__wrapper {
		border-top: none;
		border-left: none;
		border-right: none;
	}
	.react-daterange-picker__inputGroup {
		input {
			font-family: ${({ theme }) => theme.typography.fontFamily};
			display: inline-block;
			text-align: center;
			margin-right: 3px;
		}
	}
	.react-daterange-picker__inputGroup:first-child {
		flex-grow: 0;
	}

	.react-daterange-picker__inputGroup:last-child {
		flex-grow: 1;
	}
`

export const mainContentJobDetailStyle = css`
	width: 100%;

	height: calc(100vh - 65px);
	overflow: hidden;

	> div {
		height: 100vh;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		padding-left: ${SIDEBAR_WIDTH};
	}

	${({ theme }) => theme.breakpoints.down('sm')} {
		margin-bottom: 70px;
		padding-bottom: 85px;
		height: calc(100vh - 120px);
	}
`

export const mainContentAnonymousStyle = css`
	width: 100%;
	min-height: 400px;

	form {
		max-width: 100%;
	}

	${({ theme }) => theme.breakpoints.down('sm')} {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	.no-back-link {
		.wrapTitleAnon {
			h6 {
				padding-left: 0px;
			}
		}
	}

	.wrapTitleAnon {
		h6 {
			padding-left: 30px;
		}
	}
`

export const profileToggleStyle = css`
	position: absolute;
	top: 13px;
	right: ${({ theme }) => theme.spacing(2)}px;
	padding: 0;
`

export const drawerTopStyle = css`
	padding: 32px 16px;
	margin: 0;
	background-color: ${({ theme }) => theme.palette.secondary.light};

	.full-name {
		font-size: 15px;
		font-weight: 900;
		font-stretch: normal;
		font-style: normal;
		color: ${({ theme }) => theme.palette.text.secondary};
	}
`

export const closeBtnWrapperStyle = css`
	.MuiSvgIcon-root {
		fill: white;
	}
`

export const drawerBottomStyle = css`
	.MuiSvgIcon-root {
		fill: ${({ theme }) => theme.palette.text.primary};
	}

	.MuiListItemText-primary {
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		color: ${({ theme }) => theme.palette.text.primary};
	}
`

export const bottomAppBarStyle = css`
	position: fixed;
	top: auto;
	bottom: 0;
	width: 100%;

	.MuiBottomNavigation-root {
		height: 60px;
		background-color: ${({ theme }) => theme.palette.background.default};
		.MuiBottomNavigationAction-root {
			color: ${({ theme }) => theme.palette.text.disabled};
			opacity: 1;

			svg {
				fill: ${({ theme }) => theme.palette.text.disabled};
			}

			&.Mui-selected {
				color: ${({ theme }) => theme.palette.background.element.footerSideBar};

				.MuiSvgIcon-root {
					svg {
						fill: ${({ theme }) => theme.palette.text.primary};
					}
				}
			}
		}
	}

	.MuiBottomNavigationAction-label {
		padding-top: 3px;
		font-size: 11px;

		&.Mui-selected {
			border-bottom: 1px solid ${({ theme }) => theme.palette.primary.contrastText};
		}
	}
`

export const socialWrapperStyle = css`
	display: flex;
	padding-left: 5px;
	.MuiListItem-button {
		border-radius: 50px;
		width: 44px;
		height: 44px;
		padding: 0 10px 0 0 !important;
		&:hover {
			text-decoration: none;
			background-color: rgba(0, 0, 0, 0.04);
		}
	}
	.MuiListItemIcon-root {
		padding-left: 10px;
	}
`

export const menuItemStyle = css`
  &.MuiListItem-root {
    margin: 13px 0;
  }

  &.sidebar-resources{
	svg {
	  path {
		stroke: white;
	  }
	}
  }

  &.MuiBottomNavigationAction-root {
    opacity: 0.5;
  }

  .MuiListItemIcon-root {
    min-width: 40px;
  }

  .MuiListItemText-root {
    .MuiTypography-root {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: ${({ theme }) => theme.palette.content.dark};
    }
  }

  &.MuiListItem-root.Mui-selected {
    .MuiListItemText-root {
      &.mobile-responsive {
        .MuiTypography-root {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.2px;
          color: ${({ theme }) => theme.palette.content.veryLight};
            //color: ${({ theme }) => theme.palette.background.element.light};
        }
      }

      .MuiTypography-root {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;

        color: ${({ theme }) => theme.palette.content.veryLight};
          //color: ${({ theme }) => theme.palette.primary.main};  
      }
    }

    .MuiListItemIcon-root {
      &.mobile-responsive {
        color: ${({ theme }) => theme.palette.primary.main};
      !important;
      }

      color: ${({ theme }) => theme.palette.content.veryLight} !important;
    }
  }
`

export const menuDisabledItemStyle = css`
	&.MuiListItem-root {
		opacity: 0.24 !important;
		margin: 13px 0;

		.MuiListItemIcon-root {
			min-width: 40px;
		}
	}

	&.MuiBottomNavigationAction-root {
		opacity: 0.24;
	}
`

export const containerStyle = css`
	display: flex;
	flex-direction: column;

	.header-bar {
		${({ theme }) => theme.breakpoints.up('md')} {
			padding-left: ${SIDEBAR_WIDTH};
		}
	}

	.app-banner {
		background-color: white;
	}
`

export const leftMenuHeader = css`
	font-family: Avenir;
	font-size: 20px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.35;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
`

export const linkStyle = css`
	text-decoration: none;
	color: ${({ theme }) => theme.colors.hyperlink.medium};
	cursor: pointer;

	&:hover {
		text-decoration: none;
		color: ${({ theme }) => theme.colors.hyperlink.medium};
	}
`

export const pageTitleStyle = css`
	text-transform: uppercase;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	color: ${({ theme }) => theme.colors.primary.main};
`

export const emptyDataStyle = css`
	background-color: ${({ theme }) => theme.palette.background.light};
	border-radius: 16px;
	text-align: center;

	.nodata-container {
		width: 295px;
		height: 267px;
		margin: auto;
	}

	svg {
		width: 100%;
		height: 100%;
	}

	.text-title {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		letter-spacing: 0.1px;
		color: ${({ theme }) => theme.colors.primary.main};
	}

	.text-info {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		align-items: center;
		text-align: center;
		letter-spacing: 0.3px;
		padding: 39px 0px 47px 0px;

		color: ${({ theme }) => theme.palette.content.dark};
	}
`

export const submitStyle = css`
	color: #2854aa;
	border: 1px solid #2854aa;
	border-radius: 2px;
	padding: 0 4px;
`

export const loadMoreStyle = css`
	margin-top: 8px;
`
export const hiddenStyle = css`
	display: none;
`

export const submitButtonStyle = css`
	background: ${({ theme }) => theme.colors.primary.main};
	border: 1px solid ${({ theme }) => theme.colors.background.element.dark};
	border-radius: 5px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	color: ${({ theme }) => theme.colors.background.app.light};
	&:hover {
		color: ${({ theme }) => theme.colors.primary.main};
		border: 2px solid ${({ theme }) => theme.colors.primary.main};
		background: ${({ theme }) => theme.palette.background.app.light};
	}
`

export const sidebarMobileStyle = css`
	.MuiDrawer-paper {
		padding-top: 60px;
		background-color: ${({ theme }) => theme.palette.background.element.footerSideBar};
	}

	.MuiListItemIcon-root {
		color: ${({ theme }) => theme.palette.background.app.light};
		min-width: 40px;
	}

	.MuiListItemText-root span.MuiTypography-root {
		color: ${({ theme }) => theme.palette.background.app.light};
	}
`

export const logoStyle = css`
	width: 165px;
	height: auto;
`

export const onboardingPercentageStyle = css`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	margin-top: 4px;
	margin-bottom: 4px;

	letter-spacing: 0.3px;
	padding-left: 10px;
	color: ${({ theme }) => theme.palette.status.warning.medium} !important;
`

import { action, observable, store } from '~/common/mobx.decorator'

@store()
class UploadStatusDialogStore {
	@observable showUploadStatusDialogStore = false
	@observable isSuccessShowUploadStatusDialogStore = false

	@action
	setShowUploadStatusDialogStore = (value) => {
		this.showUploadStatusDialogStore = value
	}
	@action
	setIsSuccessShowUploadStatusDialogStore = (value) => {
		this.isSuccessShowUploadStatusDialogStore = value
	}
}

export const uploadStatusDialogStore = new UploadStatusDialogStore()

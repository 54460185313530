import { createMuiTheme } from '@material-ui/core'

export const careTheme = createMuiTheme({
	overrides: {
		MuiDrawer: {
			paperAnchorDockedLeft: {
				borderRight: '1px solid #F4F5F4',
			},
		},
		MuiListItem: {
			root: {
				color: '#868686',
				fontSize: '14px',
				fontWeight: 800,
				letterSpacing: '0.1px',
				'&.Mui-selected': {
					backgroundColor: 'transparent',
				},
			},
		},
		MuiFormLabel: {
			root: {
				color: '#255E8E',
				whiteSpace: 'nowrap',
				fontWeight: 800,
				'&$focused': {
					color: '#255E8E',
				},
			},
		},

		MuiFormControlLabel: {
			label: {
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '18px',
				letterSpacing: '0.2px',
				color: '#255E8E',
			},
		},
		MuiInputBase: {
			root: {
				color: '#255E8E',
				fontWeight: 400,
				fontSize: '14px',
			},
		},
		MuiInputLabel: {
			shrink: {
				transform: 'translate(0, 1.5px) scale(0.86)',
			},
		},
		MuiBackdrop: {
			root: {
				backgroundColor: 'transparent !important',
			},
		},
		MuiDialog: {
			container: {
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
			},
		},
		MuiButtonGroup: {
			groupedOutlinedPrimary: {
				'&:hover': {
					borderColor: '#99E1E1',
				},
			},
		},
		MuiButton: {
			outlinedPrimary: {
				border: '2px solid #99E1E1',
				backgroundColor: '#FFFFFF',
				'&:hover': {
					border: '2px solid #99E1E1',
				},
			},
			containedPrimary: {
				color: '#03606A',
				backgroundColor: '#E3F8F4',
				boxShadow: 'none',
				border: '2px solid #99E1E1',
				'&:hover': {
					backgroundColor: '#E3F8F4 !important',
					boxShadow: 'none',
				},
			},

			sizeLarge: {
				height: '44px',
			},
			sizeSmall: {
				height: '32px',
			},
		},
		MuiTab: {
			root: {
				textTransform: 'unset',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#99E1E1',
			},
		},
	},
	spacing: 8,
	palette: {
		primary: {
			light: '#E3F8F4',
			main: '#03606A',
			secondary: '#2D9CDB',
			tertiary: '#03606A',
			contrastText: '#ffffff',
		},
		secondary: {
			light: '#f8fcfc',
			main: '#82cecf',
		},
		error: {
			main: '#fe7d74',
		},
		text: {
			primary: '#000000',
			secondary: '#03606A',
			tertiary: '#7E7E80',
			disabled: '#AEAEAE',
			blue: '#1C5CDB',
			hint: '#868686',
		},
		background: {
			default: '#ffffff',
			paper: '#ffffff',
			light: 'rgba(130, 206, 206, 0.5)',
		},
		action: {
			active: '#868686',
			hover: 'rgba(0, 0, 0, 0.04)',
			selected: 'rgba(0, 0, 0, 0.08)',
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
		},
		divider: 'rgba(0, 0, 0, 0.12)',
	},
	shape: {
		borderRadius: 10,
	},
	borders: ['unset', 'solid 1px #e5e5e5', 'solid 1px rgba(130, 206, 207, 0.1)', 'solid 1px #82CECE'],
	typography: {
		fontFamily: ['Avenir'],
		h6: { fontSize: '1.125rem' },
		h5: { fontSize: '1.1875rem', fontWeight: 500 },
		h4: { fontSize: '1.5rem' },
		h3: { fontSize: '1.875rem' },
		h2: { fontSize: '2rem' },
		h1: { fontSize: '2rem' },
		subtitle1: { fontSize: '16px', fontWeight: 500 },
		subtitle2: { fontSize: '14px', fontWeight: 500 },
		subtitle3: { fontSize: '12px', fontWeight: 500 },
		body1: { fontSize: '0.9375rem', fontWeight: 'normal' },
		body2: { fontSize: '0.675rem' },
		button: { fontSize: '0.9375rem' },
		caption: { fontSize: '0.8125rem' },
	},
})

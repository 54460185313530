import {
	apolloClient,
	DISMISS_BULLETIN_MUTATION,
	GET_AUTHORIZED_WORKER,
	GET_BULLENTINS_QUERY,
	GET_GENERAL_RANKED_JOBS_QUERY,
	GET_HOT_JOBS_QUERY,
	GET_JOB_DISCOVERS_QUERY,
	GET_LIKES_JOBS_DISCOVER_COUNT,
	GET_SUGGESTION_JOBS_QUERY,
} from '~/common/apollo'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, notifyStore } from '~/stores'
import { includes, isEmpty, take } from 'lodash'
import { EXTEND_JOB_APPLICANT_MUTATION } from '~/common/apollo/mutations/job-applicant.mutation'
import { careFindJobStore } from '~/features/care-find-job/care-find-job.store'
import { mappingJobSearchData } from '~/common/helpers/mapping.helper'

@store()
class CareHomeStore {
	@observable numCityCall = 0
	@observable numJobCall = 0

	@observable citiesMatches = []
	@observable workAuthorized = {}
	@observable hotJobs = []
	@observable suggestedJobs = []
	@observable bulletins = []
	@observable referGuidanceContent = {}
	@observable discover = {}
	@observable phoneAuthorized = null
	@observable phoneVerifiedAt = null
	@observable workerInfo = null
	@observable settings = {}
	@observable discoverLastSearch = {}
	@observable totalCountLastSearch = 0
	@observable similarJobFilter = {}
	@observable lastSearchTitle = ''
	@observable totalLikesJobs = 0

	@computed
	get citiesMatchesItems() {
		return this.citiesMatches
	}

	@action
	setSimilarJobFilter = (filter) => {
		this.similarJobFilter = careFindJobStore.transferDataParams(filter)
	}

	@computed
	get hotJobsItems() {
		return this.hotJobs
	}

	@computed
	get suggestedJobsItems() {
		return this.suggestedJobs
	}

	@action
	fetchCitiesMatches = async () => {
		const response = await apolloClient.query({
			query: GET_GENERAL_RANKED_JOBS_QUERY,
		})

		this.citiesMatches = take(response.data.popularCities, 4)
		setTimeout(() => {
			this.numCityCall += 1
		}, 500)
	}

	@action
	fetchSuggestedJobs = async () => {
		const response = await apolloClient.query({ query: GET_SUGGESTION_JOBS_QUERY, variables: { limit: 20, offset: 0 } })
		this.suggestedJobs = response?.data?.suggestionJobsV2?.jobs

		setTimeout(() => {
			this.numJobCall += 1
		}, 500)
	}

	@action
	fetchHotJobs = async () => {
		const response = await apolloClient.query({
			query: GET_HOT_JOBS_QUERY,
			variables: { offset: 0, limit: 20 },
		})
		this.hotJobs = response.data.hotJobsV2.jobs

		setTimeout(() => {
			this.numJobCall += 1
		}, 500)
	}

	@action
	fetchGuidances = async () => {
		const { data } = await apolloClient.query({
			query: GET_BULLENTINS_QUERY,
		})

		this.bulletins = data?.bulletins.filter((item) => item?.kind !== 'onboarding' && item?.kind !== 'refer')
		this.referGuidanceContent = data?.bulletins.find((item) => item?.kind === 'refer')

		if (authStore?.isAnonymous) {
			const deleteBulletinsAnoParsed = JSON.parse(window.localStorage.getItem('deleteBulletinsAno')) || []
			let deleteBulletinsAno = isEmpty(deleteBulletinsAnoParsed) ? [] : deleteBulletinsAnoParsed
			this.bulletins = this.bulletins?.filter((item) => !includes(deleteBulletinsAno, item.id))
			return
		}
	}

	@action
	dismissBulletinForAnonymous = async (bulletinId) => {
		try {
			const deleteBulletinsAnoParsed = JSON.parse(window.localStorage.getItem('deleteBulletinsAno')) || []
			let deleteBulletinsAno = isEmpty(deleteBulletinsAnoParsed) ? [] : deleteBulletinsAnoParsed
			deleteBulletinsAno.push(bulletinId)
			window.localStorage.setItem('deleteBulletinsAno', JSON.stringify(deleteBulletinsAno))
			this.fetchGuidances()
			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	dissmisBulletin = async (bulletinId) => {
		if (authStore.isAnonymous) {
			this.dismissBulletinForAnonymous(bulletinId)
			return
		}

		try {
			await apolloClient.mutate({
				mutation: DISMISS_BULLETIN_MUTATION,
				variables: { bulletinId },
			})
			notifyStore.success('$MESSAGES.SUCCESSFUL')
			this.fetchGuidances()
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchDiscovers = async () => {
		try {
			const { data } = await apolloClient.query({
				query: GET_JOB_DISCOVERS_QUERY,
			})
			this.discover = data?.discoversV2
			if (!authStore.id) {
				const hasLastSearch = window.localStorage.getItem('lastSearch')
				if (!isEmpty(hasLastSearch)) {
					const hasLastSearchCondtion = JSON.parse(window.localStorage.getItem('lastSearch'))?.filterConditions
					this.discoverLastSearch = hasLastSearchCondtion
					this.lastSearchTitle = 'Last Search'
					careFindJobStore.fetchSearchResults({ ...hasLastSearchCondtion }, '')
				} else {
					this.lastSearchTitle = 'Posted Today'
					this.discoverLastSearch = mappingJobSearchData(data?.discoversV2.lastSearch?.filterConditions)
					careFindJobStore.fetchSearchResults(this.discoverLastSearch, '')
				}
			} else {
				if (data?.discoversV2?.lastSearch?.type === 'posted_today') {
					this.lastSearchTitle = 'Posted Today'
				} else {
					this.lastSearchTitle = 'Last Search'
				}
				this.discoverLastSearch = mappingJobSearchData(data?.discoversV2.lastSearch?.filterConditions)
				this.totalCountLastSearch = data?.discoversV2.lastSearch.count
			}
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchWorkerAuthorized = async () => {
		try {
			const { data } = await apolloClient.query({
				query: GET_AUTHORIZED_WORKER,
				variables: {
					workerId: authStore.id,
				},
			})
			this.workerInfo = data?.worker
			this.phoneAuthorized = data?.worker?.phone
			this.phoneVerifiedAt = data?.worker?.phoneVerifiedAt
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	onExtendPlaceJob = async (values) => {
		try {
			const { note, timeOffEndDate, timeOffStartDate, jobApplicantId } = values

			await apolloClient.mutate({
				mutation: EXTEND_JOB_APPLICANT_MUTATION,
				variables: { workerAssignmentId: jobApplicantId, note, timeOffEndDate, timeOffStartDate },
			})

			notifyStore.success('$MESSAGES.SUCCESSFUL')
			this.fetchGuidances()
		} catch (error) {
			notifyStore.error(error.message)
		}
	}
	@action
	fetchTotalLikesJobs = async () => {
		const { data } = await apolloClient.query({
			query: GET_LIKES_JOBS_DISCOVER_COUNT,
		})
		this.totalLikesJobs = data?.discoversV2?.likedJobs
	}
}

export const careHomeStore = new CareHomeStore()

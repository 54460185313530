import React, { useCallback, useState } from 'react'
import { useField } from 'formik'
import { FormHelperText, TextField as MuiTextField } from '@material-ui/core'
import { textFieldStyle } from './text-field.style'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { useDebounce } from 'react-use'

export const TextField = ({ name, validate, label, placeholder, mode, errorMessage, shrink, ...rest }) => {
	const { t } = useTranslation()

	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [focused, focusProps] = useFieldFocused(field)
	const [inputValue, updateInputValue] = useState(field.value)
	const error = useFieldError(meta)

	useDebounce(
		() => {
			if (field.value !== inputValue && !focused) {
				updateInputValue(field.value)
			}
		},
		500,
		[field.value]
	)

	useDebounce(
		() => {
			field.onChange({ target: { name, value: inputValue } })
		},
		300,
		[inputValue]
	)

	const handleChange = useCallback(
		(event) => {
			updateInputValue(event.target.value || '')
		},
		[updateInputValue]
	)

	if (mode === FIELD_MODE.view) {
		return <LabelField label={t(label)} displayValueFormat={() => inputValue} />
	}

	return (
		<>
			<MuiTextField
				id={name}
				label={t(label)}
				placeholder={placeholder && t(placeholder)}
				helperText={error}
				error={!!error}
				css={textFieldStyle}
				InputProps={focusProps}
				InputLabelProps={{
					shrink: focused || !!inputValue || shrink,
				}}
				{...field}
				value={inputValue || ''}
				onChange={handleChange}
				{...rest}
			/>
			{errorMessage && <FormHelperText style={{ color: '#FF2727' }}>{errorMessage}</FormHelperText>}
		</>
	)
}

TextField.defaultProps = {
	mode: FIELD_MODE.edit,
	shrink: false,
}

import React, { Suspense } from 'react'
import { Redirect, Route as BaseRoute } from 'react-router-dom'
import { observer } from '~/common/mobx.decorator'
import { authStore, routerStore } from '~/stores'
import { Error403 } from '~/components/errors'
import { Backdrop, Box, CircularProgress, Zoom } from '@material-ui/core'
import { PATHS } from '~/common/constants'
import { commonSigninStore } from '~/features/common-signin/common-signin.store'

const RouteComponent = observer(({ auth, feature, component: Component, transition: Transition, ...props }) => {
	if (!Component) {
		return <Redirect to={PATHS.common.home} />
	}
	const pathsToRedirect = [
		PATHS.common.signin,
		PATHS.common.authorization,
		PATHS.common.reset,
		PATHS.common.signup,
		PATHS.common.link_account,
		PATHS.common.link_options,
	]
	const pathname = window.location.pathname
	if (pathname === '/profile') {
		if (authStore.isAnonymous) {
			return <Redirect to={{ pathname: PATHS.common.authorization }} />
		} else if (!authStore.activated) {
			return <Redirect to={{ pathname: PATHS.care.activation }} />
		}
	}

	if (pathname === PATHS.care.express_pass && authStore.isAnonymous) {
		return <Redirect to={{ pathname: PATHS.common.signin, state: { from: routerStore.from } }} />
	}

	if (authStore.id && pathname === PATHS.common.reset) {
		return <Redirect to={{ pathname: PATHS.common.change_password }} />
	}

	if (authStore.authorization && authStore.phoneVerifyAt !== null && !commonSigninStore.changePasswordRequired && props.match.path === PATHS.common.signin) {
		return (
			<Redirect
				to={{
					pathname: props?.location?.state?.from?.pathname || PATHS.common.home,
					state: { from: routerStore.from },
				}}
			/>
		)
	}

	if (auth) {
		if (!authStore.authorization) {
			return (
				<Redirect
					to={{
						pathname: PATHS.common.authorization,
						state: { from: routerStore.from },
					}}
				/>
			)
		}

		if (feature && !authStore.features?.includes(feature)) {
			return <Error403 />
		}
	}

	if (authStore.id && pathname === PATHS.care.express_pass && !authStore.activated) {
		return <Redirect to={{ pathname: PATHS.care.activation }} />
	}

	if (authStore.id && pathsToRedirect.includes(props.match.path)) {
		return <Redirect to={{ pathname: PATHS.common.home, state: {} }} />
	}

	return (
		<Suspense
			fallback={
				<Backdrop open={true}>
					<CircularProgress />
				</Backdrop>
			}
		>
			{Transition ? (
				<Transition in>
					<Box>
						<Component {...props} />
					</Box>
				</Transition>
			) : (
				<Component {...props} />
			)}
		</Suspense>
	)
})

export const Route = ({ auth, feature, component, transition, ...props }) => (
	<BaseRoute {...props} render={(rest) => <RouteComponent {...rest} component={component} auth={auth} feature={feature} transition={transition} />} />
)

Route.defaultProps = {
	transition: Zoom,
}

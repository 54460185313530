import React, { useEffect, useRef } from 'react'
import { NotifyDialog } from '../notify'
import mammoth from 'mammoth'
import axios from 'axios'

export const DocxViewer = ({ showDialog, setShowDialog, docUrl, fileType = 'application/pdf' }) => {
	const iframeRef = useRef(null)

	useEffect(() => {
		const fetchAndRenderFile = async () => {
			try {
				const response = await axios.get(docUrl, { responseType: 'arraybuffer' })

				if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
					const result = await mammoth.extractRawText({ arrayBuffer: response.data })

					const iframeDoc = iframeRef.current.contentDocument
					iframeDoc.open()
					iframeDoc.write(result.value)
					iframeDoc.close()
				} else if (fileType === 'application/pdf') {
					const blob = new Blob([response.data], { type: 'application/pdf' })
					const objectUrl = URL.createObjectURL(blob)

					const iframe = iframeRef.current
					iframe.src = objectUrl
				}
			} catch (error) {
				console.error('Error loading file:', error)
			}
		}

		fetchAndRenderFile()
	}, [docUrl, fileType])

	return (
		<NotifyDialog
			open={showDialog}
			onOk={() => (window.location.href = docUrl)}
			cancelText={'Close'}
			onCancel={() => setShowDialog(false)}
			okText={'Download File'}
			title="Preview Generic Document"
			showButtonClose={true}
			onClose={() => setShowDialog(false)}
		>
			{docUrl && (
				<iframe style={{ border: '1px solid #d1d1d1', borderRadius: '8px' }} key={docUrl} ref={iframeRef} title="Docx Viewer" width="100%" height="600" />
			)}
		</NotifyDialog>
	)
}

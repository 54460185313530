import axios from 'axios'
import { nanoid } from 'nanoid'
import { captureException } from '~/common/helpers'
import { action, computed, observable, persist, storage, store } from '~/common/mobx.decorator'
import { authStore } from './auth.store'
import { COMPANY, COMPANY_PATH } from '~/common/constants/company.constant'
import { THEMES } from '~/themes'
import { notifyStore } from '~/stores/notify.store'

axios.defaults.baseURL = `${process.env.REACT_APP_PROXY_API_URL}`
axios.defaults.headers['APP_ID'] = process.env.REACT_APP_PROXY_API_APP_ID
axios.defaults.headers['COMPANY-ID'] = process.env.REACT_APP_COMPANY_ID
axios.defaults.headers['APP_SECRET'] = process.env.REACT_APP_PROXY_API_APP_SECRET

axios.interceptors.response.use(undefined, (error) => {
	captureException('Axios Client', error)
	return Promise.reject(error)
})

@store({ persist: true })
class AppStore {
	constructor() {
		storage.ready(() => {
			this.ready = true
			if (!this._uniqueId) {
				this._uniqueId = nanoid()
			}
		})
	}

	@persist @observable _uniqueId
	@observable config = null
	@observable ready = false

	@observable chatIntegrationSetting
	@observable meta_domain
	@observable store_android_url
	@observable store_ios_url

	@computed
	get id() {
		// Company Id
		return process.env.REACT_APP_COMPANY_ID
	}

	@computed
	get name() {
		return process.env.REACT_APP_COMPANY_NAME
	}

	@computed
	get logo() {
		return `${process.env.PUBLIC_URL}/assets/${process.env.REACT_APP_COMPANY_HOST === COMPANY.JNP ? COMPANY_PATH.jnp : COMPANY_PATH.jtp}/logo.png?version=${
			process.env.REACT_APP_CI_COMMIT_TAG
		}`
	}

	@computed
	get getTheme() {
		return process.env.REACT_APP_COMPANY_HOST === COMPANY.JNP ? THEMES.jnp : THEMES.jtp
	}

	@action
	init = async () => {
		await this.fetchFEConfig()
		await this.fetchAppConfig()

		return new Promise((resolve) => {
			// wait 1s to make sure all resource loaded
			setTimeout(async () => {
				await authStore.verifyToken()
				resolve()
			}, 1000)
		})
	}

	fetchAppConfig = async () => {
		const response = await axios.get(`/company_configs`)

		this.config = response?.data
		this.chatIntegrationSetting = response.data?.chatIntegrationSetting
		this.store_android_url = process.env.REACT_APP_MAIN_DOMAIN
		this.store_ios_url = process.env.REACT_APP_MAIN_DOMAIN
		this.meta_domain = response?.data?.companyConfigs?.ssrUrl

		await notifyStore.initOneSignal()
		await notifyStore.sendInfo()
	}

	fetchFEConfig = async () => {
		return JSON.parse(process.env.REACT_APP_CONFIG_JSON)
	}
}

export const appStore = new AppStore()

import { gql } from '@apollo/client'
import { JOB_FRAGMENT, WORKER_ASSIGNMENT_FRAGMENT, WORKER_FRAGMENT, WORKER_JTP_FRAGMENT } from '../apollo.fragment'

const workerFragment = process.env.REACT_APP_COMPANY_HOST === 'JNP' ? WORKER_FRAGMENT : WORKER_JTP_FRAGMENT
export const GET_GENERAL_RANKED_JOBS_QUERY = gql`
	query getGeneralRankedJobsQuery {
		popularCities {
			city
			cityImage
			state
			avgWeeklyRate
			highWeeklyRate
			lowWeeklyRate
			totalJobs
		}
	}
`

export const GET_DETAIL_RANKED_JOBS_QUERY = gql`
	query PopularCityJobs($state: String!, $city: String!, $offset: Int, $limit: Int, $filter: JobFilter) {
		popularCityJobsV2(state: $state, city: $city, offset: $offset, limit: $limit, filter: $filter) {
			jobs {
				...jobFragment
			}
			totalCount
		}
	}

	${JOB_FRAGMENT}
`

export const GET_SUGGESTION_JOBS_QUERY = gql`
	query getSuggestionJobsQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		suggestionJobsV2(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_SIMILAR_JOBS_QUERY = gql`
	query getSimilarSearchJobsQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		similarSearchJobs(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_HOT_JOBS_QUERY = gql`
	query getHotJobsQuery($filter: JobFilter, $offset: Int, $limit: Int) {
		hotJobsV2(filter: $filter, offset: $offset, limit: $limit) {
			totalCount
			jobs {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_ACTIVE_JOBS_QUERY = gql`
	query getActiveJobsQuery {
		activeJobs {
			completedJobs {
				...jobFragment
			}
			currentJobs {
				...jobFragment
			}
			nextJobs {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_ACTIVE_WORKER_ASSIGNMENT_QUERY = gql`
	query getActiveWorkerAssignment {
		activeListWorkerAssignments {
			completed {
				...workerAssignmentFragment
			}
			current {
				...workerAssignmentFragment
			}
			next {
				...workerAssignmentFragment
			}
		}
	}

	${WORKER_ASSIGNMENT_FRAGMENT}
`

export const GET_APPLIED_JOBS_QUERY = gql`
	query getAppliedJobsQuery($filter: JobApplicantStatusEnum, $offset: Int, $limit: Int) {
		appliedJobs(filter: $filter, offset: $offset, limit: $limit) {
			id
			status
			createdAt
			updatedAt
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${workerFragment}
`

export const GET_FAVORITED_JOBS_QUERY = gql`
	query getfavoritedJobsQuery($after: String, $before: String, $first: Int, $last: Int) {
		likedJobs(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_JOB_DISCOVERS_QUERY = gql`
	query getJobDiscoversQuery {
		discoversV2 {
			available
			lastSearch {
				count
				type
				filterConditions
			}
			trending
		}
	}
`

export const GET_BULLENTINS_QUERY = gql`
	query getBullentinsQuery {
		bulletins {
			id
			active
			allowDismiss
			content
			createdAt
			data
			expiredAt
			kind
			mediaUrl
			name
			scope
			targetedClass
			title
		}
	}
`

export const GET_ALLOW_DISMISS_BULLETIN_QUERY = gql`
	query AllowDismissedBulletins {
		allowDismissedBulletins {
			id
			isDismissed
			name
		}
	}
`

export const DISMISS_BULLETIN_MUTATION = gql`
	mutation dismissBulletinMutation($bulletinId: String!) {
		dismissBulletin(bulletinId: $bulletinId) {
			bulletinId
			success
		}
	}
`

export const MARK_ALL_DISMISS_BULLETIN_MUTATION = gql`
	mutation MarkAllDismissBulletin($action: DismissBulletinEnum!) {
		markAllDismissBulletin(action: $action) {
			success
		}
	}
`

export const REPLAY_BULLETIN_MUTATION = gql`
	mutation ReplayDismissedBulletin($bulletinIds: [String!]!) {
		replayDismissedBulletin(bulletinIds: $bulletinIds) {
			success
		}
	}
`

export const GET_TRENDING_JOBS_QUERY = gql`
	query getTrendingJobsQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		trendingJobsV2(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`
export const GET_LAST_JOBS_DISCOVER_COUNT = gql`
	query getLastJobsDiscoverCount {
		discoversV2 {
			lastViewedJobs
		}
	}
`

export const GET_LIKES_JOBS_DISCOVER_COUNT = gql`
	query getLastJobsDiscoverCount {
		discoversV2 {
			likedJobs
		}
	}
`

export const GET_LAST_JOBS_QUERY = gql`
	query getLastJobsQuery {
		lastViewedJobs {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`
export const GET_LIST_JOBS_SEO_QUERY = gql`
	query JobsBySeoPath($seoPath: String!, $limit: Int, $offset: Int) {
		jobsBySeoPath(seoPath: $seoPath, limit: $limit, offset: $offset) {
			job {
				...jobFragment
			}
			jobs {
				...jobFragment
			}
			totalCount
		}
	}
	${JOB_FRAGMENT}
`

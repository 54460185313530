import { gql } from '@apollo/client'
import { JOB_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const SAVE_FILTER_QUERY = gql`
	query SavedFilters {
		savedFilters {
			alertChannel
			companyId
			enableAlert
			filterCondition {
				startDate
				exclusiveJob
				externalId
				locationCities
				locationStates
				jobTypes
				id
				profession
				maxWage
				minMatchingPercentage
				minWage
				nearBy
				shifts
				skillName
				startDate
			}
			frequency
			id
			lastAlertedAt
			name
			pauseAlert
			pauseAlertPeriod
			pauseAlertUntil
			startPauseAlertDate
		}
	}
`

export const GET_APPLICATION_JOB_QUERY = gql`
	query getApplicantJobQuery($jobId: String!) {
		job(id: $jobId) {
			isLiked
			isDisliked
			id
			externalJobId
			externalFriendlyId
			jobApplicant {
				currentWorkerAssignment {
					createdAt
					endDate
					id
					startDate
					status
					updatedAt
					client {
						name
						id
					}
				}
				applicationProcess
				createdAt
				id
				job {
					id
				}
				status
				submittal {
					client {
						id
						name
					}
					id
					jobId
					status
				}
				timeOffEndDate
				timeOffStartDate
				updatedAt
			}
		}
	}
`

export const GET_JOB_ALERT_QUERY = gql`
	query JobAlerts($after: String, $before: String, $first: Int, $last: Int) {
		jobAlerts(after: $after, before: $before, first: $first, last: $last) {
			nodes {
				name
				alertChannel
				enableAlert
				filterCondition {
					startDate
					exclusiveJob
					externalId
					locationCities
					locationStates
					jobTypes
					profession
					id
					maxWage
					minMatchingPercentage
					minWage
					nearBy
					shifts
					skillName
				}
				frequency
				lastAlertedAt
				name
				pauseAlert
				pauseAlertPeriod
				pauseAlertUntil
				startPauseAlertDate
				jobs {
					...jobFragment
				}
			}
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
		}
	}
	${JOB_FRAGMENT}
`

export const GET_ALL_CLOSE_JOB_QUERY = gql`
	query ClosedJobsList($ids: [String!]!) {
		closedJobsList(ids: $ids) {
			id
		}
	}
`

export const INTEGRATION_OBJECTS = {
	resume: 'ResumeOnboarding',
	skillSpecialty: 'SpecialtyOnboarding',
	referenceOnboarding: 'WorkerReferenceOnboarding',
	preferredLocationOnboarding: 'WorkingPreferredLocationOnboarding',
	terms: 'UserTermsOfService',
}

export const JOB_TITLE = {
	nurse: 'Charge Nurse',
	manager: 'Manager',
}

import React from 'react'
import { NotifyDialog } from '../notify'
import { observer } from '~/common/mobx.decorator'

export const CancelVerificationDialog = observer(({ handleCancelAllModals, showModal, setShowModal }) => {
	return (
		<NotifyDialog
			open={showModal}
			okText="GO BACK"
			cancelText={'EXIT'}
			onCancel={() => handleCancelAllModals()}
			onOk={() => setShowModal(false)}
			title={'Are you sure you want to close?'}
			showButtonClose={true}
			onClose={() => setShowModal(false)}
		>
			This cannot be undone, and it will count as a request to verify the code. <br />
			Your phone number has not been verified yet. You would still need to verify your phone number to continue using our platform.
		</NotifyDialog>
	)
})

import { Box, Chip, makeStyles, TextField } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Autocomplete } from '@material-ui/lab'
import { LabelField } from '@opus/web.core.form.label-field'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import axios from 'axios'
import { useField, useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useCallback, useState, useMemo } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { useEffectOnce } from 'react-use'
import { css } from 'styled-components'
import { FIELD_MODE } from '~/common/constants'
import { parseLocation } from '~/common/helpers'
import { useFieldError, useFieldFocused } from '~/common/hooks'

export const labelStyle = css`
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.primary.main} !important;
	text-transform: capitalize;
	.MuiFormLabel-root.Mui-disabled {
		color: ${({ theme }) => theme.overrides.MuiButton.containedPrimary.color} !important;
	}
	.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.palette.content.veryDark};
	}
`

const useStyles = makeStyles((theme) => ({
	clearIndicator: {
		backgroundColor: theme.palette.primary.main,

		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		'& span': {
			'& svg': {
				fill: 'white',
				fontSize: '0.6rem',
			},
		},
	},
}))

export const CityStateField = ({ name, validate, label, placeholder, disabled, freeSolo, mode, multiple, disableClearable, onSelect, defaultValue }) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { setFieldValue } = useFormikContext()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [inputValue, updateInputValue] = useState('')
	const [, focusProps] = useFieldFocused(field)

	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [fetchDone, setFetchDone] = React.useState(false)
	const loading = open && options.length === 0 && !fetchDone

	const fetchCities = async (input) => {
		const response = await axios.get(`/cities/search?terms=${input}`)
		const cities = response?.data?.data
		setFetchDone(true)
		setOptions(cities)
	}

	React.useEffect(() => {
		if (!loading) {
			return undefined
		}

		fetchCities(field.value?.name || '')
	}, [loading, field.value])

	React.useEffect(() => {
		if (!open) {
			setOptions([])
		} else {
			fetchCities(field.value?.name || '')
		}
	}, [open, field.value])

	useEffectOnce(() => {
		if (!isEmpty(defaultValue)) {
			setFieldValue(name, { ...defaultValue })
		}
	})

	const errorMsg = meta?.error
	const errorFlat = useFieldError(meta)
	const errorNest = useFieldError(meta, 'city')
	const error = typeof errorMsg === 'string' ? errorFlat : errorNest

	const handleChange = useCallback(
		(_, option) => {
			if (option !== null) {
				const address = multiple ? option.map((opt) => parseLocation(opt)) : parseLocation(option)
				setFieldValue(name, { ...address })
				updateInputValue(
					multiple
						? option?.map((opt) => `${opt.city || opt.name}, ${opt.state || opt.stateCode}`)
						: `${option.city || option.name}, ${option.state || option.stateCode}`
				)

				onSelect && onSelect(option)
				onSelect && updateInputValue('')
				onSelect && setFieldValue(name, '')
			} else {
				setFieldValue(name, undefined)
				updateInputValue(undefined)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateInputValue, multiple, setFieldValue, name]
	)

	const city = field?.value?.city
	const state = field?.value?.state

	const viewValue = useMemo(() => {
		if (city && state) {
			return `${city}, ${state}`
		}
		if (city && !state) {
			return `${city}`
		}
		if (!city && state) {
			return `${state}`
		}
		return t('$PLACEHOLDERS.NONE')
	}, [t, city, state])

	if (mode === FIELD_MODE.view) {
		if (multiple) {
			return (
				<Box>
					<Box mb={2} css={labelStyle}>
						<label> {t(label)} </label>
					</Box>
					{field?.value &&
						Object.values(field?.value)?.map((item) => (
							<span>
								{' '}
								<Chip label={`${item.name}, ${item.stateCode}`} />{' '}
							</span>
						))}
				</Box>
			)
		} else {
			return <LabelField label={t(label)} displayValueFormat={() => viewValue} />
		}
	}

	return (
		<Autocomplete
			key={name}
			id={name}
			freeSolo={freeSolo}
			open={open}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			loading={loading}
			options={options}
			getOptionSelected={(option, value) => {
				return option?.fullAddress === value?.fullAddress || (option?.name === value?.name && option?.stateCode === value?.stateCode)
			}}
			renderOption={(option) => {
				return (
					<>
						<b>{option.name}</b>
						{', ' + option.stateCode + ', ' + option.countryCode}
					</>
				)
			}}
			getOptionLabel={(option) => {
				if (Object.values(option).some((item) => item === undefined)) {
					return null
				} else {
					const city = option?.city || option?.name
					const state = option?.stateCode || option?.state
					return [city, state].filter((item) => !isEmpty(item)).join(', ')
				}
			}}
			fullWidth
			multiple={multiple}
			{...field}
			{...focusProps}
			disabled={disabled}
			onChange={handleChange}
			inputValue={inputValue}
			disableClearable={disableClearable}
			filterOptions={(options) => {
				return options
			}}
			classes={{
				clearIndicatorDirty: classes.clearIndicator,
			}}
			onInputChange={(event, newInputValue) => {
				if (newInputValue !== 'null, null') {
					updateInputValue(newInputValue)
				} else {
					updateInputValue(undefined)
				}

				fetchCities(newInputValue)
			}}
			defaultValue={defaultValue}
			popupIcon={<MdExpandMore />}
			renderInput={(params) => {
				let endAdormentChildren = Boolean(inputValue) ? params.InputProps.endAdornment.props.children : params.InputProps.endAdornment.props.children[1]

				return (
					<TextField
						{...params}
						label={t(label)}
						disabled={disabled}
						placeholder={placeholder && t(placeholder)}
						error={!!error}
						helperText={error}
						InputProps={{
							...params.InputProps,
							style: {
								paddingRight: 0,
							},
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{endAdormentChildren}
								</React.Fragment>
							),
						}}
					/>
				)
			}}
		/>
	)
}

CityStateField.defaultProps = {
	freeSolo: false,
	mode: FIELD_MODE.edit,
	multiple: false,
}

import React from 'react'
import { fileNameElisips } from '~/components/fields/file-upload-field/file-upload-field.style'
import { convertFileNameEllipsis } from '~/common/helpers'
import { FIELD_MODE } from '~/common/constants'
import { Box, IconButton } from '@material-ui/core'
import { RemoveSvg } from '~/components/icons'

function UploadListImage({ mode, onClick, item, onClick1, handleErrorImage, hideDelete = false, size }) {
	const isRenderEdit = mode === FIELD_MODE.edit || (mode === FIELD_MODE.viewDisable && item.hasOwnProperty('signedBlobId'))

	return (
		<Box
			onClick={onClick}
			style={{
				padding: '10px 0px',
				cursor: 'pointer',
				position: 'relative',
				width: size ? size : '80px',
				borderRadius: '5px',
				marginRight: '5px',
				marginLeft: '5px',
			}}
		>
			<img
				onError={() => {
					try {
						handleErrorImage(item?.blobId)
					} catch (error) {
						console.error(error)
					}
				}}
				style={{
					width: size ? size : 80,
					height: size ? size : 80,
					borderRadius: 8,
					boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
				}}
				src={item?.imageUrls?.s_500x500 || item?.src || item?.fileUrl}
				alt={item.filename}
			/>
			<p className="caption-image" css={fileNameElisips}>
				{convertFileNameEllipsis(item.filename)}
			</p>

			{isRenderEdit && !hideDelete && (
				<IconButton className="removeIconSvg" style={{ position: 'absolute', top: '12px', left: '55px' }} size="small" onClick={onClick1}>
					<RemoveSvg />
				</IconButton>
			)}
		</Box>
	)
}

export default UploadListImage

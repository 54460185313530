import gql from 'graphql-tag'
import { JOB_FRAGMENT } from '../apollo.fragment'

export const APPLY_JOB_MUTATION = gql`
	mutation CreateJobApplicant($jobId: String!, $timeOffStartDate: ISO8601Date, $timeOffEndDate: ISO8601Date, $note: String) {
		createJobApplicant(jobId: $jobId, timeOffStartDate: $timeOffStartDate, timeOffEndDate: $timeOffEndDate, source: opus_web_portal, note: $note) {
			job {
				...jobFragment
			}
			id
		}
	}
	${JOB_FRAGMENT}
`

export const APPLY_JOB_V2_MUTATION = gql`
	mutation CreateJobApplicantV2($jobId: String!, $note: String, $applicantTimeOffs: [ApplicantTimeOffArgument!]) {
		createJobApplicantV2(jobId: $jobId, note: $note, applicantTimeOffs: $applicantTimeOffs, source: opus_web_portal) {
			id
			job {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

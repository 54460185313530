import React, { useCallback, useMemo, useState } from 'react'
import { useField } from 'formik'
import { TextField as MuiTextField } from '@material-ui/core'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { phoneFieldStyle } from './phone-field.style'
import NumberFormat from 'react-number-format'
import { compose, phone, required } from '~/common/validator'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { useDebounce } from 'react-use'
import { defaultTo } from 'lodash'
import { observer } from '~/common/mobx.decorator'

const PHONE_FORMAT = '(###) ###-####'
const PHONE_CODE = '+1'

const PhoneFormat = observer(({ inputRef, onChange, value, ...rest }) => {
	const [inputValue, updateInputValue] = useState(value)
	const [focused, setFocus] = useState()

	const onValueChange = React.useCallback(
		(values) => {
			updateInputValue(values.value || '')
		},
		[updateInputValue]
	)

	const onFocus = useCallback(
		(event) => {
			setFocus(true)
			if (typeof rest.onFocus === 'function') {
				rest.onFocus(event)
			}
		},
		[setFocus, rest]
	)

	useDebounce(
		() => {
			if (value !== inputValue && !focused) {
				updateInputValue(value)
			}
		},
		500,
		[value]
	)

	useDebounce(
		() => {
			onChange({ target: { name: rest.name, value: inputValue } })
		},
		300,
		[inputValue]
	)

	const fieldValue = useMemo(() => defaultTo(inputValue, value), [inputValue, value])

	return (
		<NumberFormat
			{...rest}
			value={fieldValue}
			defaultValue={PHONE_CODE}
			getInputRef={inputRef}
			onValueChange={onValueChange}
			format={PHONE_FORMAT}
			onFocus={onFocus}
			isNumericString
		/>
	)
})

export const PhoneField = observer(({ name, validate, label, placeholder, mode }) => {
	const { t } = useTranslation()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [focused, focusProps] = useFieldFocused(field)
	const error = useFieldError(meta)

	const fieldValue = useMemo(() => field.value?.replace(PHONE_CODE, '') || null, [field.value])

	const handleChange = useCallback(
		(event) => {
			const value = event.target.value ? `${PHONE_CODE}${event.target.value}` : ''

			if (!value && !field.value) {
				return
			}

			field.onChange({ target: { name, value: value } })
		},
		[name, field]
	)

	if (mode === FIELD_MODE.view) {
		return <LabelField label={t(label)} displayValueFormat={() => <NumberFormat value={fieldValue} displayType="text" format={PHONE_FORMAT} />} />
	}

	return (
		<MuiTextField
			id={name}
			label={t(label)}
			placeholder={placeholder ? t(placeholder) : PHONE_FORMAT}
			helperText={mode === FIELD_MODE.edit && error}
			error={mode === FIELD_MODE.edit && !!error}
			css={phoneFieldStyle}
			InputProps={{
				inputComponent: PhoneFormat,
				...focusProps,
			}}
			InputLabelProps={{ shrink: focused || !!field.value || mode === FIELD_MODE.view }}
			{...field}
			value={fieldValue}
			onChange={handleChange}
		/>
	)
})
PhoneField.defaultProps = {
	label: 'PHONE_NUMBER',
	validate: compose(required, phone),
	mode: FIELD_MODE.edit,
}

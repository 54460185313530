import { gql } from '@apollo/client'

export const DELETE_SUPPORT_DOCUMENTS_MUTATION = gql`
	mutation destroySupportDocument($ids: [String!]!) {
		destroySupportDocument(ids: $ids) {
			success
		}
	}
`

export const DELETE_RESUME_MUTATION = gql`
	mutation destroyWorkerResumesMutation($ids: [String!]!) {
		destroyWorkerResumes(ids: $ids) {
			success
		}
	}
`

import { css } from 'styled-components'

export const radioStyle = css`
	margin: 2px 9px;
	padding: 0;

	+ span {
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		display: flex;
		align-items: center;
		letter-spacing: 0.2px;
		color: ${({ theme }) => theme.palette.content.veryDark};
	}

	&.Mui-checked {
		+ span {
			color: ${({theme}) => theme.palette.primary.main};
		}
	}
`
export const labelStyle = css`
	font-style: normal;
	font-weight: 800;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({theme}) => theme.palette.primary.main};
	text-transform: capitalize;
`

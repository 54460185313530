import axios from 'axios'
import { find, isEmpty } from 'lodash'
import { apolloClient, QUICK_APPLY_JOB_MUTATION } from '~/common/apollo'
import { isExistingEmailError, parseLocation, RECAPTCHA_CLIENT_KEY, toFormErrors } from '~/common/helpers'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, masterStore, notifyStore } from '~/stores'

@store()
class QuickApplyStore {
	@observable confirm = false
	@observable phoneNumber = ''
	@observable currentJobID
	@observable cityObjects = []
	@observable quickApplyJob = {}
	@observable openQuickApply = false

	@computed
	get specialtiesOptions() {
		const specialties = masterStore?.specialties
		if (isEmpty(specialties)) {
			return []
		}

		let options = []
		options = specialties?.map((item) => ({ value: item.skill_name, label: item.long_name, has_checklist: item.has_checklist }))
		return options
	}

	@computed
	get stateOptions() {
		return masterStore.licenseStates
	}

	@action
	convertToSaveData = (variables) => {
		const workerSpecialtiesAttributes = variables?.specialty?.map((item, index) => {
			let specialty = masterStore?.specialties?.find((pick_item) => pick_item?.skill_name === item)

			return {
				specialty: item,
				category: specialty?.category,
				isPrimary: index === 0,
				workerSkillChecklistSectionsAttributes: [],
			}
		})

		let cities = []
		let states = []
		if (!isEmpty(variables?.locationCity)) {
			cities = Object.values(variables?.locationCity).map((item) => ({ preferredWorkingCity: item?.city, preferredWorkingState: item?.stateCode }))
		}

		if (!isEmpty(variables?.locationState)) {
			states = variables?.locationState?.map((state) => ({ preferredWorkingState: state }))
		}
		const workingPreferredLocationsAttributes = [...cities, ...states]

		return {
			externalJobId: variables?.externalJobId,
			workerSpecialtiesAttributes: workerSpecialtiesAttributes,
			workingPreferredLocationsAttributes: workingPreferredLocationsAttributes,
			...variables,
		}
	}

	@action
	quickApplyJobs = async (variables, { setErrors }, setClose) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'createIntegrationWorker',
					})

					let updatedVariables = {
						recaptchaToken: token,
						...this.convertToSaveData(variables),
					}

					await apolloClient.mutate({
						mutation: QUICK_APPLY_JOB_MUTATION,
						variables: { ...updatedVariables },
					})

					// const authToken = data?.quickApplyJob?.authToken

					await setClose(false)
					// await authStore.changeToken(authToken)
					// await authStore.changeProfile(data?.quickApplyJob?.worker, data?.quickApplyJob?.notificationChannel)
					await authStore.handleAuthAnonymous(false)
					await notifyStore.success('$MESSAGES.SUCCESSFUL')

					// this.phoneNumber = variables?.phone
					// otpDialogStore.setPhoneNumber(variables?.phone)
					// // window.localStorage.setItem('quickApplyJobID', JSON.stringify(variables?.jobID))
					// setTimeout(() => {
					// 	otpDialogStore.setShowDialogOtp(true)
					// }, 1000)
					resolve()
				} catch (error) {
					if (isExistingEmailError(error)) {
						this.confirm = true
					}

					const errors = toFormErrors(error) || {}
					if (errors?.hasOwnProperty('__ERROR')) {
						notifyStore.error(error.message)
					} else {
						setErrors(errors)
					}
					reject(errors)
				}
			})
		})
	}

	@action
	toggleConfirm = async (confirm) => {
		this.confirm = confirm
	}

	@action
	removePhoneNumber = async () => {
		this.phoneNumber = null
	}

	@action
	setCurrentJobID = async (jobID) => {
		this.currentJobID = jobID
	}

	@action
	fetchCities = async (keywordCity, keywordState) => {
		const response = await axios.get(`/cities/search?terms=${keywordCity}`)
		let cities = response?.data?.data

		if (keywordCity !== '' && keywordState !== '') {
			const option = find(cities, (item) => item.name === keywordCity && item.stateCode === keywordState)
			if (option !== undefined) {
				this.cityObjects = [parseLocation(option)]
			}
		}
	}

	@action
	setQuickApplyJob = async (job) => {
		this.quickApplyJob = job
	}

	@action
	setOpenQuickApply = async (value) => {
		this.openQuickApply = value
	}
}

export const quickApplyStore = new QuickApplyStore()

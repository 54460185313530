import moment from 'moment'
import { DATE_TIME_FORMAT_VIEW, DATE_FORMAT_VIEW, DATE_FORMAT_SAVE } from '../constants'

export const dateFormat = (value, format = DATE_FORMAT_VIEW) => {
	return moment(value).format(format)
}

export const datetimeFormat = (value, format = DATE_TIME_FORMAT_VIEW) => {
	return moment(value).format(format)
}

export const toDate = (value, format = DATE_FORMAT_SAVE) => {
	return moment(value, format).toDate()
}

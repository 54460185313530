import { CHECK_REMAINING_OTP_REQUESTS, apolloClient } from '~/common/apollo'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { quickApplyStore } from '~/features/quick-apply/quick-apply.store'

@store()
class OtpDialogStore {
	@observable showDialogOtp = false
	@observable showPhoneVerifyModal = false
	@observable showVerificationSixTimes = false
	@observable showPhoneChangeTwoTimesModal = false
	@observable phoneNumber = ''
	@observable remainingResendCode = null
	@observable remainingChangePhone = null
	@observable submitError = false

	@observable
	setPhoneNumber = (value) => {
		this.phoneNumber = value
	}

	@computed
	get canSendOTP() {
		return this.remainingResendCode !== null && this.remainingResendCode > 0
	}

	@action
	setShowDialogOtp = (value) => {
		this.showDialogOtp = value
		quickApplyStore.removePhoneNumber()
	}

	@action
	setShowPhoneVerifyModal = (value) => {
		this.showPhoneVerifyModal = value
	}

	@action
	setShowVerificationSixTimesModal = (value) => {
		this.showVerificationSixTimes = value
	}

	@action
	setShowPhoneChangeTwoTimesModal = (value) => {
		this.showPhoneChangeTwoTimesModal = value
	}

	@action
	resetRemainingChangePhone = async () => {
		this.remainingChangePhone = null
	}

	@action
	setSubmitError = async (value) => {
		this.submitError = value
	}

	@action
	setRemainingResendCode = async (value) => {
		this.remainingResendCode = value
	}

	@action
	checkRemainingOtpRequests = async () => {
		const { data } = await apolloClient.query({
			query: CHECK_REMAINING_OTP_REQUESTS,
			variables: {
				phone: this.phoneNumber,
			},
		})

		this.remainingChangePhone = data?.checkRemainingOtpRequests?.remainingChangePhone
		this.remainingResendCode = data?.checkRemainingOtpRequests?.remainingResendCode
	}
}

export const otpDialogStore = new OtpDialogStore()

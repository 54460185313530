import gql from 'graphql-tag'
import { JOB_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const SEARCH_JOB_QUERY = gql`
	query searchJobQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		jobSearchV2(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobFragment
			}
		}
	}
	${JOB_FRAGMENT}
`
export const SEARCH_SCROLL_END_JOB_QUERY = gql`
	query searchScrollEndJobQuery($filter: JobFilter, $sortBy: JobManagementSort, $limit: Int, $offset: Int) {
		jobSearchV2(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
			totalCount
			jobs {
				...jobFragment
			}
		}
	}
	${JOB_FRAGMENT}
`

import gql from 'graphql-tag'

export const CREATE_SAVE_MUTATION = gql`
	mutation CreateSavedFilter($savedFilter: SavedFilterArgument, $filterConditions: JobFilter) {
		createSavedFilter(savedFilter: $savedFilter, filterConditions: $filterConditions) {
			id
			name
			filterCondition {
				exclusiveJob
				externalId
				locationCities
				locationStates
				maxWage
				minMatchingPercentage
				jobTypes
				minWage
				nearBy
				id
				profession
				shifts
				skillName
				startDate
			}
			alertLastName
			alertFirstName
			alertEmail
			deviceId
			alertChannel
			enableAlert
			frequency
			lastAlertedAt
			pauseAlert
			pauseAlertPeriod
			pauseAlertUntil
			startPauseAlertDate
		}
	}
`
export const UPDATE_SAVE_MUTATION = gql`
	mutation UpdateSavedFilter($savedFilter: SavedFilterArgument, $filterConditions: JobFilter) {
		updateSavedFilter(savedFilter: $savedFilter, filterConditions: $filterConditions) {
			id
			name
			filterCondition {
				startDate
				exclusiveJob
				externalId
				locationCities
				id
				locationStates
				jobTypes
				maxWage
				minMatchingPercentage
				jobTypes
				minWage
				nearBy
				profession
				shifts
				skillName
			}
			alertLastName
			alertFirstName
			alertEmail
			deviceId
			alertChannel
			enableAlert
			frequency
			lastAlertedAt
			pauseAlert
			pauseAlertPeriod
			pauseAlertUntil
			startPauseAlertDate
		}
	}
`

export const DELETE_SAVE_MUTATION = gql`
	mutation DeleteSavedFilter($savedFilter: SavedFilterArgument) {
		destroySavedFilter(savedFilter: $savedFilter) {
			id
			success
		}
	}
`

export const SYNC_ANONYMOUS_WORKER_DATA = gql`
	mutation SyncAnonymousWorkerData($savedFiltersAttributes: [SavedFilterArgument!], $workerViewedJobsAttributes: [WorkerViewedJobArgument!]) {
		syncAnonymousWorkerData(savedFiltersAttributes: $savedFiltersAttributes, workerViewedJobsAttributes: $workerViewedJobsAttributes) {
			success
		}
	}
`

import { gql } from '@apollo/client'
import { EMPLOYER_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const SIGNIN_EMPLOYER_MUTATION = gql`
	mutation signInEmployer($email: String!, $password: String!) {
		signInEmployer(email: $email, password: $password) {
			authToken
			employer {
				...employerFragment
			}
		}
	}
	${EMPLOYER_FRAGMENT}
`
export const CONFIRM_WORKER_LAST_WEEK = gql`
	mutation ConfirmWorkedLastWeek($hadWorked: Boolean!, $workedTimecardAttributes: WorkedTimecardArgument) {
		confirmWorkedLastWeek(hadWorked: $hadWorked, workedTimecardAttributes: $workedTimecardAttributes) {
			success
		}
	}
`

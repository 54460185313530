import { masterStore } from '~/stores'

export const getQueriesUrlParams = () => {
	const urlSearchParams = new URLSearchParams(window.location.search)
	return Object.fromEntries(urlSearchParams.entries()) || {}
}

export const formatStringUrl = (input) => {
	return input.replace(/[./\s-]+/g, '-').toLowerCase()
}

export function parsePathname(pathname) {
	if (pathname) {
		return pathname?.split('/').filter((item) => item !== '')
	}
}
export function removeJobsPath(url) {
	if (url.includes('/jobs/')) {
		return url.replace('/jobs/', '')
	} else {
		return url
	}
}

export const checkSEOpathJobDetail = () => {
	const { isJobDetailPage } = masterStore
	return isJobDetailPage
}

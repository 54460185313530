import { apolloClient, SIGNIN_WORKER_MUTATION } from '~/common/apollo'
import { RECAPTCHA_CLIENT_KEY } from '~/common/helpers'
import { action, store } from '~/common/mobx.decorator'
import { authStore, fireStore, notifyStore, routerStore } from '~/stores'
import { PATHS, PROVIDER_NAME } from '~/common/constants'
import { observable } from 'mobx'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { EVENT_CLICK_ID } from '~/common/tracking/event-click.constant'
import { isEmpty, isNil } from 'lodash'
import { otpDialogStore } from '~/components/otp-dialog/otp-dialog.store'

@store()
class CommonSigninStore {
	@observable phoneVerifyAt = null
	@observable changePasswordRequired = false
	@observable workerID = ''
	@action
	loginLinkedIn = async (data, history) => {
		const providerData = {
			email: data?.email,
			clientId: data?.clientId,
			workerSocialFirstName: data?.workerSocialFirstName,
			workerSocialLastName: data?.workerSocialLastName,
			providerName: data?.providerName,
			clientToken: data?.clientToken,
			code: data?.code,
			redirectUri: data?.redirectUri,
		}
		return fireStore.signInWithOauth(history, providerData)
	}

	@action
	handleSuccess = async (data, history) => {
		if (data) {
			this.loginLinkedIn(
				{
					clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
					providerName: PROVIDER_NAME.linkedin,
					code: data,
					redirectUri: `${window.location.origin}/linkedin`,
				},
				history
			)
		}
	}

	@action
	login = async (variables, { setErrors }) => {
		return new Promise((resolve, reject) => {
			logClickEvent(EVENT_CLICK_ID.clickLoginBtn)
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'signInWorker',
					})
					let updatedVariables = {
						recaptchaToken: token,
						...variables,
					}
					const {
						data: { signInWorker },
					} = await apolloClient.mutate({
						mutation: SIGNIN_WORKER_MUTATION,
						variables: updatedVariables,
					})

					//const { authToken, worker, notificationChannel } = signInWorker
					const { authToken, worker } = signInWorker
					await authStore.handleAuthAnonymous(false)

					const phoneVerifiedAt = worker?.phoneVerifiedAt
					this.phoneVerifyAt = worker?.phoneVerifiedAt
					authStore.setPhoneVerifyAt(worker?.phoneVerifiedAt)
					this.changePasswordRequired = worker?.changePasswordRequired
					this.workerID = worker?.id
					otpDialogStore.setPhoneNumber(worker?.phone)
					otpDialogStore.resetRemainingChangePhone()
					otpDialogStore.setRemainingResendCode(null)
					if (isNil(worker?.phone)) {
						await notifyStore.error('Please update your phone number')
						await routerStore.goPage(PATHS.common.update_phone)
					}

					if (worker?.changePasswordRequired) {
						await authStore.changeToken(authToken)
						await authStore.setShowChangePasswordDialog(true)
					} else if (phoneVerifiedAt === null && !!worker?.phone) {
						await authStore.changeToken(authToken)
						await otpDialogStore.setShowPhoneVerifyModal(true)
					} else {
						await authStore.changeProfile(worker)
						await authStore.changeToken(authToken)
						await this.redirectQuickApplyJob()
					}

					resolve()
				} catch (error) {
					const { graphQLErrors } = error
					if (graphQLErrors?.[0]?.extensions?.code === 430) {
						authStore.setShowResetPassLockContent(graphQLErrors?.[0]?.extensions)
						authStore.setShowResetPassLockAccount(true)
					} else {
						authStore.setShowResetPassLockContent(graphQLErrors?.[0]?.extensions)
						authStore.setShowResetPassLockAccount(true)
					}
				}
			})
		})
	}

	@action
	redirectQuickApplyJob = async () => {
		const quickApplyJobID = JSON.parse(window.localStorage.getItem('quickApplyJobID'))
		if (!isEmpty(quickApplyJobID)) {
			window.localStorage.removeItem('quickApplyJobID')
			await routerStore.goPage(`${PATHS.care.jobs}/${quickApplyJobID?.jobTitle}`)
		}
	}
	@action
	toogleChangePasswordRequired = async (value) => {
		this.changePasswordRequired = value
	}
}

export const commonSigninStore = new CommonSigninStore()

import React from 'react'
import { NotifyDialog } from '../notify'
import { Link } from 'react-router-dom'
import { linkStyle } from '~/companies/care/care.style'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { observer } from '~/common/mobx.decorator'
import { otpDialogStore } from '~/components/otp-dialog/otp-dialog.store'
import { convertToUSPhoneFormat } from '~/common/helpers/mapping.helper'

export const PhoneVerifyDialogComponent = observer(({ showPhoneVerifyModal, handleOnClickVerifyOtp, handleOnClickUpdatePhone }) => {
	const { t } = useTranslation()

	return (
		<NotifyDialog
			open={showPhoneVerifyModal}
			okText="VERIFY NOW"
			onOk={() => handleOnClickVerifyOtp()}
			cancelText={'CANCEL'}
			showButtonClose={true}
			onClose={() => otpDialogStore.setShowPhoneVerifyModal(false)}
			onCancel={() => otpDialogStore.setShowPhoneVerifyModal(false)}
			title={t('$MESSAGES.PHONE_VERIFICATION_DIALOG_TITLE')}
		>
			The phone{' '}
			<span style={{ color: '#0083C9', textDecoration: 'none', fontWeight: '700', cursor: 'unset' }}>{convertToUSPhoneFormat(otpDialogStore.phoneNumber)}</span>{' '}
			needs to be verified to continue using our platform. <br /> <br />
			If not correct, please{' '}
			<Link css={linkStyle} onClick={() => handleOnClickUpdatePhone()}>
				update your phone number
			</Link>
		</NotifyDialog>
	)
})

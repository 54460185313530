import { concat, filter, isEmpty, pick } from 'lodash'
import { isTempId, removeTempId, removeTempIds } from '~/common/helpers/uid.helper'
import { authStore } from '~/stores'
import { INTEGRATION_OBJECTS } from './constants'

export const convertResumeToSaveData = (formData) => {
	const { resumes, __workerOnboardingPart } = formData
	const signedBlobIds = resumes?.filter((image) => !image._destroy && image.signedBlobId).map((file) => file.signedBlobId)

	return {
		id: __workerOnboardingPart?.id,
		integrationAttributes: {
			workerAttributes: {
				id: authStore.id,
				resumesSignedBlobIds: signedBlobIds,
			},
		},
	}
}

export const workerSkillChecklistsAttributes = (workerSkillChecklistsAttribute) => {
	return removeTempIds(workerSkillChecklistsAttribute.workerSkillChecklists)
}

export const getWorkerSkillChecklists = (workerSkillChecklists) => {
	return workerSkillChecklists
		.filter((item) => item.frequency > 0 && item.proficiency > 0)
		.map((item) => ({
			...item,
			workerId: authStore.id,
		}))
}

export const getWorkerSkillChecklistSections = (workerSpecialty) => {
	let workerSkillChecklistSections = workerSpecialty?.workerSkillChecklistSections || []
	workerSkillChecklistSections = workerSkillChecklistSections.map((item) => ({
		id: item?.id,
		name: item?.name,
		workerId: authStore.id,
		workerSkillChecklistsAttributes: removeTempIds(getWorkerSkillChecklists(item?.workerSkillChecklists)),
	}))
	return removeTempIds(workerSkillChecklistSections)
}

export const convertAllSkillChecklistsToSaveData = (formData, emptySections = false, specialtyId) => {
	const { workerSpecialties, __workerOnboardingPart } = formData
	let workerSpecialtiesAttributes = workerSpecialties
		?.filter((item) => {
			return (!specialtyId && item.status !== 'expired') || item.id === specialtyId
		})
		.map((workerSpecialty) => ({
			id: workerSpecialty?.id,
			category: workerSpecialty?.category,
			isPrimary: workerSpecialty?.isPrimary,
			specialty: workerSpecialty?.specialty,
			workerId: authStore.id,
			specialtyType: workerSpecialty?.specialtyType,
			checklistFileSignedBlobId: workerSpecialty?.checklistFileSignedBlobId,
			workerSkillChecklistSectionsAttributes: emptySections ? [] : getWorkerSkillChecklistSections(workerSpecialty),
		}))

	workerSpecialtiesAttributes = removeTempIds(workerSpecialtiesAttributes)
	return {
		id: __workerOnboardingPart?.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			workerSpecialtiesAttributes: workerSpecialtiesAttributes,
		},
	}
}

export const convertEducationsToSaveData = (formData) => {
	const { workerEducations, __workerOnboardingPart } = formData

	const workerEducationsAttributes = workerEducations
		?.map(({ id, location, ...education }) => {
			const data = education
			if (!isTempId(id)) {
				data.id = id
			}

			if (!education?.schoolName) {
				data._destroy = true
			}

			data.city = location?.city
			data.state = location?.stateCode
			data.country = location?.country

			return data
		})
		.filter((data) => data.id || !data._destroy)

	const updatedIds = workerEducationsAttributes?.map((education) => education.id)

	const deletedWorkerEducations = __workerOnboardingPart?.integration?.workerEducations
		?.filter((education) => !updatedIds.includes(education.id))
		?.map(({ id }) => ({ id, _destroy: true }))

	return {
		id: __workerOnboardingPart?.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			workerEducationsAttributes: concat(workerEducationsAttributes, deletedWorkerEducations),
		},
	}
}

export const convertReferencesToSaveData = (formData) => {
	const { workerReferences, __workerOnboardingPart } = formData
	const workerReferencesAttributes =
		workerReferences.filter((item) => item.refType !== null).length > 0
			? workerReferences
					?.map(({ id, refType, location, ...references }) => {
						const data = references
						if (!isTempId(id)) {
							data.id = id
						}
						data.refType = refType ? 'former' : 'current'
						data.state = location?.stateCode
						data.city = location?.city
						return data
					})
					?.filter((reference) => reference.id || !reference._destroy)
			: {}
	return {
		id: __workerOnboardingPart?.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			workerReferencesAttributes: workerReferencesAttributes,
		},
	}
}

export const convertTermsToSaveData = (formData) => {
	const { workerQuizAnswers, termsOfServiceId, __workerOnboardingPart } = formData

	return {
		id: __workerOnboardingPart?.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			termsOfServiceId: termsOfServiceId && __workerOnboardingPart?.integrationConfigurations?.termsOfService?.id,
			workerQuizAnswerAttributes: workerQuizAnswers,
		},
	}
}

export const convertProfileToSaveData = (formData) => {
	const { workingAuthorization, workerAddress, workingPreferredLocations, emergencyContact, __workerOnboardingPart } = formData

	const { id, dateOfBirth, socialSecurityNumber } = workingAuthorization || {}
	const workingAuthorizationAttributes = { id }

	if (!dateOfBirth?.startsWith('xx/xx')) {
		workingAuthorizationAttributes.dateOfBirth = dateOfBirth
	}

	if (!socialSecurityNumber?.startsWith('xxx-xx')) {
		workingAuthorizationAttributes.socialSecurityNumber = socialSecurityNumber
	}

	const updateLocations = filter(workingPreferredLocations, (location) => !!location?.preferredWorkingState)

	return {
		id: __workerOnboardingPart?.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			workingAuthorizationAttributes,
			emergencyContactAttributes: emergencyContact,
			workerAddressAttributes: pick(workerAddress, ['id', 'street', 'city', 'state', 'country', 'zipcode']),
			workingPreferredLocationsAttributes: updateLocations?.map((location) => ({ preferredWorkingState: location.preferredWorkingState }))?.slice(0, 3),
		},
	}
}

export const convertDocumentsToSaveFirebase = (supportDocuments) => {
	let flatSupportDocuments = []
	for (var i = 0; i < supportDocuments.length; i++) {
		const supportDocument = supportDocuments[i]
		let uploadedDocuments = supportDocument?.documents
		for (var j = 0; j < uploadedDocuments.length; j++) {
			flatSupportDocuments.push({
				documentType: supportDocument?.documentType,
				document: uploadedDocuments[j],
				id: uploadedDocuments[j].id,
			})
		}
	}
	return flatSupportDocuments
}

export const convertDocumentsToSaveData = (formData) => {
	const supportDocuments = formData?.supportDocuments || []
	let supportDocumentsAttributes = []
	for (var i = 0; i < supportDocuments.length; i++) {
		const supportDocument = supportDocuments[i]
		let uploadedDocuments = supportDocument?.documents?.filter((doc) => !doc._destroy && doc.signedBlobId)
		for (var j = 0; j < uploadedDocuments.length; j++) {
			if (!isEmpty(uploadedDocuments[j].signedBlobId)) {
				supportDocumentsAttributes.push({
					documentType: supportDocument?.documentType,
					documentSignedBlobId: uploadedDocuments[j].signedBlobId,
				})
			}
		}
	}
	return supportDocumentsAttributes
}

export const convertPreferredLocationsToSaveData = (formData) => {
	const { worker, workingPreferredLocations, __workerOnboardingPart } = formData
	const workerAttributes = {
		id: worker?.id,
		availableStartDate: worker?.availableStartDate,
	}

	const workingPreferredLocationsAttributes = workingPreferredLocations?.map(({ id, preferredWorkingState, preferredWorkingCity, _destroy }) => {
		let data = { preferredWorkingState, preferredWorkingCity }
		if (!isTempId(id)) {
			data.id = id
			data._destroy = _destroy
		}
		return data
	})

	return {
		id: __workerOnboardingPart?.id,
		integrationAttributes: {
			id: __workerOnboardingPart?.integration?.id,
			workerAttributes: workerAttributes,
			workingPreferredLocationsAttributes: workingPreferredLocationsAttributes,
		},
	}
}

export const convertSkillChecklistToSaveData = (workerSpecialty, emptySections = false) => {
	let workerSkillChecklistSections = getWorkerSkillChecklistSections(workerSpecialty)
	workerSkillChecklistSections = removeTempIds(workerSkillChecklistSections)
	let specialty = {
		id: workerSpecialty?.id,
		category: workerSpecialty?.category,
		specialty: workerSpecialty?.specialty,
		isPrimary: workerSpecialty?.isPrimary,
		specialtyType: workerSpecialty.specialtyType,
		checklistFileSignedBlobId: workerSpecialty?.checklistFileSignedBlobId,
	}

	return {
		...removeTempId(specialty),
		workerSkillChecklistSectionsAttributes: emptySections ? [] : workerSkillChecklistSections,
	}
}

export const CONVERT_FORM_TO_SAVE_DATA = {
	[INTEGRATION_OBJECTS.resume]: convertResumeToSaveData,
	[INTEGRATION_OBJECTS.skillSpecialty]: convertAllSkillChecklistsToSaveData,
	[INTEGRATION_OBJECTS.preferredLocationOnboarding]: convertPreferredLocationsToSaveData,
	[INTEGRATION_OBJECTS.referenceOnboarding]: convertReferencesToSaveData,
	[INTEGRATION_OBJECTS.terms]: convertTermsToSaveData,
	[INTEGRATION_OBJECTS.profile]: convertProfileToSaveData,
}

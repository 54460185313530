import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Button } from '~/components/button'
import { NotifyDialog } from '../notify'
import OtpInput from 'react-otp-input'
import styled from 'styled-components'
import { Formik } from 'formik'
import useCountDown from 'react-countdown-hook'
import { appStore, routerStore } from '~/stores'
import { careSignupStore } from '~/features/care-signup/care-signup.store'
import { Link } from 'react-router-dom'
import { linkStyle } from '~/companies/care/care.style'
import { PATHS } from '~/common/constants'
import { GET_COMPANY_INFO_QUERY } from '~/common/apollo'
import { useQuery } from '@apollo/client'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { EVENT_CLICK_ID } from '~/common/tracking/event-click.constant'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { observer } from '~/common/mobx.decorator'
import { otpDialogStore } from '~/components/otp-dialog/otp-dialog.store'
import { convertToUSPhoneFormat } from '~/common/helpers/mapping.helper'
import { PhoneVerifyDialogComponent } from './phone-verify-modal'
import { VerificationSixTimesDialog } from './verification-six-times-dialog'
import { PhoneChangeTwoTimesDialog } from './phone-change-two-times-dialog'
import { CancelVerificationDialog } from './cancel-verification-dialog'
import { useUnmount } from 'react-use'
import { careStore } from '~/companies/care/care.store'

const Form = styled.form`
	.title {
		font-style: normal;
		font-weight: 800;
		font-size: 16px;
		line-height: 20px;

		letter-spacing: 0.1px;
		text-align: center;
		color: #0e3f6c;
	}

	.description {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;

		letter-spacing: 0.2px;

		color: #0e3f6c;

		margin: 12px 0;
	}

	.text {
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 28px;

		text-align: center;

		color: #000000;
		margin: 12px 0;
	}

	.timer {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 26px;
		line-height: 32px;
		/* identical to box height, or 123% */

		text-align: center;
		letter-spacing: 0.2px;

		/* Jackson Color/primary/main */

		color: #255e8e;

		&.end {
			color: #ff7d74;
		}
	}

	.send-button {
		font-weight: 400;
		font-size: 16px;
		line-height: 18px;

		text-align: center;
		letter-spacing: 0.2px;
		text-decoration-line: underline;

		color: #868686;
		cursor: pointer;
		margin: 40px 0;
		text-transform: uppercase;
		width: 100%;

		&:disabled {
			cursor: not-allowed;
		}
	}

	.inputStyle.error {
		border-bottom: 2px solid red;
	}

	.inputStyle {
		font-style: normal;

		font-size: 20px;
		font-weight: bold;
		line-height: 24px;
		text-align: center;
		color: ${({ theme }) => theme.colors.primary.main};
		width: 100% !important;
		height: 56px;

		border: none;
		border-bottom: 2px solid #c2d6dc;

		&:disabled {
			background: unset;
			cursor: not-allowed;
		}

		&.errorStyle {
			border-bottom: 2px solid #fe7d74;
		}
	}

	.containerStyle {
	}

	.separatorStyle {
		width: 16px;
	}
`
export const OtpDialogComponent = observer(({ isShowUpdatePhone = true }) => {
	const { t } = useTranslation()
	const [showCancelModal, setShowCancelModal] = useState(false)
	const { handleSubmitOTP, handleSendOTP, setPhoneInvalidConfirm, phoneInvalidConfirm, phoneInvalidMessage } = careSignupStore
	const { remainingResendCode, checkRemainingOtpRequests, showPhoneVerifyModal, showDialogOtp, setShowDialogOtp, phoneNumber } = otpDialogStore
	const [timeIn, setTimeIn] = React.useState(60 * 1000)
	const { data } = useQuery(GET_COMPANY_INFO_QUERY)

	const [timeLeft, actions] = useCountDown(timeIn, 1000)
	// const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
	const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
	const onClickResend = async () => {
		if (typeof handleSendOTP === 'function' && showDialogOtp === true) {
			handleSendOTP(phoneNumber).then(() => {
				const timeLeft = 60 * 1000
				setTimeIn(timeLeft)
				actions.start(timeLeft)
				if (remainingResendCode > 0) {
					otpDialogStore.setRemainingResendCode(remainingResendCode - 1)
				}
			})
		}
	}

	useEffect(() => {
		if ((showDialogOtp === true && otpDialogStore?.canSendOTP) || remainingResendCode > 0) {
			onClickResend()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showDialogOtp])

	const handleCloseInValidConfirmAndUpdate = async () => {
		await handleCancelAllModals()
		setPhoneInvalidConfirm(false)
		routerStore.goPage(PATHS.common.update_phone)
	}

	const handleOnClickUpdatePhone = async () => {
		await checkRemainingOtpRequests()
		setPhoneInvalidConfirm(false)
		if (otpDialogStore.remainingChangePhone > 0) {
			setPhoneInvalidConfirm(false)
			handleCancelAllModals()
			routerStore.goPage(PATHS.common.update_phone)
		} else {
			otpDialogStore.setShowPhoneChangeTwoTimesModal(true)
		}
	}

	const handleOnClickVerifyOtp = async () => {
		await checkRemainingOtpRequests()
		if (otpDialogStore.remainingResendCode > 0) {
			otpDialogStore.setShowPhoneVerifyModal(false)
			otpDialogStore.setShowDialogOtp(true)
		} else {
			otpDialogStore.setShowVerificationSixTimesModal(true)
		}
	}

	const handleOnClickCloseModals = () => {
		otpDialogStore.setSubmitError(false)
		otpDialogStore.setShowVerificationSixTimesModal(false)
		otpDialogStore.setShowPhoneVerifyModal(false)
		otpDialogStore.setShowPhoneChangeTwoTimesModal(false)
	}

	const handleCancelAllModals = () => {
		otpDialogStore.setSubmitError(false)
		setShowCancelModal(false)
		otpDialogStore.setShowDialogOtp(false)
		otpDialogStore.setShowVerificationSixTimesModal(false)
		otpDialogStore.setShowPhoneVerifyModal(false)
		otpDialogStore.setShowPhoneChangeTwoTimesModal(false)
	}

	useUnmount(() => {
		otpDialogStore.setSubmitError(false)
	})

	return (
		<>
			{!phoneInvalidConfirm && phoneNumber && (
				<Formik initialValues={{ phoneNumber, otpCode: '' }} onSubmit={handleSubmitOTP} onReset={onClickResend}>
					{({ isSubmitting, handleSubmit, resetForm, handleReset, values, errors, setFieldValue }) => {
						return (
							<NotifyDialog
								showButtonClose={true}
								onClose={() => {
									setShowCancelModal(true)
								}}
								isShowOkText={false}
								open={showDialogOtp}
								hideTitle={true}
							>
								<Form onSubmit={handleSubmit} onReset={handleReset}>
									<Typography
										css={[
											appStore.getTheme.typography.title1,
											{
												color: appStore.getTheme.palette.primary.main,
												textAlign: 'left',
												padding: '12px 0',
											},
										]}
									>
										Phone verification
									</Typography>
									<div className={'sub-title'}>
										<Typography
											variant="body2"
											color="text.primary"
											css={[
												appStore.getTheme.typography.body2,
												{
													textAlign: 'center',
													padding: '12px 0',
												},
											]}
										>
											Please enter the six-digit code we sent to
										</Typography>
										<Typography
											component={'p'}
											variant="title1"
											color="text.primary"
											css={[
												appStore.getTheme.typography.title1,
												{
													textAlign: 'center',
													color: appStore.getTheme.colors.primary.main,
												},
											]}
										>
											{convertToUSPhoneFormat(phoneNumber)}
										</Typography>
									</div>

									{isShowUpdatePhone && (
										<Box mt={'10px'} display={'flex'} justifyContent={'center'}>
											Or
											<Link
												css={linkStyle}
												variant="body2"
												onClick={async () => {
													handleOnClickUpdatePhone()
												}}
											>
												<span style={{ margin: '0px 5px' }}>click here</span>
											</Link>
											to update your phone number.
										</Box>
									)}

									<OtpInput
										value={values?.otpCode}
										onChange={async (value) => {
											setFieldValue('otpCode', value)
										}}
										numInputs={6}
										placeholder="------"
										isInputNum={true}
										hasErrored={!!errors?.otpCode}
										errorStyle="errorStyle"
										containerStyle="containerStyle"
										inputStyle={otpDialogStore.submitError ? 'inputStyle error' : 'inputStyle'}
										separator={<span className="separatorStyle" />}
									/>

									{otpDialogStore.submitError && (
										<Box mt={'10px'} color={'red'} textAlign={'center'}>
											Incorrect code, please check again.
										</Box>
									)}

									{otpDialogStore.remainingResendCode > 0 && (
										<Box mt={2} textAlign={'center'}>
											{timeLeft <= 0 ? (
												<Link
													css={linkStyle}
													variant="body2"
													onClick={async () => {
														await onClickResend()
														await resetForm()
													}}
												>
													Click to resend the verification code
												</Link>
											) : (
												<Box>Resend the verification code in {seconds.toString().padStart(2, '0')}s</Box>
											)}
										</Box>
									)}

									{otpDialogStore.remainingResendCode >= 0 && (
										<Box mt={1} textAlign={'center'}>
											You have {otpDialogStore.remainingResendCode} attempt{otpDialogStore.remainingResendCode === 1 ? '' : 's'} remaining
										</Box>
									)}

									{otpDialogStore.submitError && otpDialogStore.remainingResendCode <= 0 && (
										<Box>
											<Typography
												css={[
													appStore.getTheme.typography.body2,
													{
														textAlign: 'center',
														padding: '12px 0',
													},
												]}
												style={{ color: '#FF9B04' }}
											>
												{!careStore.isJTP && (
													<>
														You have requested the verification code 6 times this session. Your account is now locked. Please contact us at
														<a
															style={{
																textDecoration: 'none',
																color: '#2C6EEE',
															}}
															onClick={() => {
																logClickEvent(EVENT_CLICK_ID.clickSupportEmailLinkSignup)
															}}
															href={`mailto:${data?.appInit?.company?.mainContactEmail}`}
														>
															{' '}
															{data?.appInit?.company?.mainContactEmail}
														</a>{' '}
														or call us at{' '}
														<a
															onClick={() => {
																logClickEvent(EVENT_CLICK_ID.clickSupportPhoneLinkSignup)
															}}
															style={{
																textDecoration: 'none',
																color: '#2C6EEE',
															}}
															href={`tel:${data?.appInit?.company?.phone}`}
														>
															{convertToUSPhoneFormat(data?.appInit?.company?.phone)}
														</a>{' '}
														for support.
													</>
												)}
												{careStore.isJTP && (
													<>
														{t('$MESSAGES.PHONE_VERIFICATION_SIX_TIMES_DES')}{' '}
														<a
															style={{
																textDecoration: 'none',
																color: '#2C6EEE',
															}}
															onClick={() => {
																logClickEvent(EVENT_CLICK_ID.clickSupportEmailLinkSignup)
															}}
															href={`mailto:${data?.appInit?.company?.mainContactEmail}`}
														>
															{data?.appInit?.company?.mainContactEmail}
														</a>{' '}
													</>
												)}
											</Typography>
										</Box>
									)}
									<Box mt={2}>
										<Button
											size="large"
											variant="contained"
											color="primary"
											onClick={async () => {
												await handleSubmitOTP({ ...values, phoneNumber: otpDialogStore.phoneNumber }, setShowDialogOtp)
												await resetForm()
											}}
											disabled={values?.otpCode?.length !== 6 || isSubmitting}
										>
											Continue
										</Button>
									</Box>
								</Form>
							</NotifyDialog>
						)
					}}
				</Formik>
			)}

			<NotifyDialog
				open={phoneInvalidConfirm}
				okText="OK"
				onOk={() => handleCloseInValidConfirmAndUpdate()}
				title={t('$MESSAGES.PHONE_VERIFICATION_TITLE')}
				children={phoneInvalidMessage || t('$MESSAGES.PHONE_VERIFICATION_TITLE')}
			/>

			<PhoneVerifyDialogComponent
				showPhoneVerifyModal={showPhoneVerifyModal}
				handleOnClickVerifyOtp={handleOnClickVerifyOtp}
				handleOnClickUpdatePhone={handleOnClickUpdatePhone}
			/>

			<VerificationSixTimesDialog handleOnClickCloseModals={handleOnClickCloseModals} data={data} />
			<PhoneChangeTwoTimesDialog handleOnClickCloseModals={handleOnClickCloseModals} data={data} />
			<CancelVerificationDialog handleCancelAllModals={handleCancelAllModals} showModal={showCancelModal} setShowModal={setShowCancelModal} />
		</>
	)
})

import { isEmpty, orderBy } from 'lodash'
import { generateTempId, isTempId } from '~/common/helpers/uid.helper'
import { authStore, masterStore } from '~/stores'
import { INTEGRATION_OBJECTS } from './constants'

export const convertPartToResume = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart
	const resumes = isEmpty(integration?.worker?.resumes) ? [] : integration?.worker?.resumes

	return {
		__hasResumes: resumes.length > 0,
		resumes: resumes,
		__workerOnboardingPart: workerOnboardingPart,
	}
}

const geneateAnswerSkillChecklist = (questions) => {
	let newQuestions = []
	newQuestions = questions?.map((item) => {
		return { workerId: authStore.id, question: item, proficiency: 0, frequency: 0, id: generateTempId() }
	})
	return newQuestions
}

const generateWorkerSkillChecklistSections = (item) => {
	if (!item?.has_checklist) {
		return []
	}
	let sections = []
	sections = item?.checklist?.map((value) => {
		return {
			id: generateTempId(),
			name: value.section,
			workerId: authStore.id,
			workerSkillChecklists: geneateAnswerSkillChecklist(value?.questions),
		}
	})
	return sections
}

const answerSkillChecklistMapping = (questions, workerQuestions) => {
	let newQuestions = []
	newQuestions = questions?.map((question) => {
		let object = workerQuestions?.find((item) => item?.question === question)
		if (object === undefined) {
			return { workerId: authStore.id, question: question, proficiency: 0, frequency: 0, id: generateTempId() }
		} else {
			return object
		}
	})
	return newQuestions
}

const workerSkillChecklistSectionsMapping = (workerSkillChecklistSections, skillChecklistSections) => {
	let sections = []
	sections = skillChecklistSections?.map((value) => {
		let section = workerSkillChecklistSections.find((item) => value?.section === item?.name)
		if (!section) {
			return {
				id: generateTempId(),
				name: value?.section,
				workerId: authStore.id,
				workerSkillChecklists: geneateAnswerSkillChecklist(value?.questions),
			}
		} else {
			return {
				...section,
				workerSkillChecklists: answerSkillChecklistMapping(value?.questions, section?.workerSkillChecklists),
			}
		}
	})
	return sections
}

export const workerSpecialtiesMapping = (workerSpecialties) => {
	const specialties = masterStore?.specialties || []

	return workerSpecialties.map((specialty) => {
		const mapObject = specialties.find((item) => item?.skill_name === specialty?.specialty && item?.has_checklist)
		if (specialty?.workerSkillChecklistSections?.length === 0) {
			specialty.workerSkillChecklistSections = generateWorkerSkillChecklistSections(mapObject)
		} else {
			specialty.workerSkillChecklistSections = workerSkillChecklistSectionsMapping(specialty?.workerSkillChecklistSections, mapObject?.checklist)
		}
		return specialty
	})
}

export const convertPartToSkillSpecialty = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart
	let workerSpecialties = isEmpty(integration?.workerSpecialties) ? [] : integration.workerSpecialties
	workerSpecialties = workerSpecialtiesMapping(workerSpecialties)
	return {
		...integration,
		workerSpecialties: workerSpecialties,
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToEducations = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart
	const workerEducations = isEmpty(integration?.workerEducations)
		? [{ id: generateTempId() }]
		: integration.workerEducations?.map(({ city, state, country, ...education }) => ({ ...education, location: { city, state, country } }))

	return {
		workerEducations,
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToPreferredLocationOnboarding = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart

	const workingPreferredLocations = isEmpty(integration?.workingPreferredLocations) ? [] : integration?.workingPreferredLocations

	const worker = isEmpty(integration?.worker) ? { id: authStore.id } : integration?.worker
	return {
		worker,
		workingPreferredLocations,
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToReferences = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart
	let workerReferences = isEmpty(integration?.workerReferences) ? [] : integration?.workerReferences

	let mapData = []

	if (workerReferences.length > 0) {
		mapData = workerReferences?.map(({ id, refType, city, state, ...rest }) => ({
			...rest,
			id,
			refType: !isTempId(id) ? (refType !== undefined && refType === 'former' ? true : false) : null,
			location: !isTempId(id) ? { city, state } : { city: null, state: null },
		}))
	} else {
		workerReferences = [{ id: generateTempId() }]
		mapData = workerReferences?.map(({ id, refType, city, state, ...rest }) => ({
			...rest,
			refType: !isTempId(id) ? (refType !== undefined && refType === 'former' ? true : false) : null,
			location: !isTempId(id) ? { city, state } : { city: null, state: null },
		}))
	}

	return {
		workerReferences: orderBy(mapData, 'createdAt'),
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToTerms = (workerOnboardingPart) => {
	const { integrationConfigurations } = workerOnboardingPart
	const workerQuizAnswers = integrationConfigurations?.quiz?.quizQuestions?.map(({ quizAnswers }) => {
		const quizAnswer = quizAnswers?.find((answer) => !!answer.workerAnswer)
		return {
			quizAnswerId: quizAnswer?.id,
			reason: quizAnswer?.workerAnswer?.reason,
			required: quizAnswer?.requireReason,
		}
	})

	return {
		workerQuizAnswers,
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const convertPartToProfile = (workerOnboardingPart) => {
	const { integration } = workerOnboardingPart

	return {
		...integration,
		workerAddress: integration?.workerAddress || {},
		workingPreferredLocations: integration?.workingPreferredLocations?.slice(0, 3) || [],
		emergencyContact: integration?.emergencyContact || {},
		__workerOnboardingPart: workerOnboardingPart,
	}
}

export const CONVERT_PART_TO_FORM_DATA = {
	[INTEGRATION_OBJECTS.resume]: convertPartToResume,
	[INTEGRATION_OBJECTS.skillSpecialty]: convertPartToSkillSpecialty,
	[INTEGRATION_OBJECTS.referenceOnboarding]: convertPartToReferences,
	[INTEGRATION_OBJECTS.preferredLocationOnboarding]: convertPartToPreferredLocationOnboarding,
	[INTEGRATION_OBJECTS.terms]: convertPartToTerms,
}

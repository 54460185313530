export const PATHS = {
	// COMMON
	common: {
		home: '/',
		reset: '/reset',
		signin: '/signin',
		errors: '/errors',
		change_password: '/change_password',
		api: '/api',
		graphql: '/graphql',
		signup: '/signup',
		tracking: '/tracking',
		link_account: '/link_account',
		link_options: '/link_options',
		authorization: '/authorization',
		update_phone: '/update_phone',
		maintenance: '/maintenance',
	},
	auth: {
		linkedin: '/linkedin',
	},

	// CARE
	care: {
		home: '/home',
		signup: '/signup',
		profile: '/profile',
		find_job: '/find_job',
		my_jobs: '/my_jobs',
		credential: '/credential',
		activation: '/activation',
		express_pass: '/express_pass',
		timecards: '/timecards',
		benefits: '/benefits',
		popular_cities: '/popular_cities',
		urgent_needs: '/urgent_needs',
		suggested_jobs: '/suggested_jobs',
		trending_jobs: '/trending_jobs',
		discover: '/discover',
		jobs: '/jobs',
		refer_traveler: '/refer_traveler',
		referrer: '/referrer',
		contact_us: '/contact_us',
		contact_recruiter: '/contact_recruiter',
		settings: '/settings',
		messages: '/messages',
		filter: '/filter',
		edit_filter: '/edit_filter',
		search: '/search',
		share_search: '/search/:link',
		last_viewed: '/last_viewed',
		job_alerts: '/job_alerts',
		email_unsubscribe: '/unsubscribe_emails',
		resources: '/resources',
		immediate_pay: '/immediate_pay',
	},
	banners: {
		first: '/assets/common/banner-1.png',
		second: '/assets/common/banner-2.png',
		third: '/assets/common/banner-3.png',
		four: '/assets/common/banner-4.png',
	},
	bannersJTP: {
		changePass: '/assets/common/banner-jtp/changepass.png',
		home: '/assets/common/banner-jtp/home.png',
		myJob: '/assets/common/banner-jtp/myJob.png',
		profile: '/assets/common/banner-jtp/profile.png',
		search: '/assets/common/banner-jtp/search.png',
		settings: '/assets/common/banner-jtp/settings.png',
		timecard: '/assets/common/banner-jtp/timecard.png',
		express: '/assets/common/banner-jtp/express.png',
	},
}

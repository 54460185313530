import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import axios from 'axios'
import { isEmpty, omit } from 'lodash'
import { apolloClient, VERIFY_EXISTED_EMAIL, VERIFY_WORKER_QUERY } from '~/common/apollo'
import { EVENTS, ONE_DAY, PATHS } from '~/common/constants'
import { captureException, RECAPTCHA_CLIENT_KEY } from '~/common/helpers'
import { action, computed, event, observable, persist, store } from '~/common/mobx.decorator'
import { notifyStore } from './notify.store'
import { careFindJobStore } from '~/features/care-find-job/care-find-job.store'
import { lastViewedStore } from '~/features/last-viewed/last-viewed.store'
import { getUserTrackingBasicInfor, logClickEvent } from '~/common/tracking/event-client.tracking'
import { EVENT_CLICK_ID } from '~/common/tracking/event-click.constant'

const matchAnonymousEvents = async () => {
	// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
	try {
		const anonymousEvents = await window.host.getUserEvents()
		const anonymousId = await window.host.getId()

		const searchEvents = anonymousEvents
			.filter((event) => event.name === 'ANONYMOUS_SEARCH')
			.map(
				(event) =>
					new events.AnonymousSearchSuccessEvent({
						...event.data,
						metadata: {
							ip: event.ip,
							user_agent: event.userAgent,
							anonymous_id: anonymousId,
						},
					})
			)

		searchEvents.forEach((event) => eventClient.logEvent(event))
		await window.host.archiveUserEvents()
		// IN CASE: You want to clean up all archived events
		// Please call: window.host.cleanUserEvents()
	} catch (error) {
		console.debug(error)
	}
}

class ProfileItem {
	@persist @observable id
	@persist @observable firstName
	@persist @observable lastName
	@persist @observable workAuthorized
	@persist @observable workerEmail
}

@store({ persist: true })
class AuthStore {
	@persist @observable token = ''
	@persist @observable maintenanceMessage = 'sgsgsssgs'
	@persist @observable expiresAt = 0
	@persist('object', ProfileItem) @observable profile
	@persist @observable isExistAccount = false
	@persist @observable notificationChannel = ''
	@observable currentJob = null
	@observable isAnonymousLogin = false
	@observable showDialogOtp = false
	@observable phoneNumber = ''
	@observable showChangePasswordDialog = false
	@observable phoneVerifyAt = null
	@observable showResetPassLockAccount = false
	@observable showResetPassLockContent = {}
	@observable avatar = null
	@computed
	get recruiter() {
		return this.profile?.recruiter
	}

	@computed
	get authorization() {
		return !this.token || Date.now() > this.expiresAt ? '' : this.token
	}

	@computed
	get features() {
		return [
			'home',
			'expressPass',
			'activation',
			'profile',
			'timecards',
			'popular-cities',
			'find-job',
			'filter-job',
			'edit-filter-job',
			'job-results',
			'list-jobs',
			'urgent-needs',
			'trending-jobs',
			'explore-matches',
			'discover',
			'my-jobs',
			'jobs',
			'referral',
			'change-password',
			'update-phone',
			'settings',
			'last-viewed',
			'job-alerts',
			'email-unsubscribe',
			'resources',
		]
	}

	@computed
	get id() {
		return this.authorization && this.profile?.id
	}

	@computed
	get isLogin() {
		return this.authorization !== ''
	}

	@computed
	get firstName() {
		return this.profile?.firstName
	}

	@computed
	get workerEmail() {
		return this.profile?.email
	}

	@computed
	get lastName() {
		return this.profile?.lastName
	}

	@computed
	get isExistAccountData() {
		return this.isExistAccount
	}

	@computed
	get avatarUrl() {
		return this.avatar || this.profile?.avatarUrl
	}

	@computed
	get fullName() {
		return [this.firstName, this.lastName].join(' ').trim()
	}

	@computed
	get isAllowAccessPage() {
		return this.isAnonymousLogin || this.isLogin || false
	}

	@computed
	get isAnonymous() {
		return this.isAnonymousLogin || this.authorization === ''
	}

	@computed
	get activated() {
		return this.profile?.workAuthorized
	}

	@computed
	get hasCurrentJob() {
		return !isEmpty(this.currentJob)
	}

	@action
	setAvatar = async (avatarUrl) => {
		this.avatar = avatarUrl
	}

	@action
	changeActiveTabIndex = (tabIndex) => {
		if (this.activeTabIndex === tabIndex) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTabIndex = tabIndex
	}

	@action
	changeProfile = async (profile, notificationChannel) => {
		if (this.profile && profile && this.profile?.id === profile?.id && profile.workAuthorized === true && this.activated !== profile?.workAuthorized) {
			eventClient.logEvent(new events.OnboardingWorkerAuthorizedSuccessEvent())
		}

		this.profile = profile
		if (notificationChannel) this.notificationChannel = notificationChannel
		if (profile) {
			eventClient.setUserId(profile.id)
			eventClient.setUserProperties({
				id: profile.id,
				company_id: process.env.REACT_APP_COMPANY_ID,
				work_authorized: profile.workAuthorized,
			})
			getUserTrackingBasicInfor().then((response) => {
				eventClient.setBasicInfo({
					...response,
					userProperties: {
						id: profile.id,
						company_id: process.env.REACT_APP_COMPANY_ID,
						work_authorized: profile.workAuthorized,
					},
				})
			})
			// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
			await matchAnonymousEvents()
		}
		notifyStore.sendInfo()

		// Update LiveChat Info
		if (window.LC_API && typeof window.LC_API.set_visitor_name === 'function') {
			window.LC_API.set_visitor_name([profile?.firstName, profile?.lastName].join(' ').trim())
			window.LC_API.set_visitor_email(profile?.email)
		}
	}

	@action
	handleAuthAnonymous(value) {
		return (this.isAnonymousLogin = value)
	}

	@action
	changeToken = async (token = '', duration = ONE_DAY * 30) => {
		this.token = token
		this.expiresAt = token ? Date.now() + duration : -1

		if (token) {
			axios.defaults.headers.common['Auth-Token'] = token
		} else {
			delete axios.defaults.headers.common['Auth-Token']
		}
		if (!token) {
			notifyStore.signal.push(() => {
				notifyStore.signal.removeExternalUserId()
			})
		}
	}

	@action
	verifyToken = async () => {
		if (!this.authorization) {
			await this.changeToken('')
			return
		}

		try {
			const {
				data: { workerToken },
			} = await apolloClient.query({
				query: VERIFY_WORKER_QUERY,
				variables: { token: this.authorization },
			})
			const { token, worker, notificationChannel } = workerToken
			if (this.isLogin) {
				this.profile = worker
				await this.changeToken(token)
				await this.changeProfile(worker, notificationChannel)
			}

			return worker
		} catch (error) {
			captureException('Verify token', error)
			await this.changeToken('')
		}
	}

	@event(EVENTS.authStore.logout)
	logout() {
		logClickEvent(EVENT_CLICK_ID.clickLogoutNavBtn)
		this.changeToken('')
		eventClient.setUserId(null)
		authStore.handleAuthAnonymous(false)
		authStore.resetPersist()
		eventClient.setUserProperties(null)
		careFindJobStore.setInitSearchListList()
		lastViewedStore.resetCountLastView()
		getUserTrackingBasicInfor().then((response) => {
			eventClient.setBasicInfo(omit(response, ['userProperties']))
		})
	}

	// @event('authStore/changeToken.success')
	// async fetchCurrentJob({ payload }) {
	// 	if (payload?.payload) {
	// 		const response = await apolloClient.query({
	// 			query: GET_CURRENT_WORKER_ASSIGNMENT_QUERY,
	// 		})
	// 		this.currentJob = response?.data?.currentWorkerAssignment
	// 	}
	// }

	@action
	setLastJobId = async (workerId, jobId, jobTitle) => {
		// if (!isEmpty(workerId)) {
		// 	const doc = fireStore.db.collection(COLECTIONS.last_job_id).doc(workerId)
		// 	await doc.set({ jobId, jobTitle })
		// }
		return null
	}

	@action
	handleSetShowDialogOtp = (value) => {
		this.showDialogOtp = value
	}

	@action
	handleSetPhoneNumber = (value) => {
		this.phoneNumber = value
	}

	@action
	setShowChangePasswordDialog = (value) => {
		this.showChangePasswordDialog = value
	}

	@action
	setPhoneVerifyAt = (value) => {
		this.phoneVerifyAt = value
	}
	@action
	setShowResetPassLockAccount = (value) => {
		this.showResetPassLockAccount = value
	}
	@action
	setShowResetPassLockContent = (value) => {
		this.showResetPassLockContent = value
	}
	@action
	resetPersist = () => {
		this.token = ''
		this.expiresAt = 0
		this.profile = null
		this.isExistAccount = false
		this.notificationChannel = ''
		this.currentJob = null
		this.phoneVerifiedAt = null
	}

	@action
	verifyExistEmail = async (variables, { setErrors }, history) => {
		authStore.resetPersist()
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'signInWorker',
					})
					let updatedVariables = {
						recaptchaToken: token,
						...variables,
					}
					const {
						data: { verifyExistedEmail },
					} = await apolloClient.mutate({
						mutation: VERIFY_EXISTED_EMAIL,
						variables: updatedVariables,
					})

					const { existed } = verifyExistedEmail
					this.isExistAccount = existed
					existed
						? await history.push(PATHS.common.signin, {
								email: variables.email,
						  })
						: await history.push(PATHS.common.signup, {
								email: variables.email,
						  })

					resolve()
				} catch (error) {
					captureException('Signin', error)
					setErrors({ password: error.message })
					reject(error.message)
				}
			})
		})
	}

	@action
	setMaintenanceMessage = (message) => {
		this.maintenanceMessage = message
	}
}

export const authStore = new AuthStore()
